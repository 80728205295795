import React, {useState} from "react"
import clsx from "clsx"
import {makeStyles, useTheme} from "@material-ui/core/styles"
import {CssBaseline, Card, Container, Grid} from "@material-ui/core"
import AppBarComponent from "../layout/AppBar"
import Scrollbar from "react-scrollbars-custom"
import EventsTable from "./RawEventsTable"
import Cookies from "js-cookie"

const drawerWidth = 280

const useStyles = makeStyles(theme => ({
	root: {
		display: "flex",
	},
	toolbar: {
		backgroundColor: "white",
		paddingRight: 24, // keep right padding when drawer closed
	},
	toolbarIcon: {
		display: "flex",
		alignItems: "center",
		justifyContent: "flex-end",
		padding: "0 8px",
		...theme.mixins.toolbar,
	},
	appBar: {
		zIndex: theme.zIndex.drawer + 1,
		transition: theme.transitions.create(["width", "margin"], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
	},
	appBarShift: {
		marginLeft: drawerWidth,
		width: `calc(100% - ${drawerWidth}px)`,
		transition: theme.transitions.create(["width", "margin"], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
	},
	menuButton: {
		marginRight: 36,
	},
	menuButtonHidden: {
		display: "none",
	},
	title: {
		flexGrow: 1,
	},
	drawerPaper: {
		position: "relative",
		whiteSpace: "nowrap",
		background: "linear-gradient(#006987 0%, #003544 100%)",
		border: "none",
		width: drawerWidth,
		transition: theme.transitions.create("width", {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
	},
	drawerPaperClose: {
		overflowX: "hidden",
		transition: theme.transitions.create("width", {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
		width: theme.spacing(7),
		[theme.breakpoints.up("sm")]: {
			width: theme.spacing(9),
		},
	},
	appBarSpacer: theme.mixins.toolbar,
	content: {
		flexGrow: 1,
		height: "100vh",
		overflow: "auto",
	},
	container: {
		paddingTop: theme.spacing(3),
		paddingBottom: theme.spacing(2),
	},
	paper: {
		padding: theme.spacing(2),
		display: "flex",
		overflow: "auto",
		flexDirection: "column",
	},
	fixedHeight: {
		height: 240,
	},
	card: {
		borderRadius: "12px",
	},
	logo: {
		"& .logo-icon": {
			width: "170px",
			margin: theme.spacing(2.5, 6),
			transition: theme.transitions.create(["width"], {
				duration: theme.transitions.duration.shortest,
				easing: theme.transitions.easing.easeInOut,
			}),
		},
	},
	item: props => ({
		height: 40,
		/* width: 'calc(100% - 16px)', */
		borderRadius: "0",
		/* paddingRight: 12, */
		paddingLeft: props.itemPadding > 80 ? 80 : props.itemPadding,
		"&.active": {
			backgroundColor: "#8adc39",
			color: `${theme.palette.secondary.contrastText}!important`,
			pointerEvents: "none",
			transition: "border-radius .15s cubic-bezier(0.4,0.0,0.2,1)",
			"& .list-item-text-primary": {
				color: "inherit",
			},
			"& .list-item-icon": {
				color: theme.palette.text.icon,
			},
		},
		"& .list-item-icon": {
			marginRight: 16,
			color: theme.palette.text.icon,
		},
		"& .list-item-text": {},
		color: theme.palette.text.icon,
		cursor: "pointer",
		textDecoration: "none!important",
	}),
	icon: {
		color: "white",
	},
	nested: {
		fontSize: "0.9rem",
		height: 40,
		paddingLeft: theme.spacing(9),
	},
	customBadge: {
		backgroundColor: `#ec0c8e !important`,
		color: "white",
	},
}))

export default function Events(props) {
	const classes = useStyles()
	const theme = useTheme()

	// let cookieValue = document.cookie.replace(/(?:(?:^|.*;\s*)loc\s*\=\s*([^;]*).*$)|^.*$/, "$1");
	// alert(cookieValue);
	// console.log(cookieValue);

	function onLogoutClick(e) {
		e.preventDefault()
		Cookies.remove("Biodit")
		window.location.href = "/login"
	}

	//for error handling
	const [iserror, setIserror] = useState(false)
	const [errorMessages, setErrorMessages] = useState([])

	// * Side Drawer Opening - Closing
	const [open, setOpen] = React.useState(true)
	const handleClick = () => {
		setOpen(!open)
	}

	return (
		<div className={classes.root}>
			<CssBaseline />
			<AppBarComponent />
			<main className={classes.content}>
				<Scrollbar id='RSC-Example' style={{width: "100%", height: "100%"}}>
					<div className={classes.appBarSpacer} />
					<Container maxWidth='lg' className={classes.container}>
						<Grid container spacing={3}>
							<Grid item xs={12}>
								<Card className={clsx(classes.card)} elevation={1}>
									<EventsTable />
								</Card>
							</Grid>
						</Grid>
					</Container>
				</Scrollbar>
			</main>
		</div>
	)
}

// export default connect(
//     mapStateToProps,
//     { logoutUser }
// )(Dashboard)
