import axios from "axios"

const setLocToken = loc_token => {
    if (loc_token) {
        // Apply authorization token to every request if logged in
        axios.defaults.withCredentials = true
        axios.defaults.headers.common["Loc"] = loc_token;
        axios.defaults.headers.common["custom"] = loc_token
        axios.defaults.headers.common["Origin-Trial"] =
            "Auy1bxxu8nX/rpWfIC5TiJSjxPG07WNcL05OvfiR4AOoG8DQySkdj6940lqZNXCkZZE02I2nv66bvF5DRNvdYw4AAABgeyJvcmlnaW4iOiJodHRwOi8vbG9jYWxob3N0OjQwMDAiLCJmZWF0dXJlIjoiVW5yZXN0cmljdGVkU2hhcmVkQXJyYXlCdWZmZXIiLCJleHBpcnkiOjE2MzM0NzgzOTl9"
    } else {
        // Delete auth header
        delete axios.defaults.headers.common["Authorization"]
    }
}

export default setLocToken
