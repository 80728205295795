import {
    SET_CURRENT_USER,
    USER_LOADING,
    USER_VERIFIED,
    VERIFICATION_FAILED,
} from '../actions/types';

const isEmpty = require('is-empty');

const initialState = {
    isAuthenticated: false,
    dataIsVerified: false,
    user: {},
    loading: false,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case SET_CURRENT_USER:
            return {
                ...state,
                isAuthenticated: !isEmpty(action.payload),
                user: action.payload,
            };
        case USER_VERIFIED:
            return {
                ...state,
                dataIsVerified: !isEmpty(action.payload),
            };
        case VERIFICATION_FAILED:
            return {
                ...state,
                verificationErrors: action.payload,
            };
        case USER_LOADING:
            return {
                ...state,
                loading: true,
            };
        default:
            return state;
    }
}
