import http from "../http-common"

const getAll = loc => {
	return http.get(`/v1/departments/loc/${loc}`)
}

const adminAll = loc => {
	return http.get(`/v1/departments/`)
}

const getAllDeleted = loc => {
	return http.get(`/v1/departments/deleted/`);
};

const get = id => {
	return http.get(`/v1/departments/${id}`)
}

const create = data => {
	return http.post("/v1/departments", data)
}

const update = (id, data) => {
	return http.put(`/v1/departments/${id}`, data)
}

const remove = id => {
	return http.delete(`/v1/departments/${id}`)
}

const removeAll = () => {
	return http.delete(`/v1/departments`)
}

const findByName = name => {
	return http.get(`/v1/departments?name=${name}`)
}

export default {
	getAll,
	adminAll,
	getAllDeleted,
	get,
	create,
	update,
	remove,
	removeAll,
	findByName,
}
