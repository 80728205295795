import React, { useEffect, useState } from 'react';
import {
    IconButton,
    Paper,
    Snackbar,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
} from '@mui/material';
import {
    Cancel as CancelIcon,
    Check as CheckIcon,
    Edit as EditIcon,
} from '@mui/icons-material';
import WebAppConfigService from '../../../services/WebAppConfigService';
import Alert from '@material-ui/lab/Alert';

const SystemRoles = () => {
    useEffect(() => {
        retrieveSystemSettings();
    }, []);

    const [settings, setSettings] = useState([]);
    const [editMode, setEditMode] = useState(false);
    const [currentValue, setCurrentValue] = useState('');
    const [currentId, setCurrentId] = useState(null);

    const retrieveSystemSettings = () => {
        WebAppConfigService.getAll()
            .then((response) => {
                setSettings(response.data);
            })
            .catch((e) => {
                console.log(e);
            });
    };

    const handleEditClick = (id, value) => {
        setEditMode(true);
        setCurrentValue(value);
        setCurrentId(id);
    };

    const handleCancelClick = () => {
        setEditMode(false);
        setCurrentValue('');
        setCurrentId(null);
    };

    const handleSaveClick = () => {
        WebAppConfigService.update(currentId, { rest_minutes: currentValue })
            .then(() => {
                retrieveSystemSettings();
                setEditMode(false);
                setCurrentValue('');
                setCurrentId(null);
                handleShowSnackbar(
                    `Успешно редактирахте стойността на поле "Обедна почивка"!`,
                    'success'
                );
            })
            .catch((e) => {
                console.log(e);
            });
    };

    // ! Snackbars Start
    const [snackbars, setSnackbars] = useState([]);

    const handleCloseSnackbar = (id) => {
        setSnackbars((prevSnackbars) =>
            prevSnackbars.filter((snackbar) => snackbar.id !== id)
        );
    };

    const handleShowSnackbar = (message, severity) => {
        const id = new Date().getTime(); // Unique ID for each snackbar
        const newSnackbar = {
            id,
            message,
            severity,
            position: snackbars.length * 55,
        };

        setSnackbars((prevSnackbars) => [...prevSnackbars, newSnackbar]);
    };
    // ! Snackbars End

    return (
        <TableContainer component={Paper}>
            {snackbars
                .slice()
                .reverse()
                .map((snackbar, index) => (
                    <Snackbar
                        key={snackbar.id}
                        open={true}
                        autoHideDuration={10000}
                        onClose={() => handleCloseSnackbar(snackbar.id)}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                        }}
                        style={{ bottom: `${10 + index * 55}px` }}
                    >
                        <Alert
                            elevation={6}
                            variant="filled"
                            severity={snackbar.severity}
                            onClose={() => handleCloseSnackbar(snackbar.id)}
                        >
                            {snackbar.message}
                        </Alert>
                    </Snackbar>
                ))}
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell>Настройка на:</TableCell>
                        <TableCell align="center">Стойност</TableCell>
                        <TableCell align="center">Действия</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {settings.map((row) => (
                        <TableRow
                            key={row.id}
                            sx={{
                                '&:last-child td, &:last-child th': {
                                    border: 0,
                                },
                            }}
                        >
                            <TableCell component="th" scope="row">
                                Обедна почивка (в минути)
                            </TableCell>
                            <TableCell align="center">
                                {editMode && currentId === row.id ? (
                                    <TextField
                                        value={currentValue}
                                        onChange={(e) =>
                                            setCurrentValue(e.target.value)
                                        }
                                        type="number"
                                    />
                                ) : (
                                    row.rest_minutes
                                )}
                            </TableCell>
                            <TableCell align="center">
                                {editMode && currentId === row.id ? (
                                    <>
                                        <IconButton onClick={handleSaveClick}>
                                            <CheckIcon />
                                        </IconButton>
                                        <IconButton onClick={handleCancelClick}>
                                            <CancelIcon />
                                        </IconButton>
                                    </>
                                ) : (
                                    <IconButton
                                        onClick={() =>
                                            handleEditClick(
                                                row.id,
                                                row.rest_minutes
                                            )
                                        }
                                    >
                                        <EditIcon />
                                    </IconButton>
                                )}
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default SystemRoles;
