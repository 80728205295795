import React from "react"
import {makeStyles, useTheme} from "@material-ui/core/styles"
import {CssBaseline, Typography, Container, Grid} from "@material-ui/core"
import AppBarComponent from "../layout/AppBar"
import PasswordResetForm from "../auth/PasswordResetForm"

const drawerWidth = 280

const useStyles = makeStyles(theme => ({
	root: {
		display: "flex",
	},
	toolbar: {
		backgroundColor: "white",
		paddingRight: 24, // keep right padding when drawer closed
	},
	toolbarIcon: {
		display: "flex",
		alignItems: "center",
		justifyContent: "flex-end",
		padding: "0 8px",
		...theme.mixins.toolbar,
	},
	menuButton: {
		marginRight: 36,
	},
	menuButtonHidden: {
		display: "none",
	},
	title: {
		flexGrow: 1,
	},
	drawer: {
		[theme.breakpoints.up("sm")]: {
			width: drawerWidth,
			flexShrink: 0,
		},
	},
	drawerPaper: {
		whiteSpace: "nowrap",
		background: "linear-gradient(#006987 0%, #003544 100%)",
		border: "none",
		width: drawerWidth,
		transition: theme.transitions.create("width", {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
		[theme.breakpoints.up("sm")]: {
			width: drawerWidth,
			flexShrink: 0,
		},
	},
	drawerPaperClose: {
		overflowX: "hidden",
		transition: theme.transitions.create("width", {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
		width: theme.spacing(7),
		[theme.breakpoints.up("sm")]: {
			width: theme.spacing(9),
		},
	},
	appBarSpacer: theme.mixins.toolbar,
	content: {
		flexGrow: 1,
	},
	container: {
		paddingTop: theme.spacing(25),
		paddingBottom: theme.spacing(4),
	},
	paper: {
		padding: theme.spacing(2),
		display: "flex",
		overflow: "auto",
		flexDirection: "column",
	},
	fixedHeight: {
		height: 240,
	},
	card: {
		borderRadius: "12px",
	},
	logo: {
		"& .logo-icon": {
			width: "170px",
			margin: theme.spacing(2.5, 6),
			transition: theme.transitions.create(["width"], {
				duration: theme.transitions.duration.shortest,
				easing: theme.transitions.easing.easeInOut,
			}),
		},
	},
	item: props => ({
		height: 40,
		/* width: 'calc(100% - 16px)', */
		borderRadius: "0",
		/* paddingRight: 12, */
		paddingLeft: props.itemPadding > 80 ? 80 : props.itemPadding,
		"&.active": {
			backgroundColor: "#8adc39",
			color: `${theme.palette.secondary.contrastText}!important`,
			pointerEvents: "none",
			transition: "border-radius .15s cubic-bezier(0.4,0.0,0.2,1)",
			"& .list-item-text-primary": {
				color: "inherit",
			},
			"& .list-item-icon": {
				color: theme.palette.text.icon,
			},
		},
		"& .list-item-icon": {
			marginRight: 16,
			color: theme.palette.text.icon,
		},
		"& .list-item-text": {},
		color: theme.palette.text.icon,
		cursor: "pointer",
		textDecoration: "none!important",
	}),
	itemtext: {
		fontSize: "0.8rem",
	},
	icon: {
		color: "white",
	},
	nested: {
		fontSize: "0.9rem",
		height: 40,
		paddingLeft: theme.spacing(9),
	},
	blueBar: {
		backgroundColor: "#003d4d",
		alignContent: "center",
		verticalAlign: "middle",
		flex: "auto",
		textAlign: "center",
		fontSize: "1.4rem",
		fontWeight: "400",
		color: "white",
		height: "8vh",
		padding: "1%",
	},
}))

function ComingSoon(props) {
	const classes = useStyles()
	const theme = useTheme()

	// * Start Drwawer Menu
	const {window} = props
	const [mobileOpen, setMobileOpen] = React.useState(false)

	const handleDrawerToggle = () => {
		setMobileOpen(!mobileOpen)
	}

	// * End Drawer Menu

	const [open, setOpen] = React.useState(true)
	const handleClick = () => {
		setOpen(!open)
	}

	const container =
		window !== undefined ? () => window().document.body : undefined

	return (
		<div className={classes.root}>
			<CssBaseline />
			<AppBarComponent />
			<main className={classes.content}>
				<Container className={classes.container}>
					<Grid container spacing={5}>
						<Grid item xs={12}>
							<Typography
								component='h1'
								variant='h4'
								color='inherit'
								align='center'>
								Смяна на парола
							</Typography>
							<PasswordResetForm />
						</Grid>
					</Grid>
				</Container>
			</main>
		</div>
	)
}

export default ComingSoon
