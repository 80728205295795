import http from '../http-common';

const getAll = (loc) => {
    return http.get(`/v1/changes/`);
};

const getAllDeleted = (loc) => {
    return http.get(`/v1/changes/deleted/`);
};

const get = (id) => {
    return http.get(`/v1/changes/${id}`);
};

const create = (data) => {
    return http.post('/v1/changes', data);
};

const update = (id, data) => {
    return http.put(`/v1/changes/${id}`, data);
};

const remove = (id) => {
    return http.delete(`/v1/changes/${id}`);
};

const removeAll = () => {
    return http.delete(`/v1/changes`);
};

const findByName = (name) => {
    return http.get(`/v1/changes?name=${name}`);
};

export default {
    getAll,
    getAllDeleted,
    get,
    create,
    update,
    remove,
    removeAll,
    findByName,
};
