import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Container, CssBaseline, Grid, Typography } from '@material-ui/core';
import AppBarComponent from '../layout/AppBar';
import BlueBar from '../layout/BlueBar';
import HomeVersionCard from './HomeVersionCard';
import HomeDashboardWidgets from './HomeDashboardWidgets';
import Alert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import { setSystemMessages } from '../../reducers/systemMessagesActions';
import SystemMessagesService from '../../services/SystemMessagesService';
import { connect } from 'react-redux';

const drawerWidth = 280;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    toolbar: {
        backgroundColor: 'white',
        paddingRight: 24, // keep right padding when drawer closed
    },
    toolbarIcon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    menuButton: {
        marginRight: 36,
    },
    menuButtonHidden: {
        display: 'none',
    },
    title: {
        flexGrow: 1,
    },
    drawer: {
        [theme.breakpoints.up('sm')]: {
            width: drawerWidth,
            flexShrink: 0,
        },
    },
    drawerPaper: {
        whiteSpace: 'nowrap',
        background: 'linear-gradient(#006987 0%, #003544 100%)',
        border: 'none',
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
        [theme.breakpoints.up('sm')]: {
            width: drawerWidth,
            flexShrink: 0,
        },
    },
    drawerPaperClose: {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9),
        },
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    fixedHeight: {
        height: 240,
    },
    card: {
        borderRadius: '12px',
    },
    logo: {
        '& .logo-icon': {
            width: '170px',
            margin: theme.spacing(2.5, 6),
            transition: theme.transitions.create(['width'], {
                duration: theme.transitions.duration.shortest,
                easing: theme.transitions.easing.easeInOut,
            }),
        },
    },
    item: (props) => ({
        height: 40,
        /* width: 'calc(100% - 16px)', */
        borderRadius: '0',
        /* paddingRight: 12, */
        paddingLeft: props.itemPadding > 80 ? 80 : props.itemPadding,
        '&.active': {
            backgroundColor: '#8adc39',
            color: `${theme.palette.secondary.contrastText}!important`,
            pointerEvents: 'none',
            transition: 'border-radius .15s cubic-bezier(0.4,0.0,0.2,1)',
            '& .list-item-text-primary': {
                color: 'inherit',
            },
            '& .list-item-icon': {
                color: theme.palette.text.icon,
            },
        },
        '& .list-item-icon': {
            marginRight: 16,
            color: theme.palette.text.icon,
        },
        '& .list-item-text': {},
        color: theme.palette.text.icon,
        cursor: 'pointer',
        textDecoration: 'none!important',
    }),
    itemtext: {
        fontSize: '0.8rem',
    },
    icon: {
        color: 'white',
    },
    nested: {
        fontSize: '0.9rem',
        height: 40,
        paddingLeft: theme.spacing(9),
    },
    blueBar: {
        backgroundColor: '#003d4d',
        alignContent: 'center',
        verticalAlign: 'middle',
        flex: 'auto',
        textAlign: 'center',
        fontSize: '1.4rem',
        fontWeight: '400',
        color: 'white',
        height: '8vh',
        padding: '1%',
    },
}));

const Home = ({ systemMessages, dispatchSetSystemMessages, ...props }) => {
    const classes = useStyles();
    const theme = useTheme();

    // * Start Drwawer Menu
    const { window } = props;
    const [mobileOpen, setMobileOpen] = React.useState(false);

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    // * End Drawer Menu

    const [open, setOpen] = React.useState(true);
    const handleClick = () => {
        setOpen(!open);
    };

    const container =
        window !== undefined ? () => window().document.body : undefined;

    // * Snackbars Start
    useEffect(() => {
        SystemMessagesService.getLatest()
            .then((response) => {
                dispatchSetSystemMessages(response.data);
            })
            .catch((error) => {
                console.error(error);
            });
    }, [dispatchSetSystemMessages]);

    return (
        <div className={classes.root}>
            <CssBaseline />
            <AppBarComponent />
            {systemMessages.length > 0 && systemMessages[0].active && (
                <Snackbar
                    key={systemMessages[0].id}
                    open={true}
                    autoHideDuration={null} // Set to null for the Snackbar to stay open indefinitely
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    // Adjust the bottom value as needed
                    style={{ bottom: '10px' }}
                >
                    <Alert
                        elevation={6}
                        variant="filled"
                        severity={systemMessages[0].backgroundColor} // Use 'backgroundColor' as 'severity'
                        style={{
                            width: '300px',
                            wordWrap: 'break-word',
                            whiteSpace: 'normal',
                        }}
                    >
                        {systemMessages[0].message}
                    </Alert>
                </Snackbar>
            )}
            <main className={classes.content}>
                <BlueBar />
                <Container className={classes.container}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={12} lg={12}>
                            <HomeDashboardWidgets />
                        </Grid>
                        <Grid item xs={12} md={12} lg={12}>
                            <Typography
                                component="h1"
                                variant="h5"
                                color="inherit"
                            >
                                Версия
                            </Typography>
                            <HomeVersionCard className="mb-24 rounded-8" />
                        </Grid>
                    </Grid>
                </Container>
            </main>
        </div>
    );
};

Home.propTypes = {
    /**
     * Injected by the documentation to work in an iframe.
     * You won't need it on your project.
     */
    window: PropTypes.func,
};

const mapStateToProps = (state) => ({
    systemMessages: state.systemMessages.systemMessages,
});

const mapDispatchToProps = (dispatch) => ({
    dispatchSetSystemMessages: (messages) =>
        dispatch(setSystemMessages(messages)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Home);

// export default connect(
//     mapStateToProps,
//     { logoutUser }
// )(Dashboard)
