import React from 'react';

const ChangelogLoadingSkeleton = () => {
    // Define the number of skeleton items you want to show
    const skeletonItems = Array.from({ length: 3 }).map((_, index) => (
        <div
            key={index}
            style={{
                display: 'flex',
                marginBottom: '16px',
            }}
        >
            {/* Skeleton item structure */}
            <div
                style={{
                    width: '80px', // Adjust width as needed
                    height: '20px', // Adjust height as needed
                    backgroundColor: '#e0e0e0', // Adjust background color as needed
                    marginRight: '16px', // Adjust margin as needed
                }}
                className="skeleton-badge"
            ></div>
            <div
                style={{
                    flexGrow: 1,
                    height: '20px', // Adjust height as needed
                    backgroundColor: '#e0e0e0', // Adjust background color as needed
                }}
                className="skeleton-description"
            ></div>
        </div>
    ));

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
            }}
            className="changelog-loading-skeleton"
        >
            {skeletonItems}
        </div>
    );
};

export default ChangelogLoadingSkeleton;
