import React, { useEffect, useState } from 'react';
import store from '../../store';
import { setSystemMessages } from '../../reducers/systemMessagesActions';
import useCheckPermission from '../../utils/permissions/useCheckPermissions';
import { usePermissions } from '../../utils/permissions/PermissionsContext';
import { logoutUser } from '../../actions/authActions';
import clsx from 'clsx';
import Alert from '@material-ui/lab/Alert';
import Grid from '@material-ui/core/Grid';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import List from '@material-ui/core/List';
import logo from '../../logo.png';
import LockIcon from '@material-ui/icons/Lock';
import HomeIcon from '@material-ui/icons/Home';
import EventIcon from '@material-ui/icons/Event';
import EventNoteIcon from '@material-ui/icons/EventNote';
import PeopleIcon from '@material-ui/icons/People';
import FingerprintIcon from '@material-ui/icons/Fingerprint';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import DeviceHubIcon from '@material-ui/icons/DeviceHub';
import PermIdentityIcon from '@material-ui/icons/PermIdentity';
import BlockIcon from '@material-ui/icons/Block';
import Hidden from '@material-ui/core/Hidden';
import Drawer from '@material-ui/core/Drawer';
import { NavLink, useLocation } from 'react-router-dom';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { useStyles } from '../styles/useStyles';
import {
    Collapse,
    ListItem,
    ListItemIcon,
    ListItemText,
} from '@material-ui/core';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import BarChartIcon from '@material-ui/icons/BarChart';
import ShareLocationIcon from '@mui/icons-material/ShareLocation';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import NewReleasesIcon from '@material-ui/icons/NewReleases';
import Divider from '@material-ui/core/Divider';
import SettingsIcon from '@material-ui/icons/Settings';
import WatchLaterIcon from '@mui/icons-material/WatchLater';
import NoteAddIcon from '@material-ui/icons/NoteAdd';
import EditIcon from '@material-ui/icons/Edit';
import SystemMessagesService from '../../services/SystemMessagesService';
import { connect } from 'react-redux';

const axios = require('axios');
const FormData = require('form-data');
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));
const AppBarComponent = ({
    systemMessages,
    dispatchSetSystemMessages,
    ...props
}) => {
    // ! LogOut Functionality
    const { handleLogout } = usePermissions();

    // ! Permissions Check
    const isSuper = useCheckPermission('super');
    const level = localStorage.getItem('level');
    // alert(isSuper);

    const canCreate = useCheckPermission('c');
    const canRead = useCheckPermission('r');
    const canUpdate = useCheckPermission('u');
    const canDelete = useCheckPermission('d');
    // alert(canCreate);
    // alert(canRead);
    // alert(canUpdate);
    // alert(canDelete);

    const classes = useStyles(props);

    // * Start Drwawer Menu
    const { window } = props;
    const [mobileOpen, setMobileOpen] = React.useState(false);

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const container =
        window !== undefined ? () => window().document.body : undefined;

    // CHeck location and keep Entries Menu Opened
    const location = useLocation();
    const [open, setOpen] = useState(false);

    const isActivePage = (path) => {
        return location.pathname === path;
    };

    const handleClick = () => {
        setOpen(!open);
    };

    useEffect(() => {
        setOpen(
            isActivePage('/working') ||
                isActivePage('/events') ||
                isActivePage('/discrepancies') ||
                isActivePage('/form-76') ||
                isActivePage('/enter-exit') ||
                isActivePage('/working-hours') ||
                isActivePage('/working-hours-night') ||
                isActivePage('/raw/events') ||
                isActivePage('/changes')
        );
    }, [location]);

    // * Snackbars Start
    useEffect(() => {
        SystemMessagesService.getLatest()
            .then((response) => {
                dispatchSetSystemMessages(response.data);
            })
            .catch((error) => {
                console.error(error);
            });
    }, [dispatchSetSystemMessages]);

    // * Start get links from database Start

    // * End get links from database

    // Check Build Version vs API Version
    // function checkBuildVersion() {
    //     const client = axios.create({
    //         baseURL: API_URL,
    //     });
    //
    //     client
    //         .get('/v1/buildVersion')
    //         .then((response) => {
    //             const serverVersion = response.data;
    //             const desiredVersion = '1.5.0';
    //
    //             if (serverVersion === desiredVersion) {
    //                 alert('Versions match!');
    //             } else {
    //                 alert(
    //                     'Versions do not match, please refresh by clicking the button below!'
    //                 );
    //                 window.location.reload(1);
    //             }
    //         })
    //         .catch((error) => {
    //             alert('Error checking build version:', error);
    //         });
    // }

    // setInterval(checkBuildVersion, 10000); // Send request every 10 seconds

    // * Snackbars End

    const drawer = (
        <div>
            {/*{systemMessages.length > 0 && systemMessages[0].active && (*/}
            {/*    <Snackbar*/}
            {/*        key={systemMessages[0].id}*/}
            {/*        open={true}*/}
            {/*        autoHideDuration={null} // Set to null for the Snackbar to stay open indefinitely*/}
            {/*        anchorOrigin={{*/}
            {/*            vertical: 'bottom',*/}
            {/*            horizontal: 'left',*/}
            {/*        }}*/}
            {/*        // Adjust the bottom value as needed*/}
            {/*        style={{ bottom: '10px' }}*/}
            {/*    >*/}
            {/*        <Alert*/}
            {/*            elevation={6}*/}
            {/*            variant="filled"*/}
            {/*            severity={systemMessages[0].backgroundColor} // Use 'backgroundColor' as 'severity'*/}
            {/*            style={{*/}
            {/*                width: '300px',*/}
            {/*                wordWrap: 'break-word',*/}
            {/*                whiteSpace: 'normal',*/}
            {/*            }}*/}
            {/*        >*/}
            {/*            {systemMessages[0].message}*/}
            {/*        </Alert>*/}
            {/*    </Snackbar>*/}
            {/*)}*/}

            <div className={clsx(classes.logo, 'flex items-center')}>
                <img className="logo-icon" src={logo} alt="logo" />
            </div>
            <List component="nav" aria-labelledby="nested-list-subheader">
                <NavLink
                    to="/home"
                    className={clsx(classes.item, classes.navlink, 'list-item')}
                    activeClassName="active"
                >
                    <HomeIcon className={classes.icon} />
                    <Typography
                        className={classes.navText}
                        variant="body1"
                        display="inline"
                    >
                        Начало
                    </Typography>
                </NavLink>
                <ListItem
                    button
                    onClick={handleClick}
                    className={clsx(classes.item, 'list-item')}
                    style={{
                        color: 'white',
                        cursor: 'pointer',
                        textDecoration: 'none',
                    }}
                >
                    <ListItemIcon className={classes.nestedIcon}>
                        <BarChartIcon />
                    </ListItemIcon>
                    <ListItemText
                        className={classes.itemtext}
                        primary="Справки"
                    />
                    {open ? <ExpandMore /> : <ExpandLess />}
                </ListItem>
                <Collapse
                    in={open}
                    timeout="auto"
                    unmountOnExit
                    className={classes.collapse}
                >
                    <List component="div" disablePadding>
                        <ListItem className={classes.nested}>
                            <NavLink
                                to="/working"
                                className={clsx(
                                    classes.item,
                                    classes.navlink,
                                    'list-item'
                                )}
                                activeClassName="active"
                                isActive={() => isActivePage('/working')}
                            >
                                <PeopleIcon className={classes.icon} />
                                <Typography
                                    className={classes.navText}
                                    variant="body1"
                                    display="inline"
                                >
                                    На Работа Днес
                                </Typography>
                            </NavLink>
                        </ListItem>
                        <ListItem className={classes.nested}>
                            <NavLink
                                to="/events"
                                className={clsx(
                                    classes.item,
                                    classes.navlink,
                                    'list-item'
                                )}
                                activeClassName="active"
                                isActive={() => isActivePage('/events')}
                            >
                                <EventIcon className={classes.icon} />
                                <Typography
                                    className={classes.navText}
                                    variant="body1"
                                    display="inline"
                                >
                                    Събития
                                </Typography>
                            </NavLink>
                        </ListItem>
                        <ListItem className={classes.nested}>
                            <NavLink
                                to="/working-hours"
                                className={clsx(
                                    classes.item,
                                    classes.navlink,
                                    'list-item'
                                )}
                                activeClassName="active"
                                isActive={() => isActivePage('/working-hours')}
                            >
                                <WatchLaterIcon className={classes.icon} />
                                <Typography
                                    className={classes.navText}
                                    variant="body1"
                                    display="inline"
                                >
                                    Справка Часове
                                </Typography>
                            </NavLink>
                        </ListItem>
                        <ListItem className={classes.nested}>
                            <NavLink
                                to="/discrepancies"
                                className={clsx(
                                    classes.item,
                                    classes.navlink,
                                    'list-item'
                                )}
                                activeClassName="active"
                                isActive={() => isActivePage('/discrepancies')}
                            >
                                <NewReleasesIcon className={classes.icon} />
                                <Typography
                                    className={classes.navText}
                                    variant="body1"
                                    display="inline"
                                >
                                    Разминавания
                                </Typography>
                            </NavLink>
                        </ListItem>
                        <ListItem className={classes.nested}>
                            <NavLink
                                to="/form-76"
                                className={clsx(
                                    classes.item,
                                    classes.navlink,
                                    'list-item'
                                )}
                                activeClassName="active"
                                isActive={() => isActivePage('/form-76')}
                            >
                                <NoteAddIcon className={classes.icon} />
                                <Typography
                                    className={classes.navText}
                                    variant="body1"
                                    display="inline"
                                >
                                    Форма 76
                                </Typography>
                            </NavLink>
                        </ListItem>
                        {(() => {
                            if (level === 'super' || level === 'admin') {
                                return (
                                    <div>
                                        <ListItem className={classes.nested}>
                                            <NavLink
                                                to="/changes"
                                                className={clsx(
                                                    classes.item,
                                                    classes.navlink,
                                                    'list-item'
                                                )}
                                                activeClassName="active"
                                                isActive={() =>
                                                    isActivePage('/changes')
                                                }
                                            >
                                                <EditIcon
                                                    className={classes.icon}
                                                />
                                                <Typography
                                                    className={classes.navText}
                                                    variant="body1"
                                                    display="inline"
                                                >
                                                    Промени
                                                </Typography>
                                            </NavLink>
                                        </ListItem>
                                    </div>
                                );
                            } else {
                                return '';
                            }
                        })()}
                        {/*<ListItem className={classes.nested}>*/}
                        {/*    <NavLink*/}
                        {/*        to="/enter-exit"*/}
                        {/*        className={clsx(*/}
                        {/*            classes.item,*/}
                        {/*            classes.navlink,*/}
                        {/*            'list-item'*/}
                        {/*        )}*/}
                        {/*        activeClassName="active"*/}
                        {/*        isActive={() => isActivePage('/enter-exit')}*/}
                        {/*    >*/}
                        {/*        <MeetingRoomIcon className={classes.icon} />*/}
                        {/*        <Typography*/}
                        {/*            className={classes.navText}*/}
                        {/*            variant="body1"*/}
                        {/*            display="inline"*/}
                        {/*        >*/}
                        {/*            Вход/Изход*/}
                        {/*        </Typography>*/}
                        {/*    </NavLink>*/}
                        {/*</ListItem>*/}
                        <ListItem className={classes.nested}>
                            <NavLink
                                to="/raw/events"
                                className={clsx(
                                    classes.item,
                                    classes.navlink,
                                    'list-item'
                                )}
                                activeClassName="active"
                                isActive={() => isActivePage('/raw/events')}
                            >
                                <EventNoteIcon className={classes.icon} />
                                <Typography
                                    className={classes.navText}
                                    variant="body1"
                                    display="inline"
                                >
                                    Сурови Събития
                                </Typography>
                            </NavLink>
                        </ListItem>
                    </List>
                </Collapse>
                <NavLink
                    to="/users"
                    className={clsx(classes.item, classes.navlink, 'list-item')}
                    activeClassName="active"
                >
                    <PeopleIcon className={classes.icon} />
                    <Typography
                        className={classes.navText}
                        variant="body1"
                        display="inline"
                    >
                        Служители
                    </Typography>
                </NavLink>
                <NavLink
                    to="/dismissed/users"
                    className={clsx(classes.item, classes.navlink, 'list-item')}
                    activeClassName="active"
                >
                    <BlockIcon className={classes.icon} />
                    <Typography
                        className={classes.navText}
                        variant="body1"
                        display="inline"
                    >
                        Изключени Служители
                    </Typography>
                </NavLink>
                {(() => {
                    if (level === 'super' || level === 'admin') {
                        return (
                            <NavLink
                                to="/devices"
                                className={clsx(
                                    classes.item,
                                    classes.navlink,
                                    'list-item'
                                )}
                                activeClassName="active"
                            >
                                <DeviceHubIcon className={classes.icon} />
                                <Typography
                                    className={classes.navText}
                                    variant="body1"
                                    display="inline"
                                >
                                    Устройства
                                </Typography>
                            </NavLink>
                        );
                    } else {
                        return '';
                    }
                })()}
                <NavLink
                    to="/allocation"
                    className={clsx(classes.item, classes.navlink, 'list-item')}
                    activeClassName="active"
                >
                    <FingerprintIcon className={classes.icon} />
                    <Typography
                        className={classes.navText}
                        variant="body1"
                        display="inline"
                    >
                        Въвеждане
                    </Typography>
                </NavLink>
                {(() => {
                    if (level === 'super' || level === 'admin') {
                        return (
                            <NavLink
                                to="/company"
                                className={clsx(
                                    classes.item,
                                    classes.navlink,
                                    'list-item'
                                )}
                                activeClassName="active"
                            >
                                <ShareLocationIcon className={classes.icon} />
                                <Typography
                                    className={classes.navText}
                                    variant="body1"
                                    display="inline"
                                >
                                    Локации и Отдели
                                </Typography>
                            </NavLink>
                        );
                    } else {
                        return '';
                    }
                })()}

                {/* <NavLink
					to='/version'
					className={clsx(classes.item, classes.navlink, "list-item")}
					activeClassName='active'>
					<HistoryIcon className={classes.icon} />
					<Typography
						className={classes.navText}
						variant='body1'
						display='inline'>
						Версия
					</Typography>
				</NavLink> */}
                {/* <NavLink
					to='/upload/workplan'
					className={clsx(classes.item, classes.navlink, "list-item")}
					activeClassName='active'>
					<HelpIcon className={classes.icon} />
					<Typography
						className={classes.navText}
						variant='body1'
						display='inline'>
						Импортиране на смени
					</Typography>
				</NavLink> */}
                {/*<NavLink*/}
                {/*    to="/support"*/}
                {/*    className={clsx(classes.item, classes.navlink, 'list-item')}*/}
                {/*    activeClassName="active"*/}
                {/*>*/}
                {/*    <HelpIcon className={classes.icon} />*/}
                {/*    <Typography*/}
                {/*        className={classes.navText}*/}
                {/*        variant="body1"*/}
                {/*        display="inline"*/}
                {/*    >*/}
                {/*        Поддръжка*/}
                {/*    </Typography>*/}
                {/*</NavLink>*/}
            </List>
        </div>
    );

    // * End Drawer Menu

    const [state, setState] = React.useState({
        right: false,
    });

    const toggleDrawer = (anchor, open) => (event) => {
        if (
            event &&
            event.type === 'keydown' &&
            (event.key === 'Tab' || event.key === 'Shift')
        ) {
            return;
        }

        setState({ ...state, [anchor]: open });
    };

    const [count, setCount] = useState('5');

    const list = (anchor) => (
        <div
            className={clsx(classes.list, {
                [classes.fullList]: anchor === 'top' || anchor === 'bottom',
            })}
            role="presentation"
            onClick={toggleDrawer(anchor, false)}
            onKeyDown={toggleDrawer(anchor, false)}
        >
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Alert severity="warning">
                        Алекс Софрониев е влязъл и излязъл в рамките на 1мин. на
                        дата 1/Март/2021.
                    </Alert>
                </Grid>
            </Grid>
        </div>
    );

    // * Profile Menu

    const [anchorEl, setAnchorEl] = React.useState(null);
    const openUser = Boolean(anchorEl);

    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleLogoutClick = () => {
        // Trigger the handleLogout function from PermissionsContext
        store.dispatch(logoutUser());
        handleLogout(() => {
            // Additional logout actions can be performed here
            // For example, dispatching a Redux action to clear user data
            console.log('User logged out.');
        });
    };

    return (
        <div>
            {['right'].map((anchor) => (
                <div>
                    <AppBar
                        position="absolute"
                        className={clsx(classes.appBar, classes.appBarShift)}
                        elevation={0}
                    >
                        <Toolbar className={classes.toolbar}>
                            <IconButton
                                color="inherit"
                                aria-label="open drawer"
                                edge="start"
                                onClick={handleDrawerToggle}
                                className={clsx(
                                    classes.menuButton,
                                    classes.menuButtonHidden
                                )}
                            >
                                <MenuIcon />
                            </IconButton>
                            <Typography
                                variant="h6"
                                className={classes.title}
                            ></Typography>
                            {/* <IconButton
								className={classes.notificationsIcon}
								onClick={toggleDrawer(anchor, true)}>
								<Badge badgeContent={count} color='secondary'>
									<NotificationsActiveIcon />
								</Badge>
							</IconButton> */}
                            {(() => {
                                if (level === 'super' || level === 'admin') {
                                    return (
                                        <div>
                                            <div
                                                style={{
                                                    borderRight:
                                                        '1px solid #ccc',
                                                    borderLeft:
                                                        '1px solid #ccc',
                                                }}
                                            >
                                                <Divider
                                                    orientation="vertical"
                                                    flexItem
                                                />
                                                <NavLink
                                                    to="/admin-settings"
                                                    style={{
                                                        color: 'black',
                                                        cursor: 'pointer',
                                                        textDecoration: 'none',
                                                    }}
                                                >
                                                    <IconButton
                                                        className={
                                                            classes.adminButton
                                                        }
                                                        aria-controls="menu-admin"
                                                        style={{ fontSize: 40 }}
                                                    >
                                                        <SettingsIcon />
                                                    </IconButton>
                                                </NavLink>
                                                <Divider
                                                    orientation="vertical"
                                                    flexItem
                                                />
                                            </div>
                                        </div>
                                    );
                                } else {
                                    return (
                                        <Divider
                                            orientation="vertical"
                                            flexItem
                                        />
                                    );
                                }
                            })()}
                            {/*{(() => {*/}
                            {/*    if (isSuper === true) {*/}
                            {/*        return (*/}
                            {/*            */}
                            {/*        );*/}
                            {/*    } else {*/}
                            {/*        return (*/}
                            {/*            <Divider*/}
                            {/*                orientation="vertical"*/}
                            {/*                flexItem*/}
                            {/*            />*/}
                            {/*        );*/}
                            {/*    }*/}
                            {/*})()}*/}

                            <NavLink
                                to="#"
                                style={{
                                    color: 'black',
                                    cursor: 'pointer',
                                    textDecoration: 'none',
                                }}
                            >
                                <IconButton
                                    aria-label="account of current user"
                                    className={classes.avatarButton}
                                    aria-controls="menu-appbar"
                                    aria-haspopup="true"
                                    onClick={handleMenu}
                                >
                                    <PermIdentityIcon />
                                </IconButton>
                            </NavLink>

                            {/* <Divider orientation='vertical' flexItem />
							<LogOutButton className={classes.logOutButton} /> */}

                            <Menu
                                id="menu-appbar"
                                anchorEl={anchorEl}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right',
                                }}
                                mt={1}
                                keepMounted
                                transformOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right',
                                }}
                                open={openUser}
                                onClose={handleClose}
                            >
                                <NavLink
                                    to="/password-reset"
                                    style={{
                                        color: 'black',
                                        cursor: 'pointer',
                                        textDecoration: 'none',
                                    }}
                                >
                                    <MenuItem onClick={handleClose}>
                                        <LockIcon
                                            className={classes.passwordIcon}
                                        />
                                        Парола
                                    </MenuItem>
                                </NavLink>
                                <NavLink
                                    to=""
                                    style={{
                                        color: 'black',
                                        cursor: 'pointer',
                                        textDecoration: 'none',
                                    }}
                                >
                                    <MenuItem onClick={handleLogoutClick}>
                                        <ExitToAppIcon
                                            className={classes.exitIcon}
                                        />
                                        Излизане
                                    </MenuItem>
                                </NavLink>
                            </Menu>

                            {/* <IconButton color="inherit" onClick={onLogoutClick}>
                        <ExitToAppIcon />
                    </IconButton> */}
                        </Toolbar>
                    </AppBar>
                    <nav
                        className={classes.drawer}
                        aria-label="mailbox folders"
                    >
                        <Hidden smUp implementation="css">
                            <Drawer
                                container={container}
                                variant="temporary"
                                open={mobileOpen}
                                onClose={handleDrawerToggle}
                                classes={{
                                    paper: classes.drawerPaper,
                                }}
                                ModalProps={{
                                    keepMounted: true, // Better open performance on mobile.
                                }}
                            >
                                {drawer}
                            </Drawer>
                        </Hidden>
                        <Hidden xsDown implementation="css">
                            <Drawer
                                classes={{
                                    paper: classes.drawerPaper,
                                }}
                                variant="permanent"
                                open
                            >
                                {drawer}
                            </Drawer>
                        </Hidden>
                    </nav>
                    <React.Fragment key={anchor}>
                        <SwipeableDrawer
                            anchor={anchor}
                            open={state[anchor]}
                            onClose={toggleDrawer(anchor, false)}
                            onOpen={toggleDrawer(anchor, true)}
                        >
                            {list(anchor)}
                        </SwipeableDrawer>
                    </React.Fragment>
                </div>
            ))}
        </div>
    );
};

const mapStateToProps = (state) => ({
    systemMessages: state.systemMessages.systemMessages,
});

const mapDispatchToProps = (dispatch) => ({
    dispatchSetSystemMessages: (messages) =>
        dispatch(setSystemMessages(messages)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AppBarComponent);
