import React, {useState, useEffect} from "react"
import {makeStyles} from "@material-ui/core/styles"
import CssBaseline from "@material-ui/core/CssBaseline"
import AppBarComponent from "../layout/AppBar"
import Container from "@material-ui/core/Container"
import Grid from "@material-ui/core/Grid"
import Alert from "@material-ui/lab/Alert"
import Scrollbar from "react-scrollbars-custom"
import {createMuiTheme} from "@material-ui/core/styles"
import CompanyDataService from "../../services/CompanyService"
import DepartmentForm from "./DepartmentForm"
import LocationForm from "./LocationForm"
import Copyright from "../layout/Copyright"
import { useStyles } from "../styles/useStyles"

export default function Events(props) {
	const classes = useStyles(props)

	const initialCompanyState = {
		id: null,
		name: "",
		phone: "",
		address: "",
		vat: "",
		owner: "",
		city: "",
	}
	const [currentCompany, setCurrentCompany] = useState(initialCompanyState)
	const [message, setMessage] = useState("")

	const getCompany = id => {
		CompanyDataService.get("1")
			.then(response => {
				setCurrentCompany(response.data)
				console.log(response.data)
			})
			.catch(e => {
				console.log(e)
			})
	}

	useEffect(() => {
		getCompany(props.match.params.id)
	}, [props.match.params.id])

	const handleInputChange = event => {
		const {name, value} = event.target
		setCurrentCompany({...currentCompany, [name]: value})
	}

	const updatePublished = status => {
		var data = {
			id: currentCompany.id,
			name: currentCompany.name,
			phone: currentCompany.phone,
			address: currentCompany.address,
			city: currentCompany.city,
			vat: currentCompany.vat,
			owner: currentCompany.owner,
			active: status,
		}

		CompanyDataService.update(currentCompany.id, data)
			.then(response => {
				setCurrentCompany({...currentCompany, active: status})
				console.log(response.data)
			})
			.catch(e => {
				console.log(e)
			})
	}

	const updateCompany = () => {
		CompanyDataService.update(currentCompany.id, currentCompany)
			.then(response => {
				console.log(response.data)
				setMessage(
					<Alert severity='success'>
						Фирмените данни бяха обновени успешно!
					</Alert>
				)
			})
			.catch(e => {
				console.log(e)
			})
	}

	const [open, setOpen] = React.useState(true)
	const handleClick = () => {
		setOpen(!open)
	}

	return (
		<div className={classes.root}>
			<CssBaseline />
			<AppBarComponent />
			<main className={classes.content}>
				<Scrollbar id='RSC-Example' style={{width: "100%", height: "100%"}}>
					<div className={classes.appBarSpacer} />
					<Container maxWidth='lg' className={classes.container}>
						<Grid container spacing={1}>
							{/*{currentCompany ? (*/}
							{/*	<Grid item xs={6}>*/}
							{/*		<Card className={classes.card}>*/}
							{/*			<AppBar position='static' elevation={0} color='primary'>*/}
							{/*				<Toolbar className='px-8'>*/}
							{/*					<Typography*/}
							{/*						variant='subtitle1'*/}
							{/*						color='inherit'*/}
							{/*						className='flex-1 px-12'>*/}
							{/*						{currentCompany.name}*/}
							{/*					</Typography>*/}
							{/*				</Toolbar>*/}
							{/*			</AppBar>*/}
							{/*			{message}*/}
							{/*			<CardContent>*/}
							{/*				<div className='edit-form'>*/}
							{/*					<form className={classes.forms}>*/}
							{/*						<div>*/}
							{/*							<TextField*/}
							{/*								required*/}
							{/*								id='outlined-required name'*/}
							{/*								label='Име на фирмата'*/}
							{/*								variant='outlined'*/}
							{/*								name='name'*/}
							{/*								value={currentCompany.name}*/}
							{/*								onChange={handleInputChange}*/}
							{/*								size='small'*/}
							{/*							/>*/}
							{/*							<TextField*/}
							{/*								required*/}
							{/*								id='outlined-required owner'*/}
							{/*								label='МОЛ'*/}
							{/*								variant='outlined'*/}
							{/*								name='owner'*/}
							{/*								value={currentCompany.owner}*/}
							{/*								onChange={handleInputChange}*/}
							{/*								size='small'*/}
							{/*							/>*/}
							{/*						</div>*/}
							{/*						<div>*/}
							{/*							<TextField*/}
							{/*								required*/}
							{/*								id='outlined-required phone'*/}
							{/*								label='Телефон за контакт'*/}
							{/*								variant='outlined'*/}
							{/*								name='phone'*/}
							{/*								value={currentCompany.phone}*/}
							{/*								onChange={handleInputChange}*/}
							{/*								size='small'*/}
							{/*							/>*/}
							{/*							<TextField*/}
							{/*								required*/}
							{/*								id='outlined-required vat'*/}
							{/*								label='ДДС Номер'*/}
							{/*								variant='outlined'*/}
							{/*								name='vat'*/}
							{/*								value={currentCompany.vat}*/}
							{/*								onChange={handleInputChange}*/}
							{/*								size='small'*/}
							{/*							/>*/}
							{/*						</div>*/}
							{/*						<div>*/}
							{/*							<TextField*/}
							{/*								required*/}
							{/*								id='outlined-required address'*/}
							{/*								label='Адрес'*/}
							{/*								variant='outlined'*/}
							{/*								name='address'*/}
							{/*								value={currentCompany.address}*/}
							{/*								onChange={handleInputChange}*/}
							{/*								size='small'*/}
							{/*							/>*/}
							{/*							<TextField*/}
							{/*								required*/}
							{/*								id='outlined-required city'*/}
							{/*								label='Град'*/}
							{/*								variant='outlined'*/}
							{/*								name='city'*/}
							{/*								id='city'*/}
							{/*								value={currentCompany.city}*/}
							{/*								onChange={handleInputChange}*/}
							{/*								size='small'*/}
							{/*							/>*/}
							{/*						</div>*/}
							{/*						<Button*/}
							{/*							onClick={updateCompany}*/}
							{/*							variant='contained'*/}
							{/*							color='primary'>*/}
							{/*							Обнови*/}
							{/*						</Button>*/}
							{/*					</form>*/}
							{/*				</div>*/}
							{/*			</CardContent>*/}
							{/*		</Card>*/}
							{/*	</Grid>*/}
							{/*) : (*/}
							{/*	<div>*/}
							{/*		<br />*/}
							{/*		<p>Please click on a Company...</p>*/}
							{/*	</div>*/}
							{/*)}*/}
							<Grid item xs={6}>
								<LocationForm />
							</Grid>
							<Grid item xs={6}>
								<DepartmentForm />
							</Grid>
						</Grid>
					</Container>
					<Copyright />
				</Scrollbar>
			</main>
		</div>
	)
}

// export default connect(
//     mapStateToProps,
//     { logoutUser }
// )(Dashboard)
