import http from '../http-common';

const getAll = (loc) => {
    return http.get(`/v1/adminRoles/`);
};

const getAllDeleted = (loc) => {
    return http.get(`/v1/adminRoles/deleted/`);
};

const get = (id) => {
    return http.get(`/v1/adminRoles/${id}`);
};

const create = (data) => {
    return http.post('/v1/adminRoles', data);
};

const update = (id, data) => {
    return http.put(`/v1/adminRoles/${id}`, data);
};

const remove = (id) => {
    return http.delete(`/v1/adminRoles/${id}`);
};

const removeAll = () => {
    return http.delete(`/v1/adminRoles`);
};

const findByName = (name) => {
    return http.get(`/v1/adminRoles?name=${name}`);
};

export default {
    getAll,
    getAllDeleted,
    get,
    create,
    update,
    remove,
    removeAll,
    findByName,
};
