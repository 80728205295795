import React, { useEffect, useState } from 'react';
import MaterialTable, { MTableFilterRow } from 'material-table';
import ChangesDataService from '../../../services/ChangesService';
import DescriptionIcon from '@material-ui/icons/Description';
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx/dist/xlsx.full.min.js';

const EventsTable = () => {
    const [changes, setChanges] = useState([]);

    const [loc] = React.useState(() => {
        const locData = localStorage.getItem('loc');
        return locData ? JSON.parse(locData).id : '';
    });

    useEffect(() => {
        retrieveChanges();
    }, []);

    const retrieveChanges = () => {
        ChangesDataService.getAll(loc)
            .then((response) => {
                setChanges(response.data);
                console.log(response.data);
            })
            .catch((e) => {
                console.log(e);
            });
    };

    // * Add Filters to State Start
    const [selectedRows, setSelectedRows] = useState([]);

    const [components] = useState({
        FilterRow: (props) => <MTableFilterRow {...props} />,
    });

    // ! Define table columns for export
    const [columns] = useState([
        {
            title: 'Администратор:',
            field: 'Administrator.name',
        },
        {
            title: 'Нова информация',
            // type: 'datetime',
            // dateSetting: { locale: 'bg-BG', hour12: false },
            render: (rowData) => {
                // Assuming changes is an object with multiple keys you want to display
                const { event, user_id, location } = rowData.changes;

                // const edited_user_id = rowData.previous_data.user_id;

                const timeValue = rowData.changes && rowData.changes.time;

                if (timeValue && timeValue.trim() !== '') {
                    const date = new Date(timeValue);

                    const dateOptions = {
                        day: 'numeric',
                        month: 'numeric',
                        year: 'numeric',
                    };

                    const timeOptions = {
                        hour: '2-digit',
                        minute: '2-digit',
                        second: '2-digit',
                        hour12: false,
                    };

                    const formattedDate =
                        new Intl.DateTimeFormat('bg-BG', dateOptions).format(
                            date
                        ) +
                        ', ' +
                        new Intl.DateTimeFormat('bg-BG', timeOptions).format(
                            date
                        );

                    return (
                        <div>
                            <span>
                                Дата и час:
                                <br /> {formattedDate}
                            </span>
                            <br />
                            {event && (
                                <>
                                    <hr />
                                    <span>Вид събитие: {event}</span>
                                    <br />
                                </>
                            )}
                            {user_id && (
                                <>
                                    <hr />
                                    <span>Служител: {user_id}</span>
                                    <br />
                                </>
                            )}
                            {/*{location && (*/}
                            {/*    <>*/}
                            {/*        <span>Магазин: {location}</span>*/}
                            {/*        <br />*/}
                            {/*    </>*/}
                            {/*)}*/}
                        </div>
                    );
                } else {
                    return 'Това е ново събитие.';
                }
            },
        },
        {
            title: 'Стара информация',
            field: 'previous_data.time',
            type: 'datetime',
            dateSetting: { locale: 'bg-BG', hour12: false },
            render: (rowData) => {
                const timeValue =
                    rowData.previous_data && rowData.previous_data.time;
                if (timeValue && timeValue.trim() !== '') {
                    const date = new Date(timeValue);

                    const dateOptions = {
                        day: 'numeric',
                        month: 'numeric',
                        year: 'numeric',
                    };

                    const timeOptions = {
                        hour: '2-digit',
                        minute: '2-digit',
                        second: '2-digit',
                        hour12: false,
                    };

                    const formattedDate =
                        new Intl.DateTimeFormat('bg-BG', dateOptions).format(
                            date
                        ) +
                        ', ' +
                        new Intl.DateTimeFormat('bg-BG', timeOptions).format(
                            date
                        );

                    return formattedDate;
                } else {
                    return 'Това е ново събитие.';
                }
            },
        },
        {
            title: 'Вид събитие',
            field: 'previous_data.event',
        },
        {
            title: 'Ръчно добавено',
            field: 'previous_data.time',
            render: (rowData) => (rowData.previous_data ? 'Не' : 'Да'),
        },
        {
            title: 'Коригирано',
            field: 'previous_data.time',
            render: (rowData) => (rowData.previous_data ? 'Да' : 'Не'),
        },
        {
            title: 'Дата на създаване на промяна:',
            field: 'createdAt',
            type: 'datetime',
            dateSetting: { locale: 'bg-BG', hour12: false },
        },
    ]);

    // ! Export Table
    const tableRef = React.createRef();

    const exportTable = () => {
        const workbook = XLSX.utils.book_new(); // Create a new workbook
        const transformedData = changes.map((rowData) => {
            // Replicate the formatting logic for new information
            let formattedNewInfo = '';
            const { event, user_id, location, Administrator } = rowData.changes;
            const timeValue = rowData.changes && rowData.changes.time;

            // Formatting logic for new information
            if (timeValue && timeValue.trim() !== '') {
                const date = new Date(timeValue);
                const dateOptions = {
                    day: 'numeric',
                    month: 'numeric',
                    year: 'numeric',
                };
                const timeOptions = {
                    hour: '2-digit',
                    minute: '2-digit',
                    second: '2-digit',
                    hour12: false,
                };

                const formattedDate =
                    new Intl.DateTimeFormat('bg-BG', dateOptions).format(date) +
                    ', ' +
                    new Intl.DateTimeFormat('bg-BG', timeOptions).format(date);

                formattedNewInfo += `Дата и час:\r\n ${formattedDate}\r\n`;
                if (event)
                    formattedNewInfo += `─────────────────────\r\nВид събитие: ${event}\r\n`;
                if (user_id)
                    formattedNewInfo += `─────────────────────\r\nСлужител: ${user_id}`;
                // Add other fields like 'location' if needed
            } else {
                formattedNewInfo = 'Това е ново събитие.';
            }

            // Replicate the formatting logic for old information
            let formattedOldInfo = '';
            const previousTimeValue =
                rowData.previous_data && rowData.previous_data.time;
            if (previousTimeValue && previousTimeValue.trim() !== '') {
                const date = new Date(previousTimeValue);
                const formattedDate =
                    new Intl.DateTimeFormat('bg-BG', {
                        day: 'numeric',
                        month: 'numeric',
                        year: 'numeric',
                    }).format(date) +
                    ', ' +
                    new Intl.DateTimeFormat('bg-BG', {
                        hour: '2-digit',
                        minute: '2-digit',
                        second: '2-digit',
                        hour12: false,
                    }).format(date);

                formattedOldInfo = formattedDate;
            } else {
                formattedOldInfo = 'Това е ново събитие.';
            }

            // Formatting logic for createdAt
            const createdAtDate = new Date(rowData.createdAt);
            const formattedCreatedAt =
                new Intl.DateTimeFormat('bg-BG', {
                    day: 'numeric',
                    month: 'numeric',
                    year: 'numeric',
                }).format(createdAtDate) +
                ', ' +
                new Intl.DateTimeFormat('bg-BG', {
                    hour: '2-digit',
                    minute: '2-digit',
                    second: '2-digit',
                    hour12: false,
                }).format(createdAtDate);

            const manualAdded = rowData.previous_data ? 'Не' : 'Да';
            const corrected = rowData.previous_data ? 'Да' : 'Не';
            const eventType =
                rowData.previous_data && rowData.previous_data.event;

            return {
                Администратор: rowData.Administrator.name,
                'Нова информация': formattedNewInfo,
                'Стара информация': formattedOldInfo, // Updated to use formattedOldInfo
                'Вид Събите': eventType,
                'Ръчно добавено': manualAdded,
                Коригирано: corrected,
                'Дата на създаване на промяна': formattedCreatedAt,
            };
        });

        const worksheet = XLSX.utils.json_to_sheet(transformedData);

        XLSX.utils.book_append_sheet(workbook, worksheet, 'Changes');
        const excelBuffer = XLSX.write(workbook, {
            bookType: 'xlsx',
            type: 'array',
        });
        const data = new Blob([excelBuffer], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        saveAs(data, 'Промени.xlsx');
    };

    return (
        <div>
            <MaterialTable
                title="Промени"
                columns={columns}
                data={changes}
                tableRef={tableRef}
                style={{
                    borderRadius: '0',
                    boxShadow: 'none',
                }}
                actions={[
                    {
                        icon: 'refresh',
                        tooltip: 'Refresh',
                        isFreeAction: true,
                        onClick: retrieveChanges,
                    },
                    {
                        icon: () => <DescriptionIcon />,
                        tooltip: 'Export to Excel',
                        isFreeAction: true,
                        onClick: exportTable,
                    },
                    // {
                    //     icon: 'save',
                    //     tooltip: 'Export to CSV',
                    //     isFreeAction: true,
                    //     onClick: () => {
                    //         const rows = changes.map((rowData) => {
                    //             let newRow = {};
                    //             columns.forEach((column) => {
                    //                 if (column.render) {
                    //                     newRow[column.field] =
                    //                         column.render(rowData);
                    //                 } else {
                    //                     newRow[column.field] =
                    //                         rowData[column.field];
                    //                 }
                    //             });
                    //             return newRow;
                    //         });
                    //         const headers = columns.map(
                    //             (column) => column.title
                    //         );
                    //         exportToCSV(rows, headers);
                    //     },
                    // },
                ]}
                options={{
                    loadingType: 'linear',
                    pageSize: 50,
                    maxBodyHeight: '68vh',
                    showTitle: true,
                    sorting: true,
                    actionsColumnIndex: -1,
                    filtering: true,
                    pageSizeOptions: [20, 50, 100, 200, 300, 400, 500],
                }}
                components={components}
                onSelectionChange={(rows) => {
                    setSelectedRows(rows);
                }}
                localization={{
                    pagination: {
                        labelDisplayedRows: '{from}-{to} от {count}',
                        labelRowsSelect: 'реда',
                    },
                    header: {
                        actions: 'Действия',
                    },
                    body: {
                        emptyDataSourceMessage: 'Няма промени...',
                        editTooltip: 'Редактирай',
                        deleteTooltip: 'Премахни',
                        filterRow: {
                            filterTooltip: 'Филтър',
                        },
                        editRow: {
                            deleteText:
                                'Сигурни ли сте че искате да изтриете промяната?',
                            cancelTooltip: 'Отказ',
                            saveTooltip: 'Запази',
                        },
                    },
                    toolbar: {
                        exportTitle: 'Експорт',
                        exportName: 'Експорт към CSV',
                        searchTooltip: 'Tърси',
                        searchPlaceholder: 'Tърси',
                        nRowsSelected: '{0} реда избрани',
                    },
                }}
            />
        </div>
    );
};

export default EventsTable;
