import http from "../http-common"

const adminAll = () => {
	return http.get(`/v1/users`)
}

const getAll = loc => {
	return http.get(`/v1/users/loc/${loc}`)
}

const adminAllFired = () => {
	return http.get(`/v1/users/off`)
}

const getAllFired = loc => {
	return http.get(`/v1/users/loc/${loc}/off`)
}

const get = sap => {
	return http.get(`/v1/users/${sap}`)
}

const create = data => {
	return http.post("/v1/users", data)
}

const update = (sap, data) => {
	return http.put(`/v1/users/${sap}`, data)
}

const remove = sap => {
	return http.delete(`/v1/users/${sap}`)
}

const removeAll = () => {
	return http.delete(`/v1/users`)
}

// const findByFullName = fullname => {
//     return http.get(`/v1/users?fullname=${fullname}`);
// };

export default {
	adminAll,
	getAll,
	adminAllFired,
	getAllFired,
	get,
	create,
	update,
	remove,
	removeAll,
	// findByFullName
}
