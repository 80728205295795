import { makeStyles } from '@material-ui/core/styles';

const drawerWidth = 280;
const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    toolbar: {
        backgroundColor: 'white',
        paddingRight: theme.spacing(1), // keep right padding when drawer closed
        paddingLeft: theme.spacing(1),
    },
    toolbarIcon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    appBar: {
        [theme.breakpoints.up('sm')]: {
            width: `calc(100% - ${drawerWidth}px)`,
            marginLeft: drawerWidth,
        },
    },
    // appBarShift: {
    // 	marginLeft: drawerWidth,
    // 	width: `calc(100% - ${drawerWidth}px)`,
    // 	transition: theme.transitions.create(["width", "margin"], {
    // 		easing: theme.transitions.easing.sharp,
    // 		duration: theme.transitions.duration.enteringScreen,
    // 	}),
    // },
    menuButton: {
        marginLeft: theme.spacing(0),
        marginRight: theme.spacing(0),
        color: '#003d4d',
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
    avatarButton: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        color: '#003d4d',
    },
    adminButton: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        color: '#003d4d',
    },
    title: {
        flexGrow: 1,
    },
    drawer: {
        [theme.breakpoints.up('sm')]: {
            width: drawerWidth,
            flexShrink: 0,
        },
    },
    drawerPaper: {
        whiteSpace: 'nowrap',
        background: 'linear-gradient(#006987 0%, #003544 100%)',
        border: 'none',
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
        overflow: 'hidden',
        [theme.breakpoints.up('sm')]: {
            width: drawerWidth,
            flexShrink: 0,
        },
    },
    drawerPaperClose: {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9),
        },
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    smallContainer: {
        paddingTop: theme.spacing(20),
        paddingBottom: theme.spacing(4),
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    smallPaper: {
        marginTop: theme.spacing(3),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    supportSmallContainer: {
        paddingTop: theme.spacing(25),
        paddingBottom: theme.spacing(4),
    },
    supportPaperContainer: {
        marginTop: theme.spacing(0),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    fixedHeight: {
        height: 240,
    },
    card: {
        alignItems: 'center',
        borderRadius: '12px',
    },
    chips: {
        display: 'flex',
        flexWrap: 'wrap',
        '& > *': {
            margin: theme.spacing(0.5),
        },
    },
    logo: {
        '& .logo-icon': {
            width: '170px',
            margin: theme.spacing(2.5, 6),
            transition: theme.transitions.create(['width'], {
                duration: theme.transitions.duration.shortest,
                easing: theme.transitions.easing.easeInOut,
            }),
        },
    },
    item: (props) => ({
        height: 40,
        /* width: 'calc(100% - 16px)', */
        borderRadius: '0',
        /* paddingRight: 12, */
        paddingLeft: props.itemPadding > 80 ? 80 : props.itemPadding,
        '&.active': {
            backgroundColor: '#8adc39',
            color: 'white !important',
            pointerEvents: 'none',
            transition: 'border-radius .15s cubic-bezier(0.4,0.0,0.2,1)',
            '& .list-item-text-primary': {
                color: 'inherit',
            },
            '& .list-item-icon': {
                color: theme.palette.text.icon,
            },
            width: '100%',
        },
        '& .list-item-icon': {
            marginRight: 16,
            color: theme.palette.text.icon,
        },
        '& .list-item-text': {},
        color: theme.palette.text.icon,
        cursor: 'pointer',
        textDecoration: 'none!important',
    }),
    itemtext: {
        fontSize: '0.8rem',
    },
    passwordIcon: {
        color: '#ccc',
        marginLeft: '12px',
        marginRight: '12px',
    },
    exitIcon: {
        marginLeft: '12px',
        marginRight: '12px',
    },
    notificationsIcon: {
        color: '#c4c4c4',
        marginRight: theme.spacing(2.5),
    },
    nested: {
        fontSize: '0.9rem',
        height: 40,
        paddingLeft: '35px',
    },
    collapse: {
        opacity: 0,
        transition: 'all 10ms ease-in-out',
    },
    nestedIcon: {
        color: 'white !important',
        minWidth: '35px !important',
    },
    list: {
        width: 350,
        [theme.breakpoints.down('lg')]: {
            width: 280,
        },
    },
    fullList: {
        width: 'auto',
    },
    icon: {
        color: 'white',
    },
    navlink: {
        color: 'white !important',
        cursor: 'pointer',
        textDecoration: 'none',
        height: '40px',
        padding: '8px 16px',
        display: 'block',
    },
    navText: {
        position: 'fixed',
        padding: '0 10px',
    },
    centered: {
        display: 'grid',
        justifyContent: 'center',
        alignItems: 'center',
    },
}));

export { useStyles };
