import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import React, { useEffect, useRef, useState } from 'react';
import TextField from '@material-ui/core/TextField';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Alert from '@material-ui/lab/Alert';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Chip from '@material-ui/core/Chip';
import LocationDataService from '../../services/LocationService';
import { AutoDisabler, Validate, ValidationGroup } from 'mui-validate';

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(0.5),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    root: (props) => ({
        flexGrow: 1,
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: '31.2ch',
        },
        '& .MuiButton-root': {
            margin: theme.spacing(1),
        },
        '& .MuiAlert-root': {
            width: '100%',
            '& > * + *': {
                marginTop: theme.spacing(1),
            },
        },
    }),
    small: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: '29ch',
        },
        '& .MuiButton-root': {
            margin: theme.spacing(1),
        },
    },
    paper: {
        textAlign: 'center',
    },
    item: {
        height: '95%',
        alignItems: 'center',
    },
    chips: {
        '& > *': {
            margin: theme.spacing(0.5),
        },
        height: '20rem',
        overflow: 'scroll',
    },
}));

function LocationForm() {
    const classes = useStyles();

    const [loc] = React.useState(() => {
        const locData = localStorage.getItem('loc');
        return locData ? JSON.parse(locData).id : '';
    });

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setLocation({ ...location, [name]: value });
    };

    const [locationMessage, setLocationMessage] = useState('');
    const initialLocationState = {
        id: null,
        name: '',
    };

    const [location, setLocation] = useState(initialLocationState);
    const [submitted, setSubmitted] = useState(false);

    const saveLocation = () => {
        var data = {
            name: location.name,
            number: biggestLocNum + 1,
        };

        LocationDataService.create(data)
            .then((response) => {
                setLocation({
                    id: response.data.id,
                    name: response.data.name,
                    number: response.data.number,
                });
                setSubmitted(true);
                setLocationMessage(
                    <Alert severity="success">
                        Успешно добавихте локацията!
                    </Alert>
                );
                console.log(response.data);
                retrieveLocations();
                setTimeout(() => {
                    retrieveDeletedLocations();
                    setLocationMessage();
                }, 2500);
            })
            .catch((e) => {
                console.log(e);
            });
    };

    const [locations, setLocations] = useState([]);
    const [biggestLocNum, setBiggestLocNum] = useState([]);

    useEffect(() => {
        retrieveLocations();
        retrieveDeletedLocations();
        // const interval = setInterval(() => {
        //     retrieveDeletedLocations()
        // }, 5000);
        // return () => clearInterval(interval);
    }, []);

    const retrieveLocations = () => {
        LocationDataService.getAll(loc)
            .then((response) => {
                setLocations(response.data);
                console.log(response.data);
            })
            .catch((e) => {
                console.log(e);
            });
    };

    const retrieveDeletedLocations = () => {
        LocationDataService.getAllDeleted()
            .then((response) => {
                var arrObjIds = response.data.map((elements) => {
                    return elements.number;
                });
                var maxId = Math.max(...arrObjIds);
                setBiggestLocNum(maxId);
            })
            .catch((e) => {
                console.log(e);
            });
    };

    const handleDelete = (id) => {
        LocationDataService.remove(id)
            .then((response) => {
                setLocationMessage(
                    <Alert severity="success">
                        Успешно премахнахте локацията!
                    </Alert>
                );
                console.log(response.data);
                retrieveLocations();
                retrieveDeletedLocations();
                setTimeout(() => {
                    setLocationMessage();
                }, 5000);
            })
            .catch((e) => {
                console.log(e);
            });
    };

    const ref = useRef(null);

    return (
        <Card className={classes.item}>
            <AppBar position="static" elevation={0} color="primary">
                <Toolbar className="px-8">
                    <Typography
                        variant="subtitle1"
                        color="inherit"
                        className="flex-1 px-12"
                    >
                        Добави Локация
                    </Typography>
                </Toolbar>
            </AppBar>
            {locationMessage}
            <CardContent>
                <div className="edit-form">
                    <form className={classes.small}>
                        <ValidationGroup>
                            <Validate
                                name="name"
                                required={[true, 'Това поле е задължително!']}
                                regex={[
                                    /^[A-Za-z0-9 ]*$/,
                                    'Моля, въведете само букви и цифри.',
                                ]}
                            >
                                <TextField
                                    required
                                    id="outlined-required name"
                                    label="Локация"
                                    variant="outlined"
                                    name="name"
                                    value={location.name}
                                    onChange={handleInputChange}
                                    size="small"
                                />
                            </Validate>
                            <AutoDisabler>
                                <Button
                                    onClick={saveLocation}
                                    variant="contained"
                                    color="primary"
                                >
                                    Добави
                                </Button>
                            </AutoDisabler>
                        </ValidationGroup>
                    </form>
                </div>
                <div className={classes.chips}>
                    {locations.map((location) => (
                        <Chip
                            ref={ref}
                            id={location.id}
                            onDelete={() => handleDelete(location.id)}
                            label={location.name}
                        />
                    ))}
                </div>
            </CardContent>
        </Card>
    );
}

export default LocationForm;
