import { SET_SYSTEM_MESSAGES } from './systemMessagesActions';

const initialState = {
    systemMessages: [],
};
const systemMessagesReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_SYSTEM_MESSAGES:
            return {
                ...state,
                systemMessages: action.payload,
            };
        default:
            return state;
    }
};

export default systemMessagesReducer;
