import React from 'react';
import Grid from '@material-ui/core/Grid';
import WorkingToday from './widgets/WorkingToday/WorkingToday';
import WorkingNow from './widgets/WorkingNow/WorkingNow';
import useCheckPermission from '../../utils/permissions/useCheckPermissions';

// const data = [
//     { year: '1950', population: 2.525 },
//     { year: '1960', population: 3.018 },
//     { year: '1970', population: 3.682 },
//     { year: '1980', population: 4.440 },
//     { year: '1990', population: 5.310 },
//     { year: '2000', population: 6.127 },
//     { year: '2010', population: 6.930 },
// ];

const HomeDashboardWidgets = () => {
    const isSuper = useCheckPermission('super');

    return (
        <div>
            {/*{isSuper && (*/}
            {/*    <Grid container spacing={3}>*/}
            {/*        <Grid item xs={12} md={3} lg={4}>*/}
            {/*            <div className="widget flex w-full p-12">*/}
            {/*                <VotedYes />*/}
            {/*            </div>*/}
            {/*        </Grid>*/}
            {/*        <Grid item xs={12} md={3} lg={4}>*/}
            {/*            <div className="widget flex w-full p-12">*/}
            {/*                <VotedNo />*/}
            {/*            </div>*/}
            {/*        </Grid>*/}
            {/*        <Grid item xs={12} md={3} lg={4}>*/}
            {/*            <div className="widget flex w-full p-12">*/}
            {/*                <VotedAbstained />*/}
            {/*            </div>*/}
            {/*        </Grid>*/}
            {/*    </Grid>*/}
            {/*)}*/}
            <Grid container spacing={3}>
                <Grid item xs={12} md={3} lg={3}>
                    <div className="widget flex w-full p-12">
                        <WorkingNow />
                    </div>
                </Grid>
                <Grid item xs={12} md={3} lg={3}>
                    <div className="widget flex w-full p-12">
                        <WorkingToday />
                    </div>
                </Grid>
                {/* <Grid item xs={3}>
				<div className='widget flex w-full sm:w-1/2 md:w-1/4 p-12'>
					<LunchToday />
				</div>
			</Grid> */}
            </Grid>
        </div>
    );
};

export default HomeDashboardWidgets;
