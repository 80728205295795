import React, { useEffect, useState } from 'react';
import {
    Checkbox,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from '@mui/material';
import AdministratorsRolesService from '../../../services/AdministratorsRolesService';

const SystemRoles = () => {
    useEffect(() => {
        retrieveAdminRoles();
    }, []);

    const [adminRoles, setAdminRoles] = useState([]);

    const retrieveAdminRoles = () => {
        AdministratorsRolesService.getAll()
            .then((response) => {
                setAdminRoles(response.data);
            })
            .catch((e) => {
                console.log(e);
            });
    };

    // Function to handle checkbox change
    const handleCheckboxChange = (event, rowId, columnId) => {
        // Logic to update the data based on checkbox change
    };

    return (
        <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell>Вид права:</TableCell>
                        <TableCell align="center">
                            Може да създава всичко
                        </TableCell>
                        <TableCell align="center">
                            Може да вижда всичко
                        </TableCell>
                        <TableCell align="center">
                            Може да редактира всичко
                        </TableCell>
                        <TableCell align="center">
                            Може да изтрива всичко
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {adminRoles.map((row, rowIndex) => (
                        <TableRow
                            key={row.id}
                            sx={{
                                '&:last-child td, &:last-child th': {
                                    border: 0,
                                },
                            }}
                        >
                            <TableCell component="th" scope="row">
                                {row.role_name}
                            </TableCell>
                            <TableCell align="center" key={row.id}>
                                <Checkbox checked={row.c} />
                            </TableCell>
                            <TableCell align="center" key={row.id}>
                                <Checkbox checked={row.r} />
                            </TableCell>
                            <TableCell align="center" key={row.id}>
                                <Checkbox checked={row.u} />
                            </TableCell>
                            <TableCell align="center" key={row.id}>
                                <Checkbox checked={row.d} />
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default SystemRoles;
