import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import React, { useEffect, useState } from 'react';
import TextField from '@material-ui/core/TextField';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Alert from '@material-ui/lab/Alert';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Chip from '@material-ui/core/Chip';
import DepartmentDataService from '../../services/DepartmentService';
import LocationDataService from '../../services/LocationService';
import MenuItem from '@material-ui/core/MenuItem';
import { AutoDisabler, Validate, ValidationGroup } from 'mui-validate';

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(0.5),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    root: (props) => ({
        flexGrow: 1,
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: '31.2ch',
        },
        '& .MuiButton-root': {
            margin: theme.spacing(1),
        },
        '& .MuiAlert-root': {
            width: '100%',
            '& > * + *': {
                marginTop: theme.spacing(1),
            },
        },
    }),
    small: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: '29ch',
        },
        '& .MuiButton-root': {
            margin: theme.spacing(1),
        },
    },
    paper: {
        textAlign: 'center',
    },
    item: {
        height: '95%',
        alignItems: 'center',
    },
    chips: {
        '& > *': {
            margin: theme.spacing(0.5),
        },
        height: '20rem',
        overflow: 'scroll',
    },
}));

function DepartmentForm() {
    const classes = useStyles();

    const [loc] = React.useState(() => {
        const locData = localStorage.getItem('loc');
        return locData ? JSON.parse(locData).id : '';
    });

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setDepartment({ ...department, [name]: value });
    };

    const [departmentMessage, setDepartmentMessage] = useState('');
    const initialDepartmentState = {
        id: null,
        name: '',
        parent: '',
    };

    const [department, setDepartment] = useState(initialDepartmentState);
    const [locations, setLocations] = useState([]);
    const [biggestDepNum, setBiggestDepNum] = useState([]);

    const [submitted, setSubmitted] = useState(false);

    const saveDepartment = () => {
        var data = {
            name: department.name,
            parent: department.parent,
        };

        DepartmentDataService.create(data)
            .then((response) => {
                setDepartment({
                    id: response.data.id,
                    name: response.data.name,
                    parent: response.data.parent,
                });
                setSubmitted(true);
                setDepartmentMessage(
                    <Alert severity="success">Успешно добавихте отдела!</Alert>
                );
                console.log(response.data);
                retrieveDepartments();
                setTimeout(() => {
                    retrieveDeletedDepartments();
                    setDepartmentMessage();
                }, 2500);
            })
            .catch((e) => {
                if (e.response.status === 500) {
                    setDepartmentMessage(
                        <Alert severity="error">
                            Отдела не можа да бъде добавен, моля опитайте
                            отново!
                        </Alert>
                    );
                    setTimeout(() => {
                        retrieveDeletedDepartments();
                        setDepartmentMessage();
                    }, 2500);
                } else if (e.response.status === 409) {
                    setDepartmentMessage(
                        <Alert severity="error">
                            Отдел с такова име съществува за тази локация!
                        </Alert>
                    );
                    setTimeout(() => {
                        retrieveDeletedDepartments();
                        setDepartmentMessage();
                    }, 2500);
                }
                console.log(e);
            });
    };

    const [departments, setDepartments] = useState([]);

    useEffect(() => {
        retrieveDepartments();
        retrieveLocations();
        retrieveDeletedDepartments();
    }, []);

    const retrieveLocations = () => {
        LocationDataService.getAll(loc)
            .then((response) => {
                setLocations(response.data);
                console.log(response.data);
            })
            .catch((e) => {
                console.log(e);
            });
    };

    const retrieveDepartments = () => {
        DepartmentDataService.adminAll()
            .then((response) => {
                setDepartments(response.data);
            })
            .catch((e) => {
                setErrorMessages(['Cannot load user data']);
                setIserror(true);
            });
    };

    const retrieveDeletedDepartments = () => {
        DepartmentDataService.getAllDeleted()
            .then((response) => {
                var arrObjIds = response.data.map((elements) => {
                    return elements.id;
                });
                var maxId = Math.max(...arrObjIds);
                setBiggestDepNum(maxId);
            })
            .catch((e) => {
                console.log(e);
            });
    };

    //for error handling
    const [iserror, setIserror] = useState(false);
    const [errorMessages, setErrorMessages] = useState([]);

    const handleDelete = (id) => {
        DepartmentDataService.remove(id)
            .then((response) => {
                setDepartmentMessage(
                    <Alert severity="success">
                        Успешно премахнахте отдела!
                    </Alert>
                );
                console.log(response.data);
                retrieveDepartments();
                retrieveDeletedDepartments();
                setTimeout(() => {
                    setDepartmentMessage();
                }, 5000);
            })
            .catch((e) => {
                console.log(e);
            });
    };
    return (
        <Card className={classes.item}>
            <AppBar position="static" elevation={0} color="primary">
                <Toolbar className="px-8">
                    <Typography
                        variant="subtitle1"
                        color="inherit"
                        className="flex-1 px-12"
                    >
                        Добави Отдел
                    </Typography>
                </Toolbar>
            </AppBar>
            {departmentMessage}
            <CardContent>
                <div className="edit-form">
                    <ValidationGroup>
                        <form className={classes.small}>
                            <TextField
                                id="outlined-select-location parent"
                                select
                                required
                                label="Изберете Локация"
                                name="parent"
                                value={department.parent}
                                onChange={handleInputChange}
                                size="small"
                                variant="outlined"
                            >
                                {locations.map((location) => (
                                    <MenuItem
                                        key={location.id}
                                        value={location.number}
                                    >
                                        {location.name}
                                    </MenuItem>
                                ))}
                            </TextField>

                            <Validate
                                name="name"
                                required={[true, 'Това поле е задължително!']}
                                regex={[
                                    /^[A-Za-z0-9 ]*$/,
                                    'Моля, въведете само букви и цифри.',
                                ]}
                            >
                                <TextField
                                    required
                                    id="outlined-required name"
                                    label="Отдел"
                                    variant="outlined"
                                    name="name"
                                    value={department.name}
                                    onChange={handleInputChange}
                                    size="small"
                                />
                            </Validate>
                            <AutoDisabler>
                                <Button
                                    onClick={saveDepartment}
                                    variant="contained"
                                    color="primary"
                                >
                                    Добави
                                </Button>
                            </AutoDisabler>
                        </form>
                    </ValidationGroup>
                </div>
                <div className={classes.chips}>
                    {departments.map((department) => (
                        <Chip
                            id={department.id}
                            onDelete={() => handleDelete(department.id)}
                            label={
                                department.name +
                                ' - ' +
                                department.ParentLocation.name
                            }
                        />
                    ))}
                </div>
            </CardContent>
        </Card>
    );
}

export default DepartmentForm;
