import http from '../http-common';

const getAll = () => {
    return http.get('/v1/admins');
};

const get = (id) => {
    return http.get(`/v1/admins/${id}`);
};

const create = (data) => {
    return http.post('/v1/admins', data);
};

const update = (id, data) => {
    return http.put(`/v1/admins/${id}`, data);
};

const updateRole = (id, data) => {
    return http.put(`/v1/admins/status/${id}`, data);
};

const remove = (id) => {
    return http.delete(`/v1/admins/${id}`);
};

const removeAll = () => {
    return http.delete(`/v1/admins`);
};

export default {
    getAll,
    get,
    create,
    update,
    updateRole,
    remove,
    removeAll,
};
