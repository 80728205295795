import http from "../http-common";

const getAll = loc => {
    return http.get(`/v1/templates/${loc}`);
};

const get = userId => {
    return http.get(`/v1/templates/${userId}`);
};

const getUserTemplates = id => {
    return http.get(`/v1/templates/user/${id}`);
};

const create = data => {
    return http.post("/v1/templates", data);
};

const update = (id, data) => {
    return http.put(`/v1/templates/${id}`, data);
};

const remove = id => {
    return http.delete(`/v1/templates/${id}`);
};

const removeAll = () => {
    return http.delete(`/v1/templates`);
};

export default {
    getAll,
    get,
    getUserTemplates,
    create,
    update,
    remove,
    removeAll
};