import http from '../http-common';

const getAll = () => {
    return http.get(`/v1/web-versions/`);
};

const getAllWithChanges = () => {
    return http.get(`/v1/web-versions/withChanges`);
};

const get = (id) => {
    return http.get(`/v1/web-versions/${id}`);
};

const create = (data) => {
    return http.post('/v1/web-versions', data);
};

const update = (id, data) => {
    return http.put(`/v1/web-versions/${id}`, data);
};

const remove = (id) => {
    return http.delete(`/v1/web-versions/${id}`);
};

const removeAll = () => {
    return http.delete(`/v1/web-versions`);
};

export default {
    getAll,
    getAllWithChanges,
    get,
    create,
    update,
    remove,
    removeAll,
};
