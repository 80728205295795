import React, { useEffect, useState } from 'react';
import useCheckPermission from '../../utils/permissions/useCheckPermissions';
import MaterialTable, { MTableFilterRow, MTableToolbar } from 'material-table';
import EventDataService from '../../services/EventService';
import DepartmentDataService from '../../services/DepartmentService';
import Grid from '@material-ui/core/Grid';
import 'date-fns';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import LocationDataService from '../../services/LocationService';
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx/dist/xlsx.full.min.js';
import DescriptionIcon from '@material-ui/icons/Description';
import dayjs from 'dayjs';
import {
    LocalizationProvider,
    MobileDatePicker,
    MobileDateTimePicker,
} from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import 'dayjs/locale/bg';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import ModalForm from './Modal';

const useStyles = makeStyles((theme) => ({
    button: {
        marginLeft: theme.spacing(1),
        marginBottom: theme.spacing(1),
        marginTop: theme.spacing(2),
    },
    grid: { marginLeft: theme.spacing(2) },
    toolbar: { marginLeft: theme.spacing(10) },
    absoluteContainer: {
        position: 'absolute',
        zIndex: 9,
        width: '45vw',
        [theme.breakpoints.down('md')]: {
            position: 'initial',
        },
    },
}));

function EventsTable() {
    // ! Permissions Check
    const isSuper = useCheckPermission('super');
    const canRead = useCheckPermission('r');
    const canCreate = useCheckPermission('c');
    const canUpdate = useCheckPermission('u');
    const canDelete = useCheckPermission('d');

    const classes = useStyles();
    const [events, setEvent] = useState([]);

    const [loc] = React.useState(() => {
        const locData = localStorage.getItem('loc');
        return locData ? JSON.parse(locData).id : '';
    });

    const tableRef = React.createRef();

    useEffect(() => {
        retrieveDepartments();
        retrieveLocations();
        retrieveEvents();
    }, []);

    const retrieveEvents = async () => {
        const locData = JSON.parse(localStorage.getItem('loc'));

        try {
            let response;

            if (Array.isArray(locData)) {
                const joinedValues = locData.join(',');
                response = await EventDataService.getAll(joinedValues);
            } else if (locData.id === 'all') {
                response = await EventDataService.adminAll();
            }

            if (response) {
                setEvent(response.data);
                console.log(response.data);
            }
        } catch (e) {
            console.log(e);
        }
    };

    const [isLoading, setIsLoading] = useState(false);

    const retrieveFilteredEvents = async (resolve) => {
        setIsLoading(true); // Start loading

        const locData = JSON.parse(localStorage.getItem('loc'));

        // Extend dayjs with the plugins
        dayjs.extend(utc);
        dayjs.extend(timezone);

        // Set the locale for dayjs
        dayjs.locale('bg');

        // Function to format the date in the API's expected format
        const formatDate = (date) => {
            // Convert to Bulgarian time zone, set to the start of the day
            const localDate = dayjs(date).tz('Europe/Sofia').startOf('day');
            // Format the date without the timezone offset
            return localDate.format('YYYY-MM-DD');
        };

        const selectedStartDateFormatted = formatDate(selectedStartDate);
        const selectedEndDateFormatted = formatDate(selectedEndDate);

        // Action to perform on successful data retrieval
        const onSuccess = (response) => {
            setEvent(response.data);
            console.log(response.data);
            tableRef.current.onQueryChange();
            resolve();
        };

        try {
            let response;

            if (Array.isArray(locData)) {
                const joinedValues = locData.join(',');
                response = await EventDataService.getAllDateFilter(
                    joinedValues,
                    selectedStartDateFormatted,
                    selectedEndDateFormatted
                );
            } else if (locData.id === 'all') {
                response = await EventDataService.adminAllDateFilter(
                    selectedStartDateFormatted,
                    selectedEndDateFormatted
                );
            }

            if (response) {
                onSuccess(response);
                // setEvent(response.data);
                // console.log(response.data);
            }
        } catch (e) {
            console.log(e);
        } finally {
            setIsLoading(false); // End loading regardless of success or failure
        }
    };

    // * Retrieve Locations & Departments
    const [departments, setDepartments] = useState([]);

    const [departmentsOptions, setDepartmentsOptions] = useState([]);
    const [locationOptions, setLocationOptions] = useState([]);

    const retrieveLocations = () => {
        LocationDataService.getAll()
            .then((response) => {
                return response.data.reduce((acc, item) => {
                    acc[item.name] = item.name;
                    setLocationOptions(acc);
                    return acc;
                }, {});
            })
            .catch((e) => {
                setErrorMessages(['Cannot load user data']);
                setIserror(true);
            });
    };

    const retrieveDepartments = () => {
        if (JSON.parse(localStorage.getItem('loc')).id >= 0) {
            DepartmentDataService.getAll(loc)
                .then((response) => {
                    setDepartments(response.data);
                    return response.data.reduce((acc, item) => {
                        acc[item.name] = item.name;
                        setDepartmentsOptions(acc);
                        return acc;
                    }, {});
                })
                .catch((e) => {
                    setErrorMessages(['Cannot load user data']);
                    setIserror(true);
                });
        } else if (JSON.parse(localStorage.getItem('loc')).id === 'all') {
            DepartmentDataService.adminAll()
                .then((response) => {
                    setDepartments(response.data);
                    return response.data.reduce((acc, item) => {
                        acc[item.name] = item.name;
                        setDepartmentsOptions(acc);
                        return acc;
                    }, {});
                })
                .catch((e) => {
                    setErrorMessages(['Cannot load user data']);
                    setIserror(true);
                });
        }
    };
    // * End of retrieving Locations & Deparments

    //for error handling
    const [iserror, setIserror] = useState(false);
    const [errorMessages, setErrorMessages] = useState([]);

    const handleEventRemove = (Event, resolve) => {
        EventDataService.remove(Event.id)
            .then((res) => {
                const dataRemove = [...events];
                const index = Event.tableData.id;
                dataRemove.splice(index, 1);
                setEvent([...dataRemove]);
                resolve();
            })
            .catch((error) => {
                setErrorMessages(['Remove failed! Server error']);
                setIserror(true);
            });
    };

    //! Edit User Event Start
    const [submitted, setSubmitted] = useState(false);

    const handleEventUpdate = async (oldEvent) => {
        // Extract the JWT from the "Biodit" cookie
        const jwtFromCookie = document.cookie
            .split('; ')
            .find((row) => row.startsWith('Biodit='))
            .split('=')[1];

        // Decode the JWT to get the adminId
        const decodedJwt = JSON.parse(atob(jwtFromCookie.split('.')[1]));
        const adminId = decodedJwt.id; // Assuming the payload has an 'id' property

        try {
            // Set oldEvent.genuine to false
            oldEvent.genuine = false;

            // Add the adminId to the data being sent to the server
            oldEvent.adminId = adminId;

            const response = await EventDataService.update(
                oldEvent.id,
                oldEvent
            );
            const updatedEvent = response.data;

            // Merge the oldEvent with the updatedEvent data
            const mergedEvent = { ...oldEvent, ...updatedEvent };

            setEvent((prevEvents) =>
                prevEvents.map((event) =>
                    event.id === mergedEvent.id ? mergedEvent : event
                )
            );
        } catch (e) {
            console.error('Error in handleEventUpdate:', e);
            throw e;
        }
    };

    //! Edit User Event Stop

    const [open, setOpen] = React.useState(false);
    const handleClickOpen = () => {
        setOpen(true);
    };

    // var loc_name = events.reduce(function (acc, cur, i) {
    //     acc[cur.id] = cur.userEvent.name;

    //     return acc;
    // }, {});

    // var sub_loc_name = departments.reduce(function (acc, sub_loc, i) {
    //     acc[sub_loc.id] = sub_loc.name;

    //     return acc;
    // }, {});

    const SPACED_DATE_FORMAT = 'DD/MMM/YYYY H:MM:ss';

    // * Add Filters to State Start
    const [selectedRows, setSelectedRows] = useState([]);

    // * Date Hooks
    const [selectedStartDate, handleStartDateChange] = useState(null);

    const [selectedEndDate, handleEndDateChange] = useState(null);

    const [components] = useState({
        Toolbar: (props) => (
            <div>
                <MTableToolbar {...props} />
            </div>
        ),
        FilterRow: (props) => <MTableFilterRow {...props} />,
    });

    const [columns] = useState([
        // {
        //     title: 'Локация',
        //     field: 'location_name',
        //     editable: 'never',
        //     emptyValue: (e, rowData) => {
        //         return 'empty';
        //     },
        // },
        {
            title: 'Дата',
            field: 'time',
            type: 'datetime',
            width: '120px',
            dateSetting: { locale: 'bg-BG', hour12: false },
            filtering: false,
            emptyValue: (e, rowData) => {
                return 'empty';
            },
            editComponent: (props) => {
                // Convert the incoming value to a Dayjs object
                const [editDate, setEditDate] = useState(
                    props.value ? dayjs(props.value) : null
                );

                const handleEditDateChange = (newValue) => {
                    // newValue is a Dayjs object
                    setEditDate(newValue);
                    // Pass the updated value to material-table's onChange in a suitable format
                    // Depending on what format your backend expects you may need to format the date
                    props.onChange(newValue ? newValue.toISOString() : null);
                };

                return (
                    <LocalizationProvider
                        dateAdapter={AdapterDayjs}
                        adapterLocale={dayjs.locale('bg')} // Use the Dayjs locale method
                    >
                        <MobileDateTimePicker
                            margin="normal"
                            closeOnSelect={true}
                            id="selectedEditDate"
                            value={editDate} // Set the Dayjs object as the value
                            onChange={handleEditDateChange}
                            slotProps={{
                                textField: {
                                    variant: 'standard',
                                    size: 'small',
                                },
                            }}
                            formatDensity={'dense'}
                            ampm={false}
                        />
                    </LocalizationProvider>
                );
            },
        },
        {
            title: 'Име',
            field: 'employee_name',
            editable: 'never',
            emptyValue: (e, rowData) => {
                return 'empty';
            },
        },
        {
            title: 'Локация',
            field: 'location_name',
            editable: 'never',
            emptyValue: (e, rowData) => {
                return 'empty';
            },
        },
        {
            title: 'Ръчно',
            field: 'genuine',
            filtering: false,
            render: (rowData) => (rowData.genuine ? 'Не' : 'Да'),
            emptyValue: (e, rowData) => {
                return 'empty';
            },
            editable: 'never',
        },
        {
            title: 'Събитие',
            field: 'event',
            lookup: {
                enter: 'enter',
                exit: 'exit',
                access: 'access',
                antipassback: 'antipassback',
            },
            emptyValue: (e, rowData) => {
                return 'empty';
            },
        },

        {
            title: 'Устройство',
            field: 'device_name',
            editable: 'never',
            emptyValue: (e, rowData) => {
                return 'empty';
            },
        },
        {
            title: '# на Служител',
            field: 'sap',
            editable: 'never',
            cellStyle: { width: '10%' },
            width: '10%',
            headerStyle: { width: '10%' },
            emptyValue: (e, rowData) => {
                return 'empty';
            },
        },
        {
            title: 'Отдел',
            field: 'sub_loc_name',
            editable: 'never',
            emptyValue: (e, rowData) => {
                return 'empty';
            },
        },
    ]);
    // * Add Filters to State End

    // ! Format the Date and time columns for export
    const formatDate = (inputTime) => {
        if (inputTime && inputTime !== 'null' && inputTime !== 'empty') {
            const date = new Date(inputTime);
            return `${date.getDate().toString().padStart(2, '0')}.${(
                date.getMonth() + 1
            )
                .toString()
                .padStart(2, '0')}.${date.getFullYear()} г., ${date
                .getHours()
                .toString()
                .padStart(2, '0')}:${date
                .getMinutes()
                .toString()
                .padStart(2, '0')}:${date
                .getSeconds()
                .toString()
                .padStart(2, '0')} ч.`;
        }
        return 'empty';
    };

    // ! Header mapping for exported column titles
    const headerMapping = {};
    columns.forEach((col) => {
        headerMapping[col.field] = col.title;
    });

    const mapHeaders = (row) => {
        let newRow = {};
        for (let key in row) {
            if (headerMapping[key]) {
                newRow[headerMapping[key]] = row[key];
            } else {
                newRow[key] = row[key];
            }
        }
        return newRow;
    };

    // ! Format the name for the file
    const formatDateForFilename = (inputDate) => {
        const date = new Date(inputDate);
        return `${date.getFullYear()}-${(date.getMonth() + 1)
            .toString()
            .padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`;
    };

    const formattedStartDate = formatDateForFilename(selectedStartDate);
    const formattedEndDate = formatDateForFilename(selectedEndDate);

    // ! Export to XLS Function
    const exportTable = () => {
        // Transform the data
        const transformedData = events.map((row) => {
            // Check for << and >> conditions for first_time and last_time
            const firstTimeValue =
                row.first_time === '<<'
                    ? 'липсва вход'
                    : formatDate(row.first_time);
            const lastTimeValue =
                row.last_time === '>>'
                    ? 'липсва изход'
                    : formatDate(row.last_time);

            const genuine = row.genuine ? 'НЕ' : 'ДА';

            return {
                ...row,
                time: formatDate(row.time),
                first_time: firstTimeValue,
                last_time: lastTimeValue,
                genuine: genuine,
            };
        });

        // Only include displayed columns in the export
        const exportedData = transformedData.map((row) => {
            let newRow = {};
            columns.forEach((col) => {
                newRow[col.field] = row[col.field];
            });
            return mapHeaders(newRow);
        });

        // Export the data
        const ws = XLSX.utils.json_to_sheet(exportedData);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

        const buf = XLSX.write(wb, { bookType: 'xls', type: 'array' });
        saveAs(
            new Blob([buf], { type: 'application/vnd.ms-excel' }),
            `Events.xls`
        );
    };

    // ! Debouncing Update Button
    const [isUpdating, setIsUpdating] = useState(false);

    return (
        <div>
            <Grid
                container
                spacing={1}
                className={classes.absoluteContainer}
                style={{ paddingLeft: '16px' }}
            >
                <Grid item xs={4} lg={3} style={{ paddingTop: '20px' }}>
                    <LocalizationProvider
                        dateAdapter={AdapterDayjs}
                        adapterLocale="bg"
                    >
                        <MobileDatePicker
                            margin="normal"
                            closeOnSelect={true}
                            id="selectedStartDate"
                            label="Начална Дата"
                            defaultValue={dayjs()}
                            ampm={false}
                            value={selectedStartDate}
                            onChange={(date) => handleStartDateChange(date)}
                            slotProps={{
                                textField: {
                                    size: 'small',
                                },
                            }}
                        />
                    </LocalizationProvider>
                </Grid>
                <Grid item xs={4} lg={3} style={{ paddingTop: '20px' }}>
                    <LocalizationProvider
                        dateAdapter={AdapterDayjs}
                        adapterLocale="bg"
                    >
                        <MobileDatePicker
                            margin="normal"
                            closeOnSelect={true}
                            id="selectedEndDate"
                            label="Крайна Дата"
                            defaultValue={dayjs()}
                            value={selectedEndDate}
                            onChange={handleEndDateChange}
                            slotProps={{
                                textField: {
                                    size: 'small',
                                },
                            }}
                        />
                    </LocalizationProvider>
                </Grid>
                <Grid item xs={4} lg={6}>
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        className={classes.button}
                        onClick={retrieveFilteredEvents}
                    >
                        Филтрирай
                    </Button>
                </Grid>
            </Grid>
            {canCreate && <ModalForm buttonLabel="Добавяне" />}
            <MaterialTable
                title="Събития"
                columns={columns}
                data={events}
                tableRef={tableRef}
                style={{
                    borderRadius: '0',
                    boxShadow: 'none',
                }}
                actions={[
                    {
                        icon: 'refresh',
                        tooltip: 'Refresh',
                        isFreeAction: true,
                        onClick: retrieveEvents,
                    },
                    {
                        icon: () => <DescriptionIcon />,
                        tooltip: 'Export to Excel',
                        isFreeAction: true,
                        onClick: exportTable,
                    },
                ]}
                options={{
                    loadingType: 'linear',
                    // rowStyle: {
                    //     overflowWrap: 'break-word'
                    // },
                    // tableLayout: "fixed",
                    pageSize: 50,
                    maxBodyHeight: '68vh',
                    showTitle: false,
                    sorting: true,
                    actionsColumnIndex: -1,
                    exportButton: { csv: true, pdf: false },
                    filtering: true,
                    pageSizeOptions: [
                        20, 50, 100, 200, 300, 400, 500, 5000, 10000,
                    ],
                    search: true,
                    toolbar: true,
                }}
                components={components}
                editable={{
                    ...(canUpdate
                        ? {
                              onRowUpdate: (oldEvent) =>
                                  new Promise((resolve) => {
                                      handleEventUpdate(oldEvent, resolve);
                                      setTimeout(() => {
                                          window.location.reload(1);
                                      }, 1500); // 1.5 seconds delay
                                  }),
                          }
                        : {}),
                    ...(canDelete
                        ? {
                              onRowDelete: (Event) =>
                                  new Promise((resolve) => {
                                      handleEventRemove(Event, resolve);
                                  }),
                          }
                        : {}),
                }}
                // onSelectionChange={rows => {
                //     setSelectedRows(rows)
                // }}
                localization={{
                    pagination: {
                        labelDisplayedRows: '{from}-{to} от {count}',
                        labelRowsSelect: 'Събития',
                    },
                    header: {
                        actions: 'Действия',
                    },
                    body: {
                        emptyDataSourceMessage: 'Зареждаме събитията...',
                        editTooltip: 'Редактирай',
                        deleteTooltip: 'Премахни',
                        filterRow: {
                            filterTooltip: 'Филтър',
                        },
                        editRow: {
                            deleteText:
                                'Сигурни ли сте че искате да изтриете събитието?',
                            cancelTooltip: 'Отказ',
                            saveTooltip: 'Запази',
                        },
                    },
                    toolbar: {
                        exportTitle: 'Експорт',
                        exportName: 'Експорт към CSV',
                        searchTooltip: 'Tърси',
                        searchPlaceholder: 'Tърси',
                        nRowsSelected: '{0} реда избрани',
                    },
                }}
            />
        </div>
    );
}

export default EventsTable;
