import React, { useEffect, useState } from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import EventDataService from '../../../../services/EventService';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import WorkingNowTable from './WorkingNowTable';

const useStyles = makeStyles({
    root: {
        minWidth: 275,
    },
    title: {
        fontSize: '25px',
        color: 'black',
    },
    count: {
        fontSize: '50px',
        color: '#4caf50',
        textAlign: 'center',
    },
    countLoading: {
        fontSize: '25px',
        color: '#4caf50',
        textAlign: 'center',
    },
    pos: {
        marginBottom: 12,
    },
});

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton
                    aria-label="close"
                    className={classes.closeButton}
                    onClick={onClose}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);

export default function WorkingNow() {
    const classes = useStyles();

    const [count, setCount] = useState(null);

    useEffect(() => {
        retrieveEvents();
    }, []);

    const [loc] = React.useState(() => {
        const locData = localStorage.getItem('loc');
        return locData ? JSON.parse(locData).id : '';
    });
    const retrieveEvents = async () => {
        const locData = JSON.parse(localStorage.getItem('loc'));

        try {
            let response;

            if (Array.isArray(locData)) {
                const joinedValues = locData.join(',');
                response = await EventDataService.countNow(joinedValues);
            } else if (locData.id === 'all') {
                response = await EventDataService.adminCountNow();
            }

            if (response) {
                setCount(response.data.count);
                // console.log(response.data);
            }
        } catch (e) {
            console.log(e);
        }
    };

    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    const currentHost = window.location.hostname;
    const currentPort = window.location.port;
    const isBilla = currentHost === 'billa.biodit.bg';
    const isDemo = currentHost === 'attendance-demo.biodit.info';
    const isBricolage = currentHost === 'bricolage.biodit.com';
    const isSwissport = currentHost === 'swissport.biodit.bg';
    const isLocalhost = currentHost === 'localhost' && currentPort === '4000';
    const isLocalhostBilla =
        currentHost === 'localhost' && currentPort === '4001';

    return (
        <div>
            <Card className={classes.root}>
                <CardContent>
                    <Typography
                        className={classes.title}
                        color="textSecondary"
                        gutterBottom
                    >
                        Текущо на работа
                    </Typography>
                    {count !== null ? (
                        <Typography
                            className={classes.count}
                            variant="h5"
                            component="h2"
                            onClick={handleClickOpen}
                        >
                            {count}
                        </Typography>
                    ) : (
                        <Typography
                            className={classes.countLoading}
                            variant="h6"
                            component="h4"
                            onClick={handleClickOpen}
                        >
                            Зареждаме...
                        </Typography>
                    )}
                </CardContent>
                {/* <CardActions>
                <Button size="small">Learn More</Button>
            </CardActions> */}
            </Card>
            <Dialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
                maxWidth="lg"
            >
                <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                    Служители текущо на работа
                </DialogTitle>
                <DialogContent dividers>
                    {/*{isLocalhost && <WorkingNowTable />}*/}
                    {/*{isBilla && <WorkingNowTableBilla />}*/}
                    {/*{isDemo && <WorkingNowTableBilla />}*/}
                    {/*{isSwissport && <WorkingNowTable />}*/}
                    {/*{isBricolage && <WorkingNowTable />}*/}
                    <WorkingNowTable />

                    {/* <AddUserForm
                        addEmployeeToState={this.props.addEmployeeToState}
                        updateState={this.props.updateState}
                        toggle={this.toggle}
                        employee={this.props.employee} /> */}
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={handleClose} color="primary">
                        Затвори
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
