import http from '../http-common';

const adminAll = () => {
    return http.get(`/v1/entries`);
};

const adminGAP = () => {
    return http.get(`/v1/entries/gap`);
};

const getAll = (loc) => {
    return http.get(`/v1/entries/loc/${loc}`);
};

const adminAllToday = () => {
    return http.get(`/v1/entries/allToday`);
};

const getAllToday = (loc) => {
    return http.get(`/v1/entries/allToday/loc/${loc}`);
};

const adminCountToday = () => {
    return http.get(`/v1/entries/countToday`);
};

const adminAllNow = () => {
    return http.get(`/v1/entries/allNow`);
};

const getAllNow = (loc) => {
    return http.get(`/v1/entries/allNow/loc/${loc}`);
};

const adminCountNow = () => {
    return http.get(`/v1/entries/countNow`);
};

const countNow = (loc) => {
    return http.get(`/v1/entries/countNow/loc/${loc}`);
};

const adminAllDateFilter = (startDate, endDate) => {
    return http.get(`/v1/entries/start/${startDate}/end/${endDate}`);
};

const getAllDateFilter = (loc, startDate, endDate) => {
    return http.get(`/v1/entries/loc/${loc}/start/${startDate}/end/${endDate}`);
};

const adminFourHour = () => {
    return http.get(`/v1/entries/fourHour`);
};

const fourHour = (loc) => {
    return http.get(`/v1/entries/fourHour/loc/${loc}`);
};

const get = (id) => {
    return http.get(`/v1/entries/${id}`);
};

const create = (data) => {
    return http.post('/v1/entries', data);
};

const update = (id, data) => {
    return http.put(`/v1/entries/${id}`, data);
};

const remove = (id) => {
    return http.delete(`/v1/entries/${id}`);
};

const removeAll = () => {
    return http.delete(`/v1/entries`);
};

const countToday = (loc) => {
    return http.get(`/v1/entries/countToday/loc/${loc}`);
};

export default {
    adminAll,
    adminGAP,
    adminAllNow,
    adminCountNow,
    countNow,
    adminAllToday,
    adminCountToday,
    adminFourHour,
    adminAllDateFilter,
    getAllDateFilter,
    fourHour,
    getAll,
    getAllToday,
    getAllNow,
    get,
    create,
    update,
    remove,
    removeAll,
    countToday,
};
