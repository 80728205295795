import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Container from '@material-ui/core/Container';
import Alert from '@material-ui/lab/Alert';
import { API_URL } from '../../env.json';
import { useStyles } from '../styles/useStyles';

// const useStyles = makeStyles(theme => ({
// 	paper: {
// 		marginTop: theme.spacing(8),
// 		display: "flex",
// 		flexDirection: "column",
// 		alignItems: "center",
// 	},
// 	avatar: {
// 		margin: theme.spacing(1),
// 		backgroundColor: theme.palette.secondary.main,
// 	},
// 	form: {
// 		width: "100%", // Fix IE 11 issue.
// 		marginTop: theme.spacing(1),
// 	},
// }))

export default function PasswordResetForm(props) {
    const classes = useStyles();

    // * Password Reset Form API Start
    // ! For error handling
    const [iserror, setIserror] = useState(false);
    const [errorMessages, setErrorMessages] = useState([]);
    const [allocateMessage, setAllocationMessage] = useState('');
    const [allocateErrorMessage, setAllocationErrorMessage] = useState('');

    const [adminId] = React.useState(
        JSON.parse(localStorage.getItem('session')).session || ''
    );

    const [status, setStatus] = useState('Изпрати');

    const handleSubmit = async (e) => {
        e.preventDefault();
        setStatus('Изпращане...');
        const { name, email, message } = e.target.elements;
        let details = {
            name: name.value,
            email: email.value,
            message: message.value,
        };
        let response = await fetch(API_URL + '/support', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json;charset=utf-8',
            },
            body: JSON.stringify(details),
        });

        setAllocationMessage(
            <Alert severity="success">Заявката беше изпратена!</Alert>
        );
        let result = await response.json();
        setStatus(result);
    };

    const handleChange = (event) => {
        const { name, value } = event.target;
        setStatus({
            ...status,
            [name]: value,
        });
    };

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.smallPaper}>
                {allocateMessage}
                {allocateErrorMessage}
                <form
                    className={classes.form}
                    onSubmit={handleSubmit}
                    noValidate
                >
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        name="name"
                        label="Име"
                        type="text"
                        id="name"
                        onChange={handleChange}
                        value={status.name}
                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        name="email"
                        label="Имейл"
                        type="email"
                        id="email"
                        onChange={handleChange}
                        value={status.email}
                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        name="message"
                        label="Съобщение"
                        type="text"
                        id="message"
                        onChange={handleChange}
                        value={status.message}
                    />
                    {/* <TextField
						variant='outlined'
						margin='normal'
						required
						fullWidth
						name='password2'
						label='Повтори Парола'
						type='password'
						id='password2'
						onChange={onChange2}
						value={state.password2}
						error={errors.password2}
						style={{marginTop: "5px"}}
						className={classnames("", {
							invalid:
								classes.errors.password2 || classes.errors.passwordincorrect2,
						})}
						autoComplete='current-password'
					/>
					<span className='red-text' style={{color: "red"}}>
						{errors.password2}
						{errors.passwordincorrect2}
					</span> */}
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        style={{
                            marginTop: '5px',
                        }}
                        className={classes.submit}
                    >
                        Изпрати
                    </Button>
                </form>
            </div>
        </Container>
    );
}
