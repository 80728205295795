import Card from '@material-ui/core/Card';
import { blue, green, red } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import WebVersionsService from '../../services/WebVersionsService';
import VersionsLoadingSkeleton from './VersionsLoadingSkeleton';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: '24px 32px',
    },
    badge: {
        display: 'inline-flex',
        fontSize: 13,
        color: '#FFF',
        letterSpacing: '.015em',
        lineHeight: 1,
        padding: '5px 8px',
        borderRadius: 2,
        '&.newChanges': {
            backgroundColor: green[500],
        },
        '&.fixedChanges': {
            backgroundColor: blue[500],
        },
        '&.breakingChanges': {
            backgroundColor: red[500],
        },
    },
    title: {
        flexGrow: 1,
    },
}));

function HomeVersionCard(props) {
    const classes = useStyles();

    const [isLoading, setIsLoading] = useState(true);
    const [changelogData, setChangelogData] = useState([]);

    useEffect(() => {
        retrieveVersions();
    }, []);

    const retrieveVersions = () => {
        WebVersionsService.getAllWithChanges()
            .then((response) => {
                setChangelogData(response.data);
                console.log(response.data);
                setIsLoading(false);
            })
            .catch((e) => {
                console.log(e);
            });
    };

    return (
        <Card className={clsx(classes.root, props.className)} elevation={1}>
            {isLoading ? (
                <VersionsLoadingSkeleton />
            ) : (
                changelogData.map((item) => (
                    <div key={item.version_id}>
                        <div className="flex items-center">
                            {item.version_number && (
                                <Typography
                                    component="h6"
                                    variant="h6"
                                    color="inherit"
                                    className={classes.title}
                                >
                                    {`v${item.version_number}`}
                                </Typography>
                            )}
                            {item.release_date && (
                                <Typography
                                    className={classes.title}
                                    component="h3"
                                >
                                    ({item.release_date})
                                </Typography>
                            )}
                        </div>
                        <br />
                        {item.changes &&
                            Object.keys(item.changes).map((category) => (
                                <div key={category}>
                                    <div
                                        className={clsx(
                                            classes.badge,
                                            category
                                        )}
                                    >
                                        {category === 'newChanges'
                                            ? 'Ново'
                                            : 'Коригирани функционалности'}
                                    </div>
                                    <ul className="my-16 px-24">
                                        {item.changes[category].map(
                                            (change, index) => (
                                                <li
                                                    key={index}
                                                    className="mb-6"
                                                >
                                                    <Typography>
                                                        {change}
                                                    </Typography>
                                                </li>
                                            )
                                        )}
                                    </ul>
                                </div>
                            ))}
                        {item.notes}
                    </div>
                ))
            )}
        </Card>
    );
}

HomeVersionCard.defaultProps = {
    version: null,
    date: null,
    newChanges: [],
    fixedChanges: [],
    breakingChanges: [],
    notes: null,
};
export default HomeVersionCard;
