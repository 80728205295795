import React, { useEffect, useState } from 'react';
import {
    Button,
    FormControl,
    FormControlLabel,
    Grid,
    InputLabel,
    MenuItem,
    Paper,
    Select,
    Switch,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
} from '@material-ui/core';
import SystemMessagesService from '../../../services/SystemMessagesService';
import { setSystemMessages } from '../../../reducers/systemMessagesActions';
import { connect } from 'react-redux';

const SystemMessageControl = ({
    systemMessages,
    dispatchSetSystemMessages,
}) => {
    useEffect(() => {
        retrieveSystemMessages();
    }, []);

    const retrieveSystemMessages = () => {
        SystemMessagesService.getAll()
            .then((response) => {
                dispatchSetSystemMessages(response.data);
            })
            .catch((e) => {
                console.log(e);
            });
    };

    const [message, setMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('');
    const [isMessageActive, setIsMessageActive] = useState(false);

    const severityOptions = {
        success: 'Success',
        warning: 'Warning',
        error: 'Error',
        info: 'Info',
    };

    /**
     * Submits data to the backend and creates a new message.
     *
     * @function handleSubmit
     * @returns {void}
     */
    const handleSubmit = () => {
        // Logic to submit data to the backend
        console.log({ message, snackbarSeverity, isMessageActive });

        const newMessage = {
            message,
            backgroundColor: snackbarSeverity,
            active: isMessageActive,
        };

        SystemMessagesService.create(newMessage)
            .then((response) => {
                // Handle successful creation
                console.log('Message created:', response.data);
                // Optionally, refresh the list of messages or update the state
                retrieveSystemMessages();
            })
            .catch((error) => {
                // Handle errors
                console.error('Error creating message:', error);
            });
    };

    /**
     * Toggles the active status of a system message based on the provided id.
     * This function updates the local state to immediately reflect the change in the UI, and also updates the status in the backend.
     *
     * @param {number} id - The id of the system message to toggle.
     *
     * @returns {void}
     */
    const toggleActiveStatus = (id) => {
        // First, update the local state to immediately reflect the change in the UI
        setSystemMessages((prevMessages) =>
            prevMessages.map((msg) => ({
                ...msg,
                active: msg.id === id ? !msg.active : false, // Only the clicked message is active
            }))
        );

        // Now, update the status in the backend
        // Assuming 'updateMessageStatus' is your service method for backend update
        systemMessages.forEach((msg) => {
            if (msg.id === id || msg.active) {
                const newStatus = msg.id === id ? !msg.active : false;
                SystemMessagesService.updateMessageStatus(msg.id, {
                    active: newStatus,
                })
                    .then(() => {
                        // After successful backend update, dispatch action to update Redux state
                        // Fetch updated messages and dispatch
                        SystemMessagesService.getAll()
                            .then((response) => {
                                dispatchSetSystemMessages(response.data);
                            })
                            .catch((error) => {
                                console.error(error);
                            });
                    })
                    .catch((e) => {
                        console.log(e);
                        // Optionally revert state change if update fails
                    });
            }
        });
    };

    return (
        <Grid
            container
            spacing={2}
            style={{
                height: '100%',
                padding: '2%',
            }}
        >
            <Grid item xs={12} md={6}>
                <TextField
                    fullWidth
                    label="Message"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                />
            </Grid>
            <Grid item xs={12} md={3}>
                <FormControl fullWidth>
                    <InputLabel>Severity</InputLabel>
                    <Select
                        value={snackbarSeverity}
                        onChange={(e) => setSnackbarSeverity(e.target.value)}
                    >
                        {Object.entries(severityOptions).map(
                            ([value, label]) => (
                                <MenuItem key={value} value={value}>
                                    {label}
                                </MenuItem>
                            )
                        )}
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={12}>
                <FormControlLabel
                    control={
                        <Switch
                            checked={isMessageActive}
                            onChange={(e) =>
                                setIsMessageActive(e.target.checked)
                            }
                        />
                    }
                    label="Display Message"
                />
            </Grid>
            <Grid item xs={12}>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSubmit}
                >
                    Добави
                </Button>
            </Grid>
            <Grid item xs={12}>
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Message</TableCell>
                                <TableCell align="right">Severity</TableCell>
                                <TableCell align="right">Active</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {systemMessages.map((row) => (
                                <TableRow key={row.id}>
                                    <TableCell component="th" scope="row">
                                        {row.message}
                                    </TableCell>
                                    <TableCell
                                        align="right"
                                        style={{
                                            backgroundColor:
                                                row.backgroundColor,
                                        }}
                                    >
                                        {row.backgroundColor}
                                    </TableCell>

                                    <TableCell align="right">
                                        <Switch
                                            checked={row.active}
                                            onChange={() =>
                                                toggleActiveStatus(row.id)
                                            }
                                        />
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
        </Grid>
    );
};

const mapStateToProps = (state) => ({
    systemMessages: state.systemMessages.systemMessages,
});

const mapDispatchToProps = (dispatch) => ({
    dispatchSetSystemMessages: (messages) =>
        dispatch(setSystemMessages(messages)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SystemMessageControl);
