import React, { useEffect, useState } from 'react';
import MaterialTable from 'material-table';
import UserDataService from '../../../services/UserService';

const UsersTable = () => {
    const [users, setUser] = useState([]);
    const [usersPin, setUserPin] = useState([]);

    useEffect(() => {
        retrieveUsers();
    }, []);

    const [loc] = React.useState(
        JSON.parse(localStorage.getItem('loc')).id || ''
    );

    const retrieveUsers = async () => {
        const locData = JSON.parse(localStorage.getItem('loc'));

        try {
            let response;

            if (Array.isArray(locData)) {
                const joinedValues = locData.join(',');
                response = await UserDataService.getAllFired(joinedValues);
            } else if (locData.id === 'all') {
                response = await UserDataService.adminAllFired();
            }

            if (response) {
                setUser(response.data);
                console.log(response.data);
            }
        } catch (e) {
            console.log(e);
        }
    };

    //for error handling
    const [iserror, setIserror] = useState(false);
    const [errorMessages, setErrorMessages] = useState([]);

    const FIRED_DATE_FORMAT = 'DD/MMM/YYYY';

    return (
        <div>
            <MaterialTable
                title="Изключени служители"
                columns={[
                    // { title: 'Id', field: 'id' },
                    { title: 'Име', field: 'name', defaultSort: 'asc' },
                    {
                        title: 'Локация',
                        field: 'loc_name',
                        editable: 'never',
                    },
                    {
                        title: 'Отдел',
                        field: 'sub_loc_name',
                        editable: 'never',
                    },
                    { title: 'No.', field: 'sap' },
                ]}
                data={users}
                style={{
                    borderRadius: '0',
                    boxShadow: 'none',
                }}
                actions={[
                    {
                        icon: 'refresh',
                        tooltip: 'Refresh',
                        isFreeAction: true,
                        onClick: retrieveUsers,
                    },
                ]}
                options={{
                    loadingType: 'linear',
                    pageSize: 50,
                    maxBodyHeight: '68vh',
                    showTitle: true,
                    sorting: true,
                    actionsColumnIndex: -1,
                    exportButton: { csv: true, pdf: false },
                    filtering: true,
                    pageSizeOptions: [5, 10, 20, 50, 100],
                }}
                localization={{
                    pagination: {
                        labelDisplayedRows: '{from}-{to} of {count}',
                    },
                    header: {
                        actions: 'Действия',
                    },
                    body: {
                        emptyDataSourceMessage: 'Няма изключени служители.',
                        filterRow: {
                            filterTooltip: 'Filter',
                        },
                        editRow: {
                            deleteText:
                                'Сигурни ли сте че искате да изтриете служителя?',
                        },
                    },
                }}
            />
        </div>
    );
};

export default UsersTable;
