import http from '../http-common';

const getAll = () => {
    return http.get('/v1/adminLocations');
};

const get = (id) => {
    return http.get(`/v1/adminLocations/${id}`);
};

const create = (data) => {
    return http.post('/v1/adminLocations', data);
};

const update = (id, data) => {
    return http.put(`/v1/adminLocations/${id}`, data);
};

const remove = (id, locId) => {
    return http.delete(`/v1/adminLocations/${id}/${locId}`);
};

const removeAll = () => {
    return http.delete(`/v1/adminLocations`);
};

export default {
    getAll,
    get,
    create,
    update,
    remove,
    removeAll,
};
