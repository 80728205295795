import React, { useEffect, useState } from 'react';
import MaterialTable, { MTableFilterRow } from 'material-table';
import EventDataService from '../../../../services/EventService';

const WorkingNowTable = () => {
    const [events, setEvent] = useState([]);

    const [loc] = React.useState(() => {
        const locData = localStorage.getItem('loc');
        return locData ? JSON.parse(locData).id : '';
    });

    useEffect(() => {
        retrieveEvents();
    }, []);

    const retrieveEvents = async () => {
        const locData = JSON.parse(localStorage.getItem('loc'));

        try {
            let response;

            if (Array.isArray(locData)) {
                const joinedValues = locData.join(',');
                response = await EventDataService.getAllNow(joinedValues);
            } else if (locData.id === 'all') {
                response = await EventDataService.adminAllNow();
            }

            if (response) {
                setEvent(response.data);
                console.log(response.data);
            }
        } catch (e) {
            console.log(e);
        }
    };

    const [open, setOpen] = React.useState(false);
    const handleClickOpen = () => {
        setOpen(true);
    };

    const SPACED_DATE_FORMAT = 'DD/MMM/YYYY H:MM:ss';

    // * Add Filters to State Start
    const [selectedRows, setSelectedRows] = useState([]);

    const [components] = useState({
        FilterRow: (props) => <MTableFilterRow {...props} />,
    });

    const [columns] = useState([
        // {
        //     title: 'Магазин',
        //     field: 'location',
        //     editable: 'never',
        //     defaultSort: 'asc',
        // },
        { title: '# на Служител', field: 'sap', editable: 'never' },
        {
            title: 'Име',
            field: 'name',
            editable: 'never',
            defaultSort: 'asc',
        },
        { title: 'Локация', field: 'loc_name', editable: 'never' },
        { title: 'Отдел', field: 'sub_loc_name', editable: 'never' },
    ]);
    // * Add Filters to State End

    return (
        <div>
            <MaterialTable
                columns={columns}
                data={events}
                style={{
                    borderRadius: '0',
                    boxShadow: 'none',
                }}
                actions={[
                    {
                        icon: 'refresh',
                        tooltip: 'Refresh',
                        isFreeAction: true,
                        onClick: retrieveEvents,
                    },
                ]}
                options={{
                    loadingType: 'linear',
                    maxBodyHeight: '40vh',
                    pageSize: 100,
                    showTitle: true,
                    sorting: true,
                    actionsColumnIndex: -1,
                    exportButton: { csv: true, pdf: false },
                    filtering: true,
                    pageSizeOptions: [20, 50, 100, 200, 300, 400, 500],
                    toolbar: false,
                }}
                components={components}
                onSelectionChange={(rows) => {
                    setSelectedRows(rows);
                }}
                localization={{
                    pagination: {
                        labelDisplayedRows: '{from}-{to} от {count}',
                        labelRowsSelect: 'Събития',
                    },
                    header: {
                        actions: 'Действия',
                    },
                    body: {
                        emptyDataSourceMessage: 'Зареждаме събитията...',
                        editTooltip: 'Редактирай',
                        deleteTooltip: 'Премахни',
                        filterRow: {
                            filterTooltip: 'Филтър',
                        },
                        editRow: {
                            deleteText:
                                'Сигурни ли сте че искате да изтриете събитието?',
                            cancelTooltip: 'Отказ',
                            saveTooltip: 'Запази',
                        },
                    },
                    toolbar: {
                        exportTitle: 'Експорт',
                        exportName: 'Експорт към CSV',
                        searchTooltip: 'Tърси',
                        searchPlaceholder: 'Tърси',
                        nRowsSelected: '{0} реда избрани',
                    },
                }}
            />
        </div>
    );
};

export default WorkingNowTable;
