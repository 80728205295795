import React, { createContext, useContext, useEffect, useState } from 'react';
import jwt from 'jsonwebtoken';

const crypto = require('crypto-browserify');

const PermissionsContext = createContext();

export const usePermissions = () => {
    return useContext(PermissionsContext);
};

export const PermissionsProvider = ({ children }) => {
    const initialPermissions = {
        c: false,
        r: false,
        u: false,
        d: false,
        super: false,
    };

    const [permissions, setPermissions] = useState(initialPermissions);
    const [loading, setLoading] = useState(true);

    function decrypt(text, key) {
        const decipher = crypto.createDecipheriv(
            'aes-256-cbc',
            Buffer.from(key),
            '1234567890123456'
        ); // Replace with your own IV
        let decrypted = decipher.update(Buffer.from(text, 'hex'));
        decrypted = Buffer.concat([decrypted, decipher.final()]);
        return decrypted.toString();
    }

    const handleLogout = (logoutCallback) => {
        // Remove the JWT from localStorage
        localStorage.removeItem('access');
        // Reset permissions to their initial state
        setPermissions(initialPermissions);

        // Invoke the provided logout callback if available
        if (typeof logoutCallback === 'function') {
            logoutCallback();
        }
    };

    useEffect(() => {
        const checkPermissions = () => {
            // Retrieve the JWT from localStorage
            const storedJWT = localStorage.getItem('access');
            // console.log('Stored JWT:', storedJWT); // Log the stored JWT

            // Check if a JWT is available
            if (storedJWT) {
                const secretOrPublicKey = '8cwkz2&mzC#MJg6wzr29fU@SvcHHhYKd'; // Replace with your actual encryption key

                // Define the expected audience value
                const expectedIssuer = 'BIODIT AD';

                const decodedToken = jwt.decode(storedJWT);
                // console.log('Decoded JWT:', decodedToken); // Log the decoded JWT

                try {
                    // Decrypt the encrypted data
                    const decryptedPayload = decrypt(
                        decodedToken.data,
                        secretOrPublicKey
                    );

                    let parsedPayload;
                    try {
                        parsedPayload = JSON.parse(decryptedPayload);
                        // console.log('Parsed Payload:', parsedPayload); // Log the parsed payload
                    } catch (e) {
                        console.error('Error parsing decrypted payload:', e);
                        return;
                    }

                    if (decodedToken.iss === expectedIssuer) {
                        // Update the permissions state with the user's permissions
                        if (parsedPayload && parsedPayload) {
                            setPermissions(parsedPayload);
                            setLoading(false);
                        }
                    } else {
                        console.error('JWT issuer mismatch');
                    }
                } catch (err) {
                    console.error('JWT verification error:', err.message);
                }
            }
        };

        // Run the initial checkPermissions
        checkPermissions();

        // Set up a recurring checkPermissions every 5 seconds (5000 milliseconds)
        const intervalId = setInterval(checkPermissions, 900000);

        // Clear the interval when the component unmounts
        return () => clearInterval(intervalId);
    }, []);

    return (
        <PermissionsContext.Provider
            value={{ permissions, loading, handleLogout }}
        >
            {children}
        </PermissionsContext.Provider>
    );
};
