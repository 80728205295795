import http from '../http-common';

const adminAll = () => {
    return http.get(`/v1/users_in_device`);
};

const getAll = (loc) => {
    return http.get(`/v1/users_in_device/loc/${loc}`);
};

const get = (id) => {
    return http.get(`/v1/users_in_device/${id}`);
};

const create = (data) => {
    return http.post('/v1/users_in_device', data);
};

const update = (id, data) => {
    return http.put(`/v1/users_in_device/${id}`, data);
};

const remove = (id) => {
    return http.delete(`/v1/users_in_device/${id}`);
};

const removeFromDevice = (id, device_id) => {
    return http.delete(`/v1/users_in_device/${id}/in/${device_id}`);
};

const removeAll = () => {
    return http.delete(`/v1/users_in_device`);
};

export default {
    adminAll,
    getAll,
    get,
    create,
    update,
    remove,
    removeFromDevice,
    removeAll,
};
