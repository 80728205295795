import http from '../http-common';

const getAll = () => {
    return http.get(`/v1/system-messages/`);
};

const getLatest = () => {
    return http.get(`/v1/system-messages/latest`);
};

const get = (id) => {
    return http.get(`/v1/system-messages/${id}`);
};

const create = (data) => {
    return http.post('/v1/system-messages', data);
};

const update = (id, data) => {
    return http.put(`/v1/system-messages/${id}`, data);
};

const updateMessageStatus = (id, data) => {
    return http.put(`/v1/system-messages/${id}/status`, data);
};

const remove = (id) => {
    return http.delete(`/v1/system-messages/${id}`);
};

const removeAll = () => {
    return http.delete(`/v1/system-messages`);
};

export default {
    getAll,
    getLatest,
    get,
    create,
    update,
    updateMessageStatus,
    remove,
    removeAll,
};
