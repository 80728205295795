import { usePermissions } from './PermissionsContext';

const useCheckPermission = (requiredPermission) => {
    const { permissions, loading } = usePermissions();

    // If permissions are not available or still loading, return null
    if (loading || !permissions) {
        return false;
    }

    // Return the check for the required permission
    return !!permissions[requiredPermission];
};

export default useCheckPermission;
