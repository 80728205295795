import React, { useEffect, useState } from 'react';
import DeviceDataService from '../../../services/DeviceService';
import DeviceUsersService from '../../../services/DeviceUsersService';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Attendance from './tables/Attendance';
import { CircularProgress } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';

const useTabStyles = makeStyles({
    root: {
        justifyContent: 'center',
    },
    scroller: {
        flexGrow: '0',
    },
});

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        width: '100%',
        backgroundColor: theme.palette.background.paper,
    },
    'MuiBox-root-120': {
        padding: 0,
    },
}));

const DeviceUsersTable = () => {
    const [loc] = React.useState(() => {
        const locData = localStorage.getItem('loc');
        return locData ? JSON.parse(locData).id : '';
    });

    const [devices, setDevice] = useState([]);

    useEffect(() => {
        retrieveDevices();
    }, []);

    const retrieveDevices = async () => {
        const locData = JSON.parse(localStorage.getItem('loc'));

        try {
            let response;

            if (Array.isArray(locData)) {
                const joinedValues = locData.join(',');
                response = await DeviceDataService.getAllNoEnroll(joinedValues);
            } else if (locData.id === 'all') {
                response = await DeviceDataService.adminAllNoEnroll();
            }

            if (response) {
                setDevice(response.data);
                console.log(response.data);
            }
        } catch (e) {
            console.log(e);
        }
    };

    //for error handling
    const [iserror, setIserror] = useState(false);
    const [errorMessages, setErrorMessages] = useState([]);

    const classes = useStyles();
    const tabClasses = useTabStyles();

    // * Tabs Values
    const [value, setValue] = React.useState(0);

    const handleTabChange = (event, newValue) => {
        setValue(newValue);
    };

    function a11yProps(index) {
        return {
            id: `scrollable-force-tab-${index}`,
            'aria-controls': `scrollable-force-tabpanel-${index}`,
        };
    }

    function TabPanel({ value, index, devices }) {
        const [deviceUsers, setDeviceUsers] = useState([]);
        const [isLoading, setIsLoading] = useState(true);
        const [isError, setIsError] = useState(false);

        const retrieveDeviceUsers = async () => {
            setIsLoading(true);
            setIsError(false);
            const locData = JSON.parse(localStorage.getItem('loc'));

            try {
                let response;

                if (Array.isArray(locData)) {
                    const joinedValues = locData.join(',');
                    response = await DeviceUsersService.getAll(joinedValues);
                } else if (locData.id === 'all') {
                    response = await DeviceUsersService.adminAll();
                }

                if (response) {
                    setDeviceUsers(response.data);
                    console.log(response.data);
                }
            } catch (e) {
                setIsError(true);
                console.log(e);
            } finally {
                setIsLoading(false);
            }
        };

        useEffect(() => {
            retrieveDeviceUsers();
        }, [devices, index]);

        const tab = devices[index];

        return (
            <div role="tabpanel" hidden={value !== index}>
                {value === index && (
                    <div>
                        {isLoading ? (
                            // Render the CircularProgress component while loading
                            <CircularProgress />
                        ) : isError ? (
                            // Render the Alert component for the error message
                            <Alert severity="error">
                                <AlertTitle>Error</AlertTitle>
                                Cannot load devices
                            </Alert>
                        ) : (
                            // Render the Attendance component with the fetched data
                            <Attendance
                                deviceUsers={deviceUsers}
                                tabName={tab.name}
                                deviceMac={tab.mac}
                                deviceName={tab.name}
                                deviceId={tab.id}
                                retrieveDeviceUsers={retrieveDeviceUsers}
                            />
                        )}
                    </div>
                )}
            </div>
        );
    }

    return (
        <div className={classes.root}>
            <AppBar position="static" color="default">
                <Tabs
                    classes={{
                        root: tabClasses.root,
                        scroller: tabClasses.scroller,
                    }}
                    value={value}
                    onChange={handleTabChange}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="scrollable"
                    scrollButtons="auto"
                    centered
                >
                    {devices.map((tab) => (
                        <Tab
                            label={tab.name}
                            {...a11yProps(tab.value)}
                            key={tab.value}
                        />
                    ))}
                </Tabs>
            </AppBar>
            {devices.map((tab, index) => (
                <TabPanel
                    key={index}
                    value={value}
                    index={index}
                    devices={devices}
                />
            ))}
        </div>
    );
};

export default DeviceUsersTable;
