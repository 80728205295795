import React, { Component } from 'react';
import axios from 'axios';
import { PermissionsProvider } from './utils/permissions/PermissionsContext';
import { Route, Router, Switch } from 'react-router-dom';
import jwt_decode from 'jwt-decode';
import setAuthToken from './utils/setAuthToken';
import { createBrowserHistory } from 'history';
import Cookies from 'js-cookie';
import { logoutUser, setCurrentUser } from './actions/authActions';
import { Provider } from 'react-redux';
import store from './store';
import Register from './components/auth/Register';
import Login from './components/auth/Login';
import Events from './components/events/Events';
import RawEvents from './components/events-raw/RawEvents';
import Discrepancies from './components/events/discrepancies/Discrepancies';
import EnterExit from './components/events/EnterExit/EnterExit';
import Working from './components/events/working/Working';
import Form76 from './components/events/form76/Form76';
import Users from './components/users/Users';
import FiredUsers from './components/users/fired-users/FiredUsers';
import PrivateRoute from './components/private-route/PrivateRoute';
import Home from './components/home/Home';
import Devices from './components/devices/Devices';
import Company from './components/company/Company';
import NotFound from './components/notfound/NotFound';
import FingersManagement from './components/devices/fingers-management/FingersManagement';
import PasswordReset from './components/profile/PasswordReset';
import Support from './components/support/Support';
import Admin from './components/admin/Admin';
import Box from '@material-ui/core/Box';
import WorkingHours from './components/events/WorkingHours/WorkingHours';
import Changes from './components/events/changes/Changes';
import { API_URL } from './env.json';
import Login2FA from './components/auth/Login2FA';

// Check for token to keep user logged in
if (Cookies.get('Biodit')) {
    // Set auth token to header
    const token = Cookies.get('Biodit');
    setAuthToken(token);
    // Decode token and get user info and exp
    const decoded = jwt_decode(token);
    // Set user and isAuthenticated
    store.dispatch(setCurrentUser(decoded));

    const checkTokenExpiration = () => {
        // Check for expired token
        const currentTime = Date.now() / 1000; // to get in milliseconds
        if (decoded.exp < currentTime) {
            // Logout user
            store.dispatch(logoutUser());
            // Redirect to login
            window.location.href = '/login';
        }
    };

    // Check token expiration every 30 seconds
    setInterval(checkTokenExpiration, 30000);
}

const history = createBrowserHistory();

// Define your hostname check
const use2FAForHostname = 'billa.biodit.bg';

// Custom component or function to decide which component to render
const LoginRoute = ({
    component: Component,
    component2FA: Component2FA,
    ...rest
}) => (
    <Route
        {...rest}
        render={(props) =>
            window.location.hostname === use2FAForHostname ? (
                <Component2FA {...props} />
            ) : (
                <Component {...props} />
            )
        }
    />
);

class App extends Component {
    intervalID = null;

    componentDidMount() {
        this.checkVersion();
        // Run the checkVersion function every 1 minute (60000 milliseconds)
        this.intervalID = setInterval(this.checkVersion, 60000);
    }

    componentWillUnmount() {
        // Clear the interval when the component unmounts
        clearInterval(this.intervalID);
    }

    checkVersion = () => {
        // Retrieve the stored version from localStorage
        const storedVersion = localStorage.getItem('x-version');

        // Replace with your actual API endpoint
        axios
            .get(`${API_URL}/ping`)
            .then((response) => {
                const currentVersion = response.headers['x-version'];
                console.log('Received x-version:', currentVersion);

                // Save the current version to localStorage
                localStorage.setItem('x-version', currentVersion);

                if (storedVersion && storedVersion !== currentVersion) {
                    // Trigger a hard page refresh if the version has changed
                    alert(
                        'Съществува нова версия на софтуера, след натискане на бутона ОК страницата ще бъде презаредена!'
                    );

                    // Clear all cookies for the domain
                    var cookies = document.cookie.split(';');

                    for (var i = 0; i < cookies.length; i++) {
                        var cookie = cookies[i];
                        var eqPos = cookie.indexOf('=');
                        var name =
                            eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
                        document.cookie =
                            name +
                            '=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/';
                    }

                    // Clear localStorage
                    localStorage.clear();

                    // Force a hard page refresh
                    window.location.reload(true);
                }
            })
            .catch((error) => {
                console.error('Error fetching version:', error);
            });
    };

    render() {
        return (
            <React.Fragment>
                <Box>
                    <Provider store={store}>
                        <Router history={history}>
                            <div>
                                <LoginRoute
                                    exact
                                    path="/"
                                    component={Login}
                                    component2FA={Login2FA}
                                />
                                <Route
                                    exact
                                    path="/register"
                                    component={Register}
                                />
                                <LoginRoute
                                    exact
                                    path="/login"
                                    component={Login}
                                    component2FA={Login2FA}
                                />
                                <Route exact path="/404" component={NotFound} />
                                <PermissionsProvider>
                                    <Switch>
                                        <PrivateRoute
                                            exact
                                            path="/home"
                                            component={Home}
                                        />
                                        <PrivateRoute
                                            exact
                                            path="/working"
                                            component={Working}
                                        />
                                        <PrivateRoute
                                            exact
                                            path="/discrepancies"
                                            component={Discrepancies}
                                        />
                                        <PrivateRoute
                                            exact
                                            path="/enter-exit"
                                            component={EnterExit}
                                        />
                                        <PrivateRoute
                                            exact
                                            path="/working-hours"
                                            component={WorkingHours}
                                        />
                                        <PrivateRoute
                                            exact
                                            path="/form-76"
                                            component={Form76}
                                        />
                                        <PrivateRoute
                                            exact
                                            path="/events"
                                            component={Events}
                                        />
                                        <PrivateRoute
                                            exact
                                            path="/changes"
                                            component={Changes}
                                        />
                                        <PrivateRoute
                                            exact
                                            path="/raw/events"
                                            component={RawEvents}
                                        />
                                        <PrivateRoute
                                            exact
                                            path="/users"
                                            component={Users}
                                        />
                                        <PrivateRoute
                                            exact
                                            path="/dismissed/users"
                                            component={FiredUsers}
                                        />
                                        <PrivateRoute
                                            exact
                                            path="/company"
                                            component={Company}
                                        />
                                        <PrivateRoute
                                            exact
                                            path="/devices"
                                            component={Devices}
                                        />
                                        <PrivateRoute
                                            exact
                                            path="/allocation"
                                            component={FingersManagement}
                                        />
                                        <PrivateRoute
                                            exact
                                            path="/password-reset"
                                            component={PasswordReset}
                                        />
                                        <PrivateRoute
                                            exact
                                            path="/support"
                                            component={Support}
                                        />
                                        <PrivateRoute
                                            exact
                                            path="/admin-settings"
                                            component={Admin}
                                        />
                                    </Switch>
                                </PermissionsProvider>
                            </div>
                        </Router>
                    </Provider>
                </Box>
            </React.Fragment>
        );
    }
}

export default App;
