import React from 'react';
import clsx from 'clsx';
import { Card, Container, CssBaseline, Grid } from '@material-ui/core';
import AppBarComponent from '../layout/AppBar';
import Scrollbar from 'react-scrollbars-custom';
import DevicesTable from './DevicesTable';
import { useStyles } from '../styles/useStyles';
import DeviceUsersTable from './fingers-management/DeviceUsersTable';

export default function Devices(props) {
    const [loc] = React.useState(() => {
        const locData = localStorage.getItem('loc');
        return locData ? JSON.parse(locData).id : '';
    });
    // * Sidebar SubMenu Function
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <CssBaseline />
            <AppBarComponent />
            <main className={classes.content}>
                <Scrollbar
                    id="RSC-Example"
                    style={{ width: '100%', height: '100%' }}
                >
                    <div className={classes.appBarSpacer} />
                    <Container maxWidth="lg" className={classes.container}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} lg={6}>
                                <Card className={clsx(classes.card)}>
                                    <DeviceUsersTable
                                        className={classes.devicesTable}
                                    />
                                </Card>
                            </Grid>
                            <Grid item xs={12} lg={6}>
                                <Card className={clsx(classes.card)}>
                                    <DevicesTable
                                        className={classes.devicesTable}
                                    />
                                </Card>
                            </Grid>
                        </Grid>
                    </Container>
                </Scrollbar>
            </main>
        </div>
    );
}
