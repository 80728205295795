import http from '../http-common';

const adminAllOnline = () => {
    return http.get(`/v1/devices/online`);
};

const adminAllNoEnroll = () => {
    return http.get(`/v1/devices/no/enroll`);
};

const adminAll = () => {
    return http.get(`/v1/devices`);
};

const getAll = (loc) => {
    return http.get(`/v1/devices/loc/${loc}`);
};

const getAllNoEnroll = (loc) => {
    return http.get(`/v1/devices/no/enroll/loc/${loc}`);
};

const getAllEnrollers = (loc) => {
    return http.get(`/v1/devices/enrollers/loc/${loc}`);
};

const adminAllEnrollers = (loc) => {
    return http.get(`/v1/devices/enrollers`);
};

const adminAllWriters = () => {
    return http.get(`/v1/devices/writers`);
};

const getAllWriters = (loc) => {
    return http.get(`/v1/devices/writers/loc/${loc}`);
};

const adminAllRooms = () => {
    return http.get(`/v1/devices/rooms`);
};

const getAllRooms = (loc) => {
    return http.get(`/v1/devices/rooms/loc/${loc}`);
};

const get = (id) => {
    return http.get(`/v1/devices/id/${id}`);
};

const create = (data) => {
    return http.post('/v1/devices', data);
};

const update = (mac, data) => {
    return http.put(`/v1/devices/${mac}`, data);
};

const remove = (id) => {
    return http.delete(`/v1/devices/${id}`);
};

const removeAll = () => {
    return http.delete(`/v1/devices`);
};

const findByIpAddress = (ip_address) => {
    return http.get(`/v1/devices?ip_address=${ip_address}`);
};

const findAllOnline = (loc) => {
    return http.get(`/v1/devices/online/loc/${loc}`);
};

export default {
    getAll,
    getAllEnrollers,
    getAllWriters,
    getAllRooms,
    get,
    create,
    update,
    remove,
    removeAll,
    findByIpAddress,
    findAllOnline,
    adminAllOnline,
    adminAllEnrollers,
    adminAll,
    adminAllNoEnroll,
    getAllNoEnroll,
    adminAllWriters,
    adminAllRooms,
};
