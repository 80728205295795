import http from '../http-common';

const getAll = () => {
    return http.get(`/v1/web-changes/`);
};

const get = (id) => {
    return http.get(`/v1/web-changes/${id}`);
};

const create = (data) => {
    return http.post('/v1/web-changes', data);
};

const update = (id, data) => {
    return http.put(`/v1/web-changes/${id}`, data);
};

const remove = (id) => {
    return http.delete(`/v1/web-changes/${id}`);
};

const removeAll = () => {
    return http.delete(`/v1/web-changes`);
};

export default {
    getAll,
    get,
    create,
    update,
    remove,
    removeAll,
};
