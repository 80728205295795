import React, { useEffect, useState } from 'react';
import MaterialTable, {
    MTableBody,
    MTableFilterRow,
    MTableToolbar,
} from 'material-table';
import axios from 'axios';
import Alert from '@material-ui/lab/Alert';
import { API_URL } from '../../../env.json';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Paper } from '@material-ui/core';
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx/dist/xlsx.full.min.js';
import DescriptionIcon from '@material-ui/icons/Description';

var _ = require('lodash');

const useStyles = makeStyles((theme) => ({
    button: {
        marginLeft: theme.spacing(1),
        marginBottom: theme.spacing(1),
        marginTop: theme.spacing(2),
    },
    grid: { marginLeft: theme.spacing(2) },
    toolbar: { marginLeft: theme.spacing(10) },
    absoluteContainer: {
        position: 'absolute',
        zIndex: 9,
        width: '45vw',
        [theme.breakpoints.down('md')]: {
            position: 'initial',
        },
    },
}));

const EnterExitTable = () => {
    const classes = useStyles();

    const [loc] = React.useState(() => {
        const locData = localStorage.getItem('loc');
        return locData ? JSON.parse(locData).id : '';
    });

    // ! Gap Filter Start
    const [workPlan, setWorkPlan] = useState([]);
    const [separatedWorkPlans, setSeparatedWorkPlans] = useState([]);
    const [filteredDay, setFilteredDay] = useState([]);
    const [dataFetched, setDataFetched] = useState(false);
    const [mergedData, setMergedData] = useState([]);
    const [open, setOpen] = useState(false);

    useEffect(() => {
        setOpen(false);
        // retrieveWorkPlan()
        // retrieveFilteredDay()
        // setTimeout(() => {
        // 	// mergeArrays()
        // 	separateWorkPlans()
        // }, 3000)
    }, []);

    const client = axios.create({
        baseURL: API_URL,
    });

    const startLoadingOverlay = () => {
        setDataFetched(false);
        setOpen(true);
    };

    var x = new Date();
    const [selectedStartDate, setStartDateChange] = useState(null);
    const [selectedEndDate, setEndDateChange] = useState(null);

    const handleEndDateChange = (date) => {
        setEndDateChange(date);
    };

    const handleStartDateChange = (date) => {
        setStartDateChange(date);
    };

    const [dates, setDates] = useState();

    const retrieveFilteredDay = async () => {
        setFilteredDay([]);

        const getDaysArray = (start, end) => {
            const dates = [];
            let currentDate = new Date(start);
            while (currentDate <= new Date(end)) {
                dates.push(new Date(currentDate));
                currentDate.setDate(currentDate.getDate() + 1);
            }
            return dates;
        };

        const daylist = getDaysArray(selectedStartDate, selectedEndDate);
        setDates(daylist);

        const requests = daylist.map((d) => {
            return client.get(
                `/enter-exit/date/${JSON.stringify(d)
                    .split('T')[0]
                    .replace(/\"/g, '')}`
            );
        });

        try {
            const responses = await Promise.all(requests);
            const allData = responses.flatMap((response) => response.data);
            setFilteredDay(allData);
            setDataFetched(true);
            setOpen(false);
        } catch (error) {
            setIserror(error.response);
            setErrorMessages(
                <Alert severity="error">Не бяха намарени отклонения!</Alert>
            );
            console.log(error.response);
        }
    };

    // ! Gap Filter End

    //for error handling
    const [iserror, setIserror] = useState(false);
    const [errorMessages, setErrorMessages] = useState([]);

    // * Add Filters to State Start
    const [selectedRows, setSelectedRows] = useState([]);

    // const [components] = useState({
    // 	Toolbar = props => <MTableToolbar {...props} />,
    // })
    const SPACED_DATE_FORMAT = 'DD/MMM/YYYY H:MM:ss';
    // * Add Filters to State End

    // * Get Today's date
    const todayDate = new Date();
    // * Get today's date end

    const [components] = useState({
        Toolbar: (props) => (
            <div>
                <MTableToolbar {...props} />
            </div>
        ),
        FilterRow: (props) => <MTableFilterRow {...props} />,
    });

    // ! Define table columns for export
    const columns = [
        {
            title: 'UserId',
            field: 'user_id',
            editable: 'never',
        },
        {
            title: 'Име',
            field: 'user_name',
            editable: 'never',
        },
        {
            title: 'Първи вход',
            field: 'first_time',
            editable: 'never',
            type: 'datetime',
            dateSetting: { locale: 'bg-BG', hour12: false },
            cellStyle: (cellValue) => {
                return cellValue === '<<' ? { color: '#ff4d4d' } : {};
            },
        },
        {
            title: 'Локация на вход',
            field: 'entry_location',
            editable: 'never',
        },
        {
            title: 'Устройство на вход',
            field: 'enter_device',
            editable: 'never',
        },
        {
            title: 'Последен изход',
            field: 'last_time',
            editable: 'never',
            type: 'datetime',
            dateSetting: { locale: 'bg-BG', hour12: false },
            cellStyle: (cellValue) => {
                return cellValue === '>>' ? { backgroundColor: '#ff4d4d' } : {};
            },
        },
        {
            title: 'Локация на изход',
            field: 'exit_location',
            editable: 'never',
        },
        {
            title: 'Устройство на изход',
            field: 'exit_device',
            editable: 'never',
        },
        {
            title: 'Длъжност',
            field: 'department',
            editable: 'never',
        },
    ];

    // ! Format the Date and time columns for export
    const formatDate = (inputTime) => {
        if (inputTime && inputTime !== 'null' && inputTime !== 'empty') {
            const date = new Date(inputTime);
            return `${date.getDate().toString().padStart(2, '0')}.${(
                date.getMonth() + 1
            )
                .toString()
                .padStart(2, '0')}.${date.getFullYear()} г., ${date
                .getHours()
                .toString()
                .padStart(2, '0')}:${date
                .getMinutes()
                .toString()
                .padStart(2, '0')}:${date
                .getSeconds()
                .toString()
                .padStart(2, '0')} ч.`;
        }
        return 'empty';
    };

    // ! Header mapping for exported column titles
    const headerMapping = {};
    columns.forEach((col) => {
        headerMapping[col.field] = col.title;
    });

    const mapHeaders = (row) => {
        let newRow = {};
        for (let key in row) {
            if (headerMapping[key]) {
                newRow[headerMapping[key]] = row[key];
            } else {
                newRow[key] = row[key];
            }
        }
        return newRow;
    };

    // ! Format the name for the file
    const formatDateForFilename = (inputDate) => {
        const date = new Date(inputDate);
        return `${date.getFullYear()}-${(date.getMonth() + 1)
            .toString()
            .padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`;
    };

    const formattedStartDate = formatDateForFilename(selectedStartDate);
    const formattedEndDate = formatDateForFilename(selectedEndDate);

    // ! Export to XLS Function
    const tableRef = React.createRef();
    const exportTable = () => {
        const currentTableData = tableRef.current.dataManager.filteredData;

        // Transform the data
        const transformedData = currentTableData.map((row) => {
            // Check for << and >> conditions for first_time and last_time
            const firstTimeValue =
                row.first_time === '<<'
                    ? 'липсва вход'
                    : formatDate(row.first_time);
            const lastTimeValue =
                row.last_time === '>>'
                    ? 'липсва изход'
                    : formatDate(row.last_time);

            return {
                ...row,
                time: formatDate(row.time),
                first_time: firstTimeValue,
                last_time: lastTimeValue,
            };
        });

        // Only include displayed columns in the export
        const exportedData = transformedData.map((row) => {
            let newRow = {};
            columns.forEach((col) => {
                newRow[col.field] = row[col.field];
            });
            return mapHeaders(newRow);
        });

        // Export the data
        const ws = XLSX.utils.json_to_sheet(exportedData);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

        const buf = XLSX.write(wb, { bookType: 'xls', type: 'array' });
        saveAs(
            new Blob([buf], { type: 'application/vnd.ms-excel' }),
            `Enter/Exit_${formattedStartDate} - ${formattedEndDate}.xls`
        );
    };

    return (
        <div>
            <Paper>
                <Grid
                    container
                    spacing={1}
                    className={classes.absoluteContainer}
                >
                    <Grid item xs={2} className={classes.grid}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <DatePicker
                                margin="normal"
                                id="selectedStartDate"
                                label="Начална Дата"
                                name="selectedStartDate"
                                inputVariant="outlined"
                                autoOk={true}
                                value={selectedStartDate}
                                onChange={(date) => handleStartDateChange(date)}
                                size="small"
                                views={['year', 'month', 'date']}
                                format="yyyy-MM-dd"
                            />
                        </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item xs={2} className={classes.grid}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <DatePicker
                                margin="normal"
                                id="selectedEndDate"
                                label="Крайна Дата"
                                name="selectedEndDate"
                                inputVariant="outlined"
                                autoOk={true}
                                value={selectedEndDate}
                                onChange={(date) => handleEndDateChange(date)}
                                size="small"
                                views={['year', 'month', 'date']}
                                format="yyyy-MM-dd"
                            />
                        </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item xs={2}>
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            className={classes.button}
                            onClick={retrieveFilteredDay}
                        >
                            Филтрирай
                        </Button>
                    </Grid>
                </Grid>
                <MaterialTable
                    title="Справка Отклонения"
                    columns={columns}
                    data={filteredDay}
                    tableRef={tableRef}
                    style={{
                        borderRadius: '0',
                        boxShadow: 'none',
                    }}
                    actions={[
                        {
                            icon: () => <DescriptionIcon />,
                            tooltip: 'Export to Excel',
                            isFreeAction: true,
                            onClick: exportTable,
                        },
                    ]}
                    components={{
                        components,
                        Body: (props) =>
                            !dataFetched ? (
                                <div
                                    style={{
                                        width: '100%',
                                        height: '100%',
                                        margin: '15% auto',
                                        position: 'absolute',
                                        textAlign: 'center',
                                    }}
                                >
                                    "Изберете период за да видите Вход/Изход!"
                                </div>
                            ) : (
                                <MTableBody {...props} />
                            ),
                    }}
                    options={{
                        minBodyHeight: '67vh',
                        maxBodyHeight: '67vh',
                        loadingType: 'linear',
                        pageSize: 100,
                        showTitle: false,
                        sorting: true,
                        actionsColumnIndex: -1,
                        exportButton: { csv: true, pdf: false },
                        filtering: true,
                        pageSizeOptions: [100, 200, 300, 400, 500],
                        search: true,
                        toolbar: true,
                    }}
                    // editable={{
                    // 	onRowUpdate: oldEvent =>
                    // 		new Promise(resolve => {
                    // 			handleEventUpdate(oldEvent, resolve)
                    // 			retrieveEvents()
                    // 		}),
                    // 	onRowDelete: Event =>
                    // 		new Promise(resolve => {
                    // 			handleEventRemove(Event, resolve)
                    // 			retrieveEvents()
                    // 		}),
                    // }}
                    onSelectionChange={(rows) => {
                        setSelectedRows(rows);
                    }}
                    localization={{
                        pagination: {
                            labelDisplayedRows: '{from}-{to} от {count}',
                            labelRowsSelect: 'Събития',
                        },
                        header: {
                            actions: 'Действия',
                        },
                        body: {
                            emptyDataSourceMessage:
                                'Изберете период за да видите Вход/Изход!',
                            editTooltip: 'Редактирай',
                            deleteTooltip: 'Премахни',
                            filterRow: {
                                filterTooltip: 'Филтър',
                            },
                            editRow: {
                                deleteText:
                                    'Сигурни ли сте че искате да изтриете събитието?',
                                cancelTooltip: 'Отказ',
                                saveTooltip: 'Запази',
                            },
                        },
                        toolbar: {
                            exportTitle: 'Експорт',
                            exportName: 'Експорт към CSV',
                            searchTooltip: 'Tърси',
                            searchPlaceholder: 'Tърси',
                            nRowsSelected: '{0} реда избрани',
                        },
                    }}
                />
            </Paper>
        </div>
    );
};

export default EnterExitTable;
