import { red } from "@material-ui/core/colors"
import { createMuiTheme, createStyles } from "@material-ui/core"

// A custom theme for this app
const theme = createMuiTheme({
    palette: {
        primary: {
            main: "#003d4d",
        },
        secondary: {
            main: "#8adc39",
        },
        error: {
            main: red.A400,
        },
        background: {
            default: "#e6e6e6 !important",
        },
        icon: {
            default: "#fff",
        },
        badge: {
            main: "#ec0c8e",
        },
    },
    overrides: {
        MuiCollapse: createStyles({
            entered: {
                "&&": {
                    opacity: 9,
                    transition: "all 200ms ease-in-out",
                },
            },
        }),
    },
})

export default theme
