import React from "react"
import {makeStyles, useTheme} from "@material-ui/core/styles"
import {CssBaseline, Typography, Container, Grid} from "@material-ui/core"
import AppBarComponent from "../layout/AppBar"
import SupportForm from "./SupportForm"
import {useStyles} from "../styles/useStyles"

function Support(props) {
	const classes = useStyles()
	const theme = useTheme()

	// * Start Drwawer Menu
	const {window} = props
	const [mobileOpen, setMobileOpen] = React.useState(false)

	const handleDrawerToggle = () => {
		setMobileOpen(!mobileOpen)
	}

	// * End Drawer Menu

	const [open, setOpen] = React.useState(true)
	const handleClick = () => {
		setOpen(!open)
	}

	const container =
		window !== undefined ? () => window().document.body : undefined

	return (
		<div className={classes.root}>
			<CssBaseline />
			<AppBarComponent />
			<main className={classes.content}>
				<Container className={classes.smallContainer}>
					<Grid container spacing={2}>
						<Grid item xs={12}>
							<Typography
								component='h1'
								variant='h4'
								color='inherit'
								align='center'>
								Поддръжка
							</Typography>
							<Typography
								component='p'
								variant='body'
								color='inherit'
								align='center'>
								Изпратете запитване или съобщете за проблем
							</Typography>
							<SupportForm />
						</Grid>
					</Grid>
				</Container>
			</main>
		</div>
	)
}

export default Support
