import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { CssBaseline } from '@material-ui/core';
import AdminDataService from '../../services/Admins';

require('dotenv').config();

const appBarHeight = 64;
const mobileAppBarHeight = 56;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    appBarSpacer: theme.mixins.toolbar,
    blueBar: {
        backgroundColor: '#003d4d',
        alignContent: 'center',
        verticalAlign: 'middle',
        marginTop: appBarHeight,
        flex: 'auto',
        textAlign: 'center',
        fontSize: '1.4rem',
        fontWeight: '400',
        color: 'white',
        height: 'auto',
        padding: '1%',
        [theme.breakpoints.down('md')]: {
            marginTop: mobileAppBarHeight,
        },
    },
}));

function BlueBar() {
    const classes = useStyles();

    const [adminId] = React.useState(() => {
        const locData = localStorage.getItem('session');
        return locData ? JSON.parse(locData).session : '';
    });

    const [admin, setAdmin] = useState([]);

    useEffect(() => {
        retrieveSystemUsers();
    }, []);

    //for error handling
    const [iserror, setIserror] = useState(false);
    const [errorMessages, setErrorMessages] = useState([]);

    const retrieveSystemUsers = () => {
        AdminDataService.get(adminId)
            .then((response) => {
                setAdmin(response.data);
            })
            .catch((e) => {
                setErrorMessages(['Cannot load user data']);
                setIserror(true);
            });
    };

    return (
        <div className={classes.root}>
            <CssBaseline />
            <div className={classes.appBarSpacer} />
            <div className={classes.blueBar}>
                <span className={classes.blueBarTitle}>
                    Здравейте {admin.name}, Добре дошли в BIODIT TIME MANAGEMENT
                    SYSTEM
                </span>
            </div>
        </div>
    );
}

export default BlueBar;

// export default connect(
//     mapStateToProps,
//     { logoutUser }
// )(Dashboard)
