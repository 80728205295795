import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import SystemUsers from './SystemUsers/SystemUsers';
import SystemRoles from './SystemUsers/SystemRoles';
import SystemMessages from './SystemUsers/SystemMessages';
import useCheckPermission from '../../utils/permissions/useCheckPermissions';
import { PermissionsProvider } from '../../utils/permissions/PermissionsContext';
import SystemVersions from './SystemUsers/SystemVersions';
import SystemSettings from './SystemUsers/SystemSettings';

const useTabStyles = makeStyles({
    root: {
        justifyContent: 'center',
    },
    scroller: {
        flexGrow: '0',
    },
});

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        width: '100%',
        backgroundColor: theme.palette.background.paper,
    },
    'MuiBox-root-120': {
        padding: 0,
    },
}));

const GodfatherTable = () => {
    const isSuper = useCheckPermission('super');

    const classes = useStyles();
    const tabClasses = useTabStyles();

    // * Tabs Values
    const [value, setValue] = useState(0);

    const handleTabChange = (event, newValue) => {
        setValue(newValue);
    };

    function TabPanel(props) {
        const { children, value, index, ...other } = props;

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`scrollable-force-tabpanel-${index}`}
                aria-labelledby={`scrollable-force-tab-${index}`}
                {...other}
            >
                {value === index && (
                    <Box p={0}>
                        <Typography>{children}</Typography>
                    </Box>
                )}
            </div>
        );
    }

    TabPanel.propTypes = {
        children: PropTypes.node,
        index: PropTypes.any.isRequired,
        value: PropTypes.any.isRequired,
    };

    function a11yProps(index) {
        return {
            id: `scrollable-force-tab-${index}`,
            'aria-controls': `scrollable-force-tabpanel-${index}`,
        };
    }

    if (isSuper === undefined) {
        return null; // or a loading spinner
    }

    return (
        <PermissionsProvider>
            <div className={classes.root}>
                <AppBar position="static" color="default">
                    <Tabs
                        classes={{
                            root: tabClasses.root,
                            scroller: tabClasses.scroller,
                        }}
                        value={value}
                        onChange={handleTabChange}
                        indicatorColor="primary"
                        textColor="primary"
                        variant="scrollable"
                        scrollButtons="auto"
                        centered
                    >
                        <Tab label="Потребители" {...a11yProps(0)} />
                        <Tab label="Видове Права" {...a11yProps(1)} />
                        {isSuper && (
                            <Tab label="Системни Съобщения" {...a11yProps(2)} />
                        )}
                        {isSuper && (
                            <Tab label="Versions Control" {...a11yProps(3)} />
                        )}
                        <Tab
                            label="Системни Настройки"
                            {...a11yProps(isSuper ? 4 : 2)}
                        />
                    </Tabs>
                </AppBar>

                <TabPanel value={value} index={0} padding={0}>
                    <SystemUsers />
                </TabPanel>
                <TabPanel value={value} index={1} padding={10}>
                    <SystemRoles />
                </TabPanel>
                {isSuper && (
                    <TabPanel value={value} index={2} padding={10}>
                        <SystemMessages />
                    </TabPanel>
                )}
                {isSuper && (
                    <TabPanel value={value} index={3} padding={10}>
                        <SystemVersions />
                    </TabPanel>
                )}
                <TabPanel value={value} index={isSuper ? 4 : 2} padding={10}>
                    <SystemSettings />
                </TabPanel>
            </div>
        </PermissionsProvider>
    );
};

export default GodfatherTable;
