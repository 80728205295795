import React, { useEffect, useState } from 'react';
import DeviceDataService from '../../services/DeviceService';
import Badge from '@material-ui/core/Badge';
import MaterialTable from 'material-table';
import { API_URL } from '../../env.json';
import axios from 'axios';
import { Snackbar } from '@mui/material';
import Alert from '@material-ui/lab/Alert';

const DevicesTable = () => {
    const [loc] = React.useState(() => {
        const locData = localStorage.getItem('loc');
        return locData ? JSON.parse(locData).id : '';
    });

    const client = axios.create({
        baseURL: API_URL,
    });

    const [devices, setDevice] = useState([]);

    useEffect(() => {
        // Call the function on component mount
        retrieveDevices();

        // Set up an interval to call the function every 60 seconds (60000 milliseconds)
        const interval = setInterval(retrieveDevices, 60000);

        // Clear the interval on component unmount
        return () => clearInterval(interval);
    }, []);

    const retrieveDevices = async () => {
        const locData = JSON.parse(localStorage.getItem('loc'));

        try {
            let response;

            if (Array.isArray(locData)) {
                const joinedValues = locData.join(',');
                response = await DeviceDataService.getAll(joinedValues);
            } else if (locData.id === 'all') {
                response = await DeviceDataService.adminAll();
            }

            if (response) {
                setDevice(response.data);
                console.log(response.data);
            }
        } catch (e) {
            console.log(e);
        }
    };

    // Snackbars Start
    const [snackbars, setSnackbars] = useState([]);

    const handleCloseSnackbar = (id) => {
        setSnackbars((prevSnackbars) =>
            prevSnackbars.filter((snackbar) => snackbar.id !== id)
        );
    };

    const handleShowSnackbar = (message, severity) => {
        const id = new Date().getTime(); // Unique ID for each snackbar
        const newSnackbar = {
            id,
            message,
            severity,
            position: snackbars.length * 55,
        };

        setSnackbars((prevSnackbars) => [...prevSnackbars, newSnackbar]);
    };
    //Snackbars End

    // * Functionality for restarting devices START
    const restartDevice = async (rowData) => {
        const { name, mac } = rowData;

        try {
            const res = await client.post('/restartDevice', { mac });

            if (res.status === 200) {
                handleShowSnackbar(
                    `Успешно изпратихте команда за рестартиране към устройството!`,
                    'success'
                );
            }
        } catch (err) {
            handleShowSnackbar(
                `Командата не беше изпратена, моля опитайте отново!`,
                'error'
            );
        }
    };
    // * Functionality for restarting devices END

    //for error handling
    const [iserror, setIserror] = useState(false);
    const [errorMessages, setErrorMessages] = useState([]);

    const columns = [
        {
            title: 'Status',
            field: 'online',
            cellStyle: {
                minWidth: 20,
                maxWidth: 50,
            },
            render: (rowData) => (
                <Badge
                    color={rowData.online ? 'secondary' : 'error'}
                    variant="dot"
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                    style={{ left: '0.5vw' }}
                />
            ),
        },
        {
            title: 'Устройство',
            field: 'name',
        },
        // {
        // 	title: "IP адрес",
        // 	field: "ip_address",
        // },
        // {
        //     title: 'Вид устройство',
        //     field: 'type',
        // },
        {
            title: 'Локация',
            field: 'DeviceLocation.name',
        },
        // {
        //     title: 'MAC',
        //     field: 'mac',
        // },
    ];
    return (
        <div>
            {snackbars
                .slice()
                .reverse()
                .map((snackbar, index) => (
                    <Snackbar
                        key={snackbar.id}
                        open={true}
                        autoHideDuration={5000}
                        onClose={() => handleCloseSnackbar(snackbar.id)}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                        }}
                        style={{ bottom: `${10 + index * 55}px` }}
                    >
                        <Alert
                            elevation={6}
                            variant="filled"
                            severity={snackbar.severity}
                            onClose={() => handleCloseSnackbar(snackbar.id)}
                        >
                            {snackbar.message}
                        </Alert>
                    </Snackbar>
                ))}
            <MaterialTable
                title="Устройства"
                data={devices}
                columns={columns}
                style={{
                    borderRadius: '0',
                    boxShadow: 'none',
                }}
                actions={[
                    {
                        icon: 'refresh',
                        tooltip: 'Опресни',
                        isFreeAction: true,
                        onClick: retrieveDevices,
                    },
                    {
                        icon: 'cached',
                        tooltip: 'Рестарт',
                        onClick: (event, rowData) => restartDevice(rowData),
                    },
                ]}
                options={{
                    loadingType: 'linear',
                    pageSize: 100,
                    maxBodyHeight: '65vh',
                    showTitle: true,
                    sorting: true,
                    actionsColumnIndex: -1,
                    exportButton: { csv: true, pdf: false },
                    filtering: true,
                    pageSizeOptions: [20, 50, 100, 500, 1000],
                    whiteSpace: 'nowrap',
                    rowStyle: {
                        overflowWrap: 'break-word',
                    },
                    search: false,
                }}
                editable={
                    {
                        // onRowUpdate: (newUserData) =>
                        // 	new Promise((resolve) => {
                        // 		handleUserUpdate(newUserData, resolve);
                        // 		retrieveDevices();
                        // 	}),
                        // onRowDelete: (removeUser) =>
                        // 	new Promise((resolve) => {
                        // 		handleUserRemove(removeUser, resolve);
                        // 		retrieveDevices();
                        // 	}),
                    }
                }
                localization={{
                    pagination: {
                        labelDisplayedRows: '{from}-{to} of {count}',
                    },
                    header: {
                        actions: 'Действия',
                    },
                    body: {
                        emptyDataSourceMessage: 'Loading data...',
                        filterRow: {
                            filterTooltip: 'Filter',
                        },
                        editRow: {
                            deleteText:
                                'Сигурни ли сте че искате да изтриете устройството?',
                        },
                    },
                }}
            />
        </div>
    );
};

export default DevicesTable;
