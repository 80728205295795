import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { loginUser } from '../../actions/authActions';
import classnames from 'classnames';
import {
    Box,
    Button,
    Card,
    CardContent,
    Container,
    CssBaseline,
    FormControl,
    Grid,
    TextField,
} from '@material-ui/core';
import logo from '../../logo-dark.png';
import { makeStyles } from '@material-ui/core/styles';
import Copyright from '../layout/Copyright';

const useStyles = makeStyles((theme) => ({
    paper: {
        borderRadius: '8px',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.primary.main,
    },
    logo_icon: {
        '& .logo-icon': {
            width: '170px',
            margin: theme.spacing(2.5, 6),
            transition: theme.transitions.create(['width'], {
                duration: theme.transitions.duration.shortest,
                easing: theme.transitions.easing.easeInOut,
            }),
        },
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    appBarSpacer: theme.mixins.toolbar,
    textField: {
        margin: theme.spacing(1),
        width: '50vh',
    },
}));

class Login extends React.Component {
    constructor() {
        super();
        this.state = {
            nikname: '',
            password: '',
            errors: {},
        };
    }

    componentDidMount() {
        // If logged in and user navigates to Login page, should redirect them to dashboard
        if (this.props.auth.isAuthenticated) {
            this.props.history.push('/home');
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.auth.isAuthenticated) {
            this.props.history.push('/home');
        }

        if (nextProps.errors) {
            this.setState({
                errors: nextProps.errors,
            });
        }
    }

    onChange = (e) => {
        this.setState({ [e.target.id]: e.target.value });
    };

    onSubmit = (e) => {
        e.preventDefault();

        const userData = {
            nikname: this.state.nikname,
            password: this.state.password,
        };

        this.props.loginUser(userData);
    };

    render() {
        const { appBarSpacer, paper, logo_icon, form, submit, textField } =
            this.props;
        const { errors } = this.state;

        return (
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <Grid
                    item
                    md={12}
                    style={{ alignItems: 'center', paddingTop: '25vh' }}
                >
                    <Card className={paper} style={{ borderRadius: '5px' }}>
                        <CardContent>
                            <div className={logo_icon}>
                                <img
                                    className="logo-icon"
                                    src={logo}
                                    alt="logo"
                                    style={{ width: '200px', margin: '5% 22%' }}
                                />
                            </div>
                            <form
                                className={form}
                                noValidate
                                onSubmit={this.onSubmit}
                            >
                                <FormControl style={{ width: '100%' }}>
                                    <TextField
                                        variant="outlined"
                                        margin="normal"
                                        required
                                        fullWidth
                                        id="nikname"
                                        label="Потребителско име"
                                        name="nikname"
                                        onChange={this.onChange}
                                        value={this.state.nikname}
                                        error={
                                            errors.nikname ||
                                            errors.niknamenotfound ||
                                            errors.inactiveprofile
                                        }
                                        style={{
                                            marginTop: '8px',
                                            marginBottom: '6px',
                                            width: '100%',
                                        }}
                                        className={classnames('', {
                                            invalid:
                                                errors.nikname ||
                                                errors.niknamenotfound ||
                                                errors.inactiveprofile,
                                        })}
                                        autoComplete="nikname"
                                        autoFocus
                                    />
                                    <span
                                        className="red-text"
                                        style={{ color: 'red' }}
                                    >
                                        {errors.nikname}
                                        {errors.niknamenotfound}
                                        {errors.inactiveprofile}
                                    </span>
                                    <TextField
                                        variant="outlined"
                                        margin="normal"
                                        required
                                        fullWidth
                                        name="password"
                                        label="Парола"
                                        type="password"
                                        id="password"
                                        onChange={this.onChange}
                                        value={this.state.password}
                                        error={
                                            (errors.password,
                                            errors.passwordincorrect)
                                        }
                                        style={{
                                            marginTop: '8px',
                                            marginBottom: '10px',
                                            width: '100%',
                                        }}
                                        className={classnames('', {
                                            invalid:
                                                errors.password ||
                                                errors.passwordincorrect,
                                        })}
                                        autoComplete="current-password"
                                    />
                                </FormControl>

                                <span
                                    className="red-text"
                                    style={{ color: 'red' }}
                                >
                                    {errors.password}
                                    {errors.passwordincorrect}
                                </span>
                                {/* <FormControlLabel
                            control={<Checkbox value="remember" color="primary" />}
                            label="Remember me"
                        /> */}
                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    className={submit}
                                >
                                    Влизане
                                </Button>
                            </form>
                        </CardContent>
                    </Card>
                </Grid>
                <Box mt={3}>
                    <Copyright />
                </Box>
            </Container>
        );
    }
}

Login.propTypes = {
    loginUser: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    auth: state.auth,
    errors: state.errors,
});

export default connect(mapStateToProps, { loginUser })(Login);
