import React, { useEffect, useState } from 'react';
import {
    Button,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    TextField,
} from '@material-ui/core';
import WebVersionsService from '../../../services/WebVersionsService';
import { connect } from 'react-redux';
import clsx from 'clsx';
import VersionsLoadingSkeleton from '../../home/VersionsLoadingSkeleton';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { blue, green, red } from '@material-ui/core/colors';
import WebChangesService from '../../../services/WebChangesService';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: '24px 32px',
    },
    badge: {
        display: 'inline-flex',
        fontSize: 13,
        color: '#FFF',
        letterSpacing: '.015em',
        lineHeight: 1,
        padding: '5px 8px',
        borderRadius: 2,
        '&.newChanges': {
            backgroundColor: green[500],
        },
        '&.fixedChanges': {
            backgroundColor: blue[500],
        },
        '&.breakingChanges': {
            backgroundColor: red[500],
        },
    },
    title: {
        flexGrow: 1,
    },
}));

const SystemVersionsControl = (props) => {
    const classes = useStyles();

    const [isLoading, setIsLoading] = useState(true);
    const [changelogData, setChangelogData] = useState([]);

    useEffect(() => {
        retrieveVersionsWithChanges();
        retrieveVersions();
    }, []);

    const retrieveVersionsWithChanges = () => {
        WebVersionsService.getAllWithChanges()
            .then((response) => {
                setChangelogData(response.data);
                console.log(response.data);
                setIsLoading(false);
            })
            .catch((e) => {
                console.log(e);
            });
    };

    const [versions, setVersions] = useState([]);
    const retrieveVersions = () => {
        WebVersionsService.getAll()
            .then((response) => {
                setVersions(response.data);
                console.log(response.data);
                setIsLoading(false);
            })
            .catch((e) => {
                console.log(e);
            });
    };

    // Version Data
    const [version_number, setVersionNumber] = useState([]);
    const [release_date, setReleaseDate] = useState([]);

    const [changesMessage, setChangesMessage] = useState([]);
    const [changesVersion, setChangesVersion] = useState([]);
    const [changesType, setChangesType] = useState([]);

    const [snackbarSeverity, setSnackbarSeverity] = useState('');
    const [isMessageActive, setIsMessageActive] = useState(false);

    const severityOptions = {
        newChanges: 'newChanges',
        breakingChanges: 'breakingChanges',
    };

    /**
     * Submits data to the backend and creates a new message.
     *
     * @function handleSubmit
     * @returns {void}
     */
    // const handleSubmit = () => {
    //     // Logic to submit data to the backend
    //     console.log({ message, snackbarSeverity, isMessageActive });
    //
    //     const newMessage = {
    //         message,
    //         backgroundColor: snackbarSeverity,
    //         active: isMessageActive,
    //     };
    //
    //     SystemMessagesService.create(newMessage)
    //         .then((response) => {
    //             // Handle successful creation
    //             console.log('Message created:', response.data);
    //             // Optionally, refresh the list of messages or update the state
    //             retrieveSystemMessages();
    //         })
    //         .catch((error) => {
    //             // Handle errors
    //             console.error('Error creating message:', error);
    //         });
    // };

    const createVersion = () => {
        const newVersion = {
            version_number,
            release_date,
        };

        WebVersionsService.create(newVersion)
            .then((response) => {
                retrieveVersions();
            })
            .catch((error) => {
                console.error('Error creating version:', error);
            });
    };

    const createChange = () => {
        const newChange = {
            description: changesMessage,
            version_id: changesVersion,
            category: changesType,
        };

        WebChangesService.create(newChange)
            .then((response) => {
                retrieveVersionsWithChanges();
            })
            .catch((error) => {
                console.error('Error creating change:', error);
            });
    };

    return (
        <Grid
            container
            spacing={2}
            style={{
                height: '100%',
                padding: '2%',
            }}
        >
            <Grid item xs={12} md={6}>
                <TextField
                    fullWidth
                    label="Версия"
                    value={version_number}
                    onChange={(e) => setVersionNumber(e.target.value)}
                />
                <TextField
                    fullWidth
                    label="Дата"
                    value={release_date}
                    onChange={(e) => setReleaseDate(e.target.value)}
                />
            </Grid>
            <Grid item xs={12}>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={createVersion}
                >
                    Добави Версия
                </Button>
            </Grid>
            <Grid item xs={12} md={6}>
                <TextField
                    fullWidth
                    label="Message"
                    value={changesMessage}
                    onChange={(e) => setChangesMessage(e.target.value)}
                />
            </Grid>
            <Grid item xs={12} md={3}>
                <FormControl fullWidth>
                    <InputLabel>Версия</InputLabel>
                    <Select
                        value={changesVersion}
                        onChange={(e) => setChangesVersion(e.target.value)}
                    >
                        {versions.map((version) => (
                            <MenuItem
                                key={version.version_id}
                                value={version.version_id}
                            >
                                {version.version_number}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={12} md={3}>
                <FormControl fullWidth>
                    <InputLabel>Тип</InputLabel>
                    <Select
                        value={changesType}
                        onChange={(e) => setChangesType(e.target.value)}
                    >
                        {Object.entries(severityOptions).map(
                            ([value, label]) => (
                                <MenuItem key={value} value={value}>
                                    {label}
                                </MenuItem>
                            )
                        )}
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={12}>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={createChange}
                >
                    Добави Промяна
                </Button>
            </Grid>

            <Grid item xs={12} md={12}>
                <hr />
                {isLoading ? (
                    <VersionsLoadingSkeleton />
                ) : (
                    changelogData.map((item) => (
                        <div key={item.version_id}>
                            <div className="flex items-center">
                                {item.version_number && (
                                    <Typography
                                        component="h6"
                                        variant="h6"
                                        color="inherit"
                                        className={classes.title}
                                    >
                                        {`v${item.version_number}`}
                                    </Typography>
                                )}
                                {item.release_date && (
                                    <Typography
                                        className={classes.title}
                                        component="h3"
                                    >
                                        ({item.release_date})
                                    </Typography>
                                )}
                            </div>
                            <br />
                            {item.changes &&
                                Object.keys(item.changes).map((category) => (
                                    <div key={category}>
                                        <div
                                            className={clsx(
                                                classes.badge,
                                                category
                                            )}
                                        >
                                            {category === 'newChanges'
                                                ? 'Ново'
                                                : 'Коригирани функционалности'}
                                        </div>
                                        <ul className="my-16 px-24">
                                            {item.changes[category].map(
                                                (change, index) => (
                                                    <li
                                                        key={index}
                                                        className="mb-6"
                                                    >
                                                        <Typography>
                                                            {change}
                                                        </Typography>
                                                    </li>
                                                )
                                            )}
                                        </ul>
                                    </div>
                                ))}
                            {item.notes}
                        </div>
                    ))
                )}
            </Grid>
        </Grid>
    );
};

const mapStateToProps = (state) => ({
    systemMessages: state.systemMessages.systemMessages,
});

export default connect(mapStateToProps)(SystemVersionsControl);
