import http from "../http-common"

const getAll = () => {
	return http.get(`/v1/logs/`)
}

const getAllDateFilter = (startDate, endDate) => {
	return http.get(`/v1/logs/start/${startDate}/end/${endDate}`)
}

const get = id => {
	return http.get(`/v1/logs/${id}`)
}

const create = data => {
	return http.post("/v1/logs", data)
}

const update = (id, data) => {
	return http.put(`/v1/logs/${id}`, data)
}

const remove = id => {
	return http.delete(`/v1/logs/${id}`)
}

const removeAll = () => {
	return http.delete(`/v1/logs`)
}

export default {
	getAll,
	getAllDateFilter,
	get,
	create,
	update,
	remove,
	removeAll,
}
