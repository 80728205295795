import React, { useEffect, useState } from 'react';
import MaterialTable, { MTableFilterRow } from 'material-table';
import UserDataService from '../../services/UserService';
import DepartmentDataService from '../../services/DepartmentService';
import LocationDataService from '../../services/LocationService';
import axios from 'axios';
import { API_URL } from '../../env.json';
import DeviceUsersService from '../../services/DeviceUsersService';
import TemplateService from '../../services/TemplateService';
import { Snackbar } from '@mui/material';
import Alert from '@material-ui/lab/Alert';
import FingerprintIcon from '@material-ui/icons/Fingerprint';
import useCheckPermission from '../../utils/permissions/useCheckPermissions';
import ModalForm from './Modal';
import DoNotTouchIcon from '@mui/icons-material/DoNotTouch';
// import EditModalForm from './EditFiles/EditModal'

const UsersTable = () => {
    // ! Permissions Check
    const isSuper = useCheckPermission('super');
    const canCreate = useCheckPermission('c');
    const canUpdate = useCheckPermission('u');
    const canDelete = useCheckPermission('d');

    const [users, setUser] = useState([]);
    const [templates, setTemplates] = useState([]);
    const [usersPin, setUserPin] = useState([]);

    useEffect(() => {
        retrieveUsers();
        retrieveDepartments();
        retrieveLocations();
    }, []);

    const [loc] = React.useState(() => {
        const locData = localStorage.getItem('loc');
        return locData ? JSON.parse(locData).id : '';
    });

    const retrieveUsers = async () => {
        const locData = JSON.parse(localStorage.getItem('loc'));

        try {
            let response;

            if (Array.isArray(locData)) {
                const joinedValues = locData.join(',');
                response = await UserDataService.getAll(joinedValues);
            } else if (locData.id === 'all') {
                response = await UserDataService.adminAll();
                client
                    .get(`/v1/templates/`)
                    .then((response) => {
                        setTemplates(response.data);
                    })
                    .catch((e) => {
                        setErrorMessages(['Cannot load user data']);
                        setIserror(true);
                    });
            }

            if (response) {
                setUser(response.data);
                console.log(response.data);
            }
        } catch (e) {
            console.log(e);
        }
    };

    //for error handling
    const [iserror, setIserror] = useState(false);
    const [errorMessages, setErrorMessages] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    // Snackbars Start
    const [snackbars, setSnackbars] = useState([]);

    const handleCloseSnackbar = (id) => {
        setSnackbars((prevSnackbars) =>
            prevSnackbars.filter((snackbar) => snackbar.id !== id)
        );
    };

    const handleShowSnackbar = (message, severity) => {
        const id = new Date().getTime(); // Unique ID for each snackbar
        const newSnackbar = {
            id,
            message,
            severity,
            position: snackbars.length * 55,
        };

        setSnackbars((prevSnackbars) => [...prevSnackbars, newSnackbar]);
    };
    //Snackbars End

    const client = axios.create({
        baseURL: API_URL,
    });

    const handleUserRemove = (removeUser, resolve) => {
        UserDataService.remove(removeUser.sap)
            .then((res) => {
                const dataRemove = [...users];
                const index = removeUser.tableData.id;
                dataRemove.splice(index, 1);
                setUser([...dataRemove]);
                resolve();
                retrieveUsers();
            })
            .catch((error) => {
                setErrorMessages(['Remove failed! Server error']);
                setIserror(true);
            });
    };

    const handleUserFingersRemove = (sap, resolve) => {
        client
            .post(`/removeUserEverywhere`, {
                user: JSON.stringify(sap),
            })
            .then((response) => {
                if (response.status === 200) {
                    DeviceUsersService.remove(sap).then((response) => {
                        if (response.status === 200) {
                            handleShowSnackbar(
                                `Успешно премахнахте служителя от всички устройства!`,
                                'success'
                            );
                        } else {
                            handleShowSnackbar(
                                `Служителя не беше премахнат от всички устройства, моля опитайте отново!`,
                                'error'
                            );
                        }
                    });
                    TemplateService.remove(sap)
                        .then((res) => {
                            resolve();
                            setIsLoading(false);
                            handleShowSnackbar(
                                `Успешно премахнахте отпечатъците на служителя от системата!`,
                                'success'
                            );
                            retrieveUsers();
                        })
                        .catch((error) => {
                            setErrorMessages(['Remove failed! Server error']);
                            setIserror(true);
                        });
                } else {
                    handleShowSnackbar(
                        `Възникна грешка. Моля, опитайте отново!`,
                        'error'
                    );
                }
            });
    };

    //! Edit User Event Start
    const [submitted, setSubmitted] = useState(false);

    // const handleUserUpdate = (newUserData, resolve) => {
    // 	if (usersPin === newUserData) {
    // 		alert("Моля напишете друг пин")
    // 	} else {
    // 		UserDataService.update(newUserData)
    // 			.then(response => {
    // 				setSubmitted(true)
    // 				console.log(response.data)
    // 				resolve()
    // 			})
    // 			.catch(e => {
    // 				console.log(e)
    // 			})
    // 	}
    // }

    const handleUserUpdate = (newUserData, resolve) => {
        UserDataService.update(newUserData.sap, newUserData)
            .then((response) => {
                setSubmitted(true);
                console.log(response.data);
                retrieveUsers();
                resolve();
            })
            .catch((e) => {
                setErrorMessages(['Cannot load user data']);
                setIserror(true);
            });
    };

    const updateUser = () => {
        setSubmitted(false);
    };
    //! Edit User Event Stop

    // * Retrieve Locations & Departments
    const [departmentsOptions, setDepartmentsOptions] = useState([]);
    const [locationOptions, setLocationOptions] = useState([]);

    const retrieveLocations = () => {
        LocationDataService.getAll()
            .then((response) => {
                return response.data.reduce((acc, item) => {
                    acc[item.name] = item.name;
                    setLocationOptions(acc);
                    return acc;
                }, {});
            })
            .catch((e) => {
                setErrorMessages(['Cannot load user data']);
                setIserror(true);
            });
    };

    const retrieveDepartments = () => {
        if (JSON.parse(localStorage.getItem('loc')).id >= 0) {
            DepartmentDataService.getAll(loc)
                .then((response) => {
                    return response.data.reduce((acc, item) => {
                        acc[item.name] = item.name;
                        setDepartmentsOptions(acc);
                        return acc;
                    }, {});
                })
                .catch((e) => {
                    setErrorMessages(['Cannot load user data']);
                    setIserror(true);
                });
        } else if (JSON.parse(localStorage.getItem('loc')).id === 'all') {
            DepartmentDataService.adminAll()
                .then((response) => {
                    return response.data.reduce((acc, item) => {
                        acc[item.name] = item.name;
                        setDepartmentsOptions(acc);
                        return acc;
                    }, {});
                })
                .catch((e) => {
                    setErrorMessages(['Cannot load user data']);
                    setIserror(true);
                });
        }
    };
    // * End of retrieving Locations & Departments

    // * Users In Device Table
    const [deviceUsers, setDeviceUsers] = useState([]);

    useEffect(() => {
        retrieveDeviceUsers();
    }, []);

    const retrieveDeviceUsers = () => {
        if (JSON.parse(localStorage.getItem('loc')).id >= 0) {
            DeviceUsersService.getAll(loc)
                .then((response) => {
                    setDeviceUsers(response.data);
                })
                .catch((e) => {
                    setErrorMessages(['Cannot load devices']);
                    setIserror(true);
                });
        } else if (JSON.parse(localStorage.getItem('loc')).id === 'all') {
            DeviceUsersService.adminAll()
                .then((response) => {
                    setDeviceUsers(response.data);
                })
                .catch((e) => {
                    setErrorMessages(['Cannot load devices']);
                    setIserror(true);
                });
        }
    };
    // * Retrieve Users In Device End

    useEffect(() => {
        const filterCells = document.querySelectorAll('.MuiTableCell-filtered');

        const handleScroll = () => {
            const scrollTop =
                window.pageYOffset || document.documentElement.scrollTop;
            const tableTop = document.querySelector('.MuiTable-root').offsetTop;

            if (scrollTop > tableTop) {
                filterCells.forEach((cell) => {
                    cell.classList.add('sticky');
                });
            } else {
                filterCells.forEach((cell) => {
                    cell.classList.remove('sticky');
                });
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const [columns] = useState([
        {
            field: 'enrolled',
            title: 'Enrolled',
            editable: false,
            render: (rowData) => {
                return (
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        <div style={{ flex: '0 0 50px' }}>
                            {rowData.enrolled === 'YES' ? (
                                <FingerprintIcon style={{ color: 'green' }} />
                            ) : (
                                <FingerprintIcon style={{ color: 'red' }} />
                            )}
                        </div>
                    </div>
                );
            },
        },
        { title: 'Id', field: 'id', hidden: true },
        { title: 'Име', field: 'name', defaultSort: 'asc', editable: false },
        {
            title: 'ЕГН',
            field: 'guest_id',
            defaultSort: 'asc',
            editable: false,
        },
        // {
        //     title: 'Магазин',
        //     field: 'loc_name',
        //     // lookup: locationOptions,
        //     cellStyle: (e, rowData) => {
        //         if (rowData.loc_name === 'null') {
        //             return { backgroundColor: '#FFCCCC' };
        //         }
        //     },
        // },
        {
            title: 'Локация',
            field: 'loc_name',
            editable: false,
            // lookup: departmentsOptions,
            cellStyle: (e, rowData) => {
                if (rowData.sub_loc_name === 'null') {
                    return { backgroundColor: '#FFCCCC' };
                }
            },
        },
        {
            title: 'Отдел',
            field: 'sub_loc_name',
            editable: false,
            // lookup: departmentsOptions,
            cellStyle: (e, rowData) => {
                if (rowData.sub_loc_name === 'null') {
                    return { backgroundColor: '#FFCCCC' };
                }
            },
        },
        // { title: 'No.', field: 'sap', editable: false },
        { title: '# Карта', field: 'card' },
        { title: '# Пин', field: 'pin' },
    ]);

    return (
        <div>
            {snackbars
                .slice()
                .reverse()
                .map((snackbar, index) => (
                    <Snackbar
                        key={snackbar.id}
                        open={true}
                        autoHideDuration={5000}
                        onClose={() => handleCloseSnackbar(snackbar.id)}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                        }}
                        style={{ bottom: `${10 + index * 55}px` }}
                    >
                        <Alert
                            elevation={6}
                            variant="filled"
                            severity={snackbar.severity}
                            onClose={() => handleCloseSnackbar(snackbar.id)}
                        >
                            {snackbar.message}
                        </Alert>
                    </Snackbar>
                ))}
            {canCreate && <ModalForm buttonLabel="Добавяне" />}
            <MaterialTable
                title="Служители "
                columns={columns}
                data={users}
                style={{
                    borderRadius: '0',
                    boxShadow: 'none',
                }}
                actions={[
                    {
                        icon: 'refresh',
                        tooltip: 'Refresh',
                        isFreeAction: true,
                        onClick: retrieveUsers,
                    },
                    {
                        icon: DoNotTouchIcon,
                        tooltip: 'Изтрий отпечатъци',
                        onClick: (event, rowData) => {
                            const confirmed = window.confirm(
                                'Сигурни ли сте че искате да изтриете отпечатъците на служителя?'
                            );
                            if (confirmed) {
                                new Promise((resolve) => {
                                    setIsLoading(true);
                                    handleShowSnackbar(
                                        `Изтриване на отпечатъци... Моля, изчакайте!`,
                                        'info'
                                    );
                                    handleUserFingersRemove(
                                        rowData.sap,
                                        resolve
                                    );
                                    retrieveUsers();
                                });
                            }
                        },
                    },
                ]}
                options={{
                    loadingType: 'linear',
                    pageSize: 100,
                    maxBodyHeight: '68vh',
                    showTitle: true,
                    sorting: true,
                    actionsColumnIndex: -1,
                    exportButton: { csv: true, pdf: false },
                    filtering: true,
                    pageSizeOptions: [20, 50, 100, 500, 1000],
                }}
                components={{
                    FilterRow: (props) => <MTableFilterRow {...props} />,
                }}
                editable={{
                    ...(canUpdate
                        ? {
                              onRowUpdate: (newUserData) =>
                                  new Promise((resolve) => {
                                      handleUserUpdate(newUserData, resolve);
                                      retrieveUsers();
                                  }),
                          }
                        : {}),

                    ...(canDelete
                        ? {
                              onRowDelete: (removeUser) =>
                                  new Promise((resolve) => {
                                      handleUserRemove(removeUser, resolve);
                                      retrieveUsers();
                                  }),
                          }
                        : {}),
                }}
                localization={{
                    pagination: {
                        labelDisplayedRows: '{from}-{to} of {count}',
                    },
                    header: {
                        actions: 'Действия',
                    },
                    body: {
                        emptyDataSourceMessage: 'Loading data...',
                        filterRow: {
                            filterTooltip: 'Filter',
                        },
                        editRow: {
                            deleteText:
                                'Сигурни ли сте че искате да изтриете служителя?',
                        },
                    },
                }}
                // components={{
                // 	EditRow: props => {
                // 		return (
                // 			<MTableEditRow
                // 				{...props}
                // 				onEditingCanceled={(mode, rowData) => {
                // 					rowData.tableData.editing = undefined
                // 				}}
                // 				onEditingApproved={(mode, newUserData, oldData) => {
                // 					const dataCopy = [...users]
                // 					const index = dataCopy.indexOf(oldData)
                // 					dataCopy[index] = newUserData
                // 					handleUserUpdate(newUserData, dataCopy)
                // 				}}
                // 			/>
                // 		)
                // 	},
                // }}
            />
        </div>
    );
};

export default UsersTable;
