import React from 'react';
import clsx from 'clsx';
import { Card, Container, CssBaseline, Grid } from '@material-ui/core';
import AppBarComponent from '../layout/AppBar';
//
import { useStyles } from '../styles/useStyles';
import AdminTabs from './AdminTabs';

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

export default function FingersManagement(props) {
    const classes = useStyles(props);
    const [loc] = React.useState(() => {
        const locData = localStorage.getItem('loc');
        return locData ? JSON.parse(locData).id : '';
    });

    return (
        <div className={classes.root}>
            <CssBaseline />
            <AppBarComponent />
            <main className={classes.content}>
                <div className={classes.appBarSpacer} />
                <Container maxWidth="lg" className={classes.container}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} lg={12}>
                            <Card className={clsx(classes.card)}>
                                <AdminTabs className={classes.devicesTable} />
                            </Card>
                        </Grid>
                    </Grid>
                </Container>
            </main>
        </div>
    );
}

// export default connect(
//     mapStateToProps,
//     { logoutUser }
// )(Dashboard)
