import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import 'date-fns';
import Alert from '@material-ui/lab/Alert';
import EventDataService from '../../services/EventService';
import DevicesDataService from '../../services/DeviceService';
import UsersDataService from '../../services/UserService';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Grid } from '@material-ui/core';
import dayjs from 'dayjs';
import {
    LocalizationProvider,
    MobileDateTimePicker,
} from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import 'dayjs/locale/bg';

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    root: (props) => ({
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: '25ch',
        },
        '& .MuiButton-root': {
            margin: theme.spacing(1),
        },
        '& .MuiAlert-root': {
            width: '100%',
            '& > * + *': {
                marginTop: theme.spacing(1),
            },
        },
    }),
}));

const AddEventForm = () => {
    const [loc] = React.useState(() => {
        const locData = localStorage.getItem('loc');
        return locData ? JSON.parse(locData).id : '';
    });

    const [devices, setDevices] = useState([]);

    useEffect(() => {
        retrieveDevices();
        // retrieveLocations();
        retrieveUsers();
    }, []);

    const retrieveDevices = async () => {
        const locData = JSON.parse(localStorage.getItem('loc'));

        try {
            let response;

            if (Array.isArray(locData)) {
                const joinedValues = locData.join(',');
                response = await DevicesDataService.getAll(joinedValues);
            } else if (locData.id === 'all') {
                response = await DevicesDataService.adminAll();
            }

            if (response) {
                setDevices(response.data);
                console.log(response.data);
            }
        } catch (e) {
            console.log(e);
        }
    };

    //for error handling
    const [iserror, setIserror] = useState(false);
    const [errorMessages, setErrorMessages] = useState([]);

    // * Retrieve Locations Start
    // const [locations, setLocations] = useState([]);
    //
    // const retrieveLocations = () => {
    //     LocationDataService.getAll(loc)
    //         .then((response) => {
    //             setLocations(response.data);
    //             console.log(response.data);
    //         })
    //         .catch((e) => {
    //             console.log(e);
    //         });
    // };
    // ! Retrieve Locations End

    // * Retrieve Users Start
    const [users, setUsers] = useState([]);

    const retrieveUsers = async () => {
        const locData = JSON.parse(localStorage.getItem('loc'));

        try {
            let response;

            if (Array.isArray(locData)) {
                const joinedValues = locData.join(',');
                response = await UsersDataService.getAll(joinedValues);
            } else if (locData.id === 'all') {
                response = await UsersDataService.adminAll();
            }

            if (response) {
                setUsers(response.data);
                console.log(response.data);
            }
        } catch (e) {
            console.log(e);
        }
    };
    // ! Retrieve Users End

    const initialEventState = {
        id: null,
        device_mac: '',
        user_id: '',
        location: '',
        time: '',
        event: '',
        genuine: false,
    };

    const [manualEvent, setEvent] = useState(initialEventState);
    const [selectedDate, handleDateChange] = useState(dayjs());
    const [submitted, setSubmitted] = useState(false);

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setEvent({ ...manualEvent, [name]: value });
    };

    // Find the selected device based on its MAC address
    const selectedDevice = devices.find(
        (device) => device.mac === manualEvent.device_mac
    );

    // Extract the location of the selected device
    // In this case, the location is a direct number in the device object
    const selectedDeviceLocation = selectedDevice
        ? selectedDevice.location
        : null;

    const saveEvent = () => {
        setIsGenerating(true);
        // Extract the JWT from the "Biodit" cookie
        const jwtFromCookie = document.cookie
            .split('; ')
            .find((row) => row.startsWith('Biodit='))
            .split('=')[1];

        // Decode the JWT to get the adminId
        const decodedJwt = JSON.parse(atob(jwtFromCookie.split('.')[1]));
        const adminId = decodedJwt.id; // Assuming the payload has an 'id' property

        var data = {
            device_mac: manualEvent.device_mac,
            user_id: selectedUser.sap,
            location: selectedDeviceLocation,
            time: selectedDate,
            event: manualEvent.event,
            genuine: manualEvent.genuine,
            adminId: adminId,
        };

        EventDataService.create(data)
            .then((response) => {
                setEvent({
                    id: response.data.id,
                    device_mac: response.data.device_mac,
                    user_id: response.data.user_id,
                    location: response.data.location,
                    time: response.data.time,
                    event: response.data.event,
                    genuine: response.data.genuine,
                });
                setIsGenerating(false);
                setSubmitted(true);
                console.log(response.data);
                setTimeout(function () {
                    window.location.reload(1);
                }, 2000);
            })
            .catch((e) => {
                console.log(e);
            });
    };

    const newEvent = () => {
        setEvent(initialEventState);
        setSubmitted(false);
    };

    const classes = useStyles();

    // ! Locations Autocomplete
    const [selectedLocation, setSelectedLocation] = useState(null);
    const [inputValue, setInputValue] = useState('');

    // ! Users Autocomplete
    const [selectedUser, setSelectedUser] = useState(null);
    const [inputUserValue, setInputUserValue] = useState('');

    // ! Define the current date
    const level = localStorage.getItem('level');
    // Set the minDate and maxDate to the start and end of the current day
    const currentDate = dayjs().startOf('day'); // Start of the current day
    const endOfDay = dayjs().endOf('day'); // End of the current day

    // ! Block users click while generating event
    const [isGenerating, setIsGenerating] = useState(false);

    return (
        <div className="submit-form">
            {submitted ? (
                // <Alert onClose={() => { }}>Успешно добавен!</Alert>
                <div>
                    <Alert>Успешно добавихте събитието!</Alert>
                    {/* <h4>You submitted successfully!</h4>
                    <button className="btn btn-success" onClick={newUser}>
                        Add
                    </button> */}
                </div>
            ) : (
                <form className={classes.root}>
                    <div>
                        <Grid container>
                            <Grid item>
                                <TextField
                                    id="outlined-select-location device_mac"
                                    select
                                    label="Устройство"
                                    name="device_mac"
                                    value={manualEvent.device_mac}
                                    onChange={handleInputChange}
                                    size="small"
                                    variant="outlined"
                                >
                                    <MenuItem></MenuItem>
                                    {devices.map((device) => (
                                        <MenuItem
                                            key={device.mac}
                                            value={device.mac}
                                        >
                                            {device.name}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                            <Grid item>
                                <Autocomplete
                                    value={selectedUser}
                                    onChange={(event, newValue) => {
                                        setSelectedUser(newValue);
                                    }}
                                    inputValue={inputUserValue}
                                    onInputChange={(event, newInputValue) => {
                                        setInputUserValue(newInputValue);
                                    }}
                                    options={users}
                                    getOptionLabel={(option) =>
                                        option.name + ' - ' + option.sap || ''
                                    }
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Служител"
                                            required
                                            variant="outlined"
                                            fullWidth
                                            id="fullWidth"
                                            size="small"
                                        />
                                    )}
                                />
                            </Grid>
                        </Grid>
                    </div>
                    <div>
                        <Grid container>
                            {/*<Grid item>*/}
                            {/*    <Autocomplete*/}
                            {/*        value={selectedLocation}*/}
                            {/*        onChange={(event, newValue) => {*/}
                            {/*            setSelectedLocation(newValue);*/}
                            {/*        }}*/}
                            {/*        inputValue={inputValue}*/}
                            {/*        onInputChange={(event, newInputValue) => {*/}
                            {/*            setInputValue(newInputValue);*/}
                            {/*        }}*/}
                            {/*        options={locations}*/}
                            {/*        getOptionLabel={(option) =>*/}
                            {/*            option.name || ''*/}
                            {/*        }*/}
                            {/*        renderInput={(params) => (*/}
                            {/*            <TextField*/}
                            {/*                {...params}*/}
                            {/*                label="Локация"*/}
                            {/*                required*/}
                            {/*                variant="outlined"*/}
                            {/*                fullWidth*/}
                            {/*                id="fullWidth"*/}
                            {/*                size="small"*/}
                            {/*            />*/}
                            {/*        )}*/}
                            {/*    />*/}
                            {/*</Grid>*/}
                            <Grid item>
                                <TextField
                                    id="outlined-select-location event"
                                    select
                                    label="Вид Събитие"
                                    name="event"
                                    value={manualEvent.event}
                                    onChange={handleInputChange}
                                    size="small"
                                    variant="outlined"
                                >
                                    <MenuItem></MenuItem>
                                    <MenuItem key="enter" value="enter">
                                        Вход
                                    </MenuItem>
                                    <MenuItem key="exit" value="exit">
                                        Изход
                                    </MenuItem>
                                </TextField>
                            </Grid>
                            <Grid item>
                                {(() => {
                                    if (
                                        level === 'super' ||
                                        level === 'admin'
                                    ) {
                                        return (
                                            <LocalizationProvider
                                                dateAdapter={AdapterDayjs}
                                                adapterLocale="bg"
                                            >
                                                <MobileDateTimePicker
                                                    margin="normal"
                                                    closeOnSelect={true}
                                                    id="ime"
                                                    label="Дата и час"
                                                    defaultValue={dayjs()}
                                                    value={selectedDate}
                                                    onChange={handleDateChange}
                                                    slotProps={{
                                                        textField: {
                                                            size: 'small',
                                                        },
                                                    }}
                                                />
                                            </LocalizationProvider>
                                        );
                                    } else {
                                        return (
                                            <LocalizationProvider
                                                dateAdapter={AdapterDayjs}
                                                adapterLocale="bg"
                                            >
                                                <MobileDateTimePicker
                                                    margin="normal"
                                                    closeOnSelect={true}
                                                    id="ime"
                                                    label="Дата и час"
                                                    defaultValue={dayjs()}
                                                    value={selectedDate}
                                                    onChange={handleDateChange}
                                                    minDate={currentDate}
                                                    maxDate={endOfDay}
                                                    slotProps={{
                                                        textField: {
                                                            size: 'small',
                                                        },
                                                    }}
                                                />
                                            </LocalizationProvider>
                                        );
                                    }
                                })()}
                            </Grid>
                        </Grid>
                    </div>
                    <div>
                        {/*<MuiPickersUtilsProvider utils={DateFnsUtils}>*/}
                        {/*    <KeyboardDateTimePicker*/}
                        {/*        id="time"*/}
                        {/*        name="time"*/}
                        {/*        variant="inline"*/}
                        {/*        ampm={false}*/}
                        {/*        label="Дата и час"*/}
                        {/*        helperText="Формат: 01/01/2021, 00:00"*/}
                        {/*        value={selectedDate}*/}
                        {/*        onChange={handleDateChange}*/}
                        {/*        onError={console.log}*/}
                        {/*        format="dd/MM/yyyy, HH:mm"*/}
                        {/*    />*/}
                        {/*</MuiPickersUtilsProvider>*/}
                    </div>
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        onClick={saveEvent}
                        disabled={isGenerating} // Disable the button when generating
                    >
                        {isGenerating ? 'Добавяме...' : 'Добави'}
                    </Button>
                </form>
            )}
        </div>
    );
};

export default AddEventForm;
