import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { loginUser, verifyUser } from '../../actions/authActions';
import {
    Box,
    Button,
    Card,
    CardContent,
    Container,
    CssBaseline,
    Grid,
    TextField,
} from '@material-ui/core';
import logo from '../../logo-dark.png';
import { makeStyles } from '@material-ui/core/styles';
import Copyright from '../layout/Copyright';
import axios from 'axios';
import API_URL from '../../env.json';

const useStyles = makeStyles((theme) => ({
    paper: {
        borderRadius: '8px',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.primary.main,
    },
    logo_icon: {
        '& .logo-icon': {
            width: '170px',
            margin: theme.spacing(2.5, 6),
            transition: theme.transitions.create(['width'], {
                duration: theme.transitions.duration.shortest,
                easing: theme.transitions.easing.easeInOut,
            }),
        },
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    appBarSpacer: theme.mixins.toolbar,
    textField: {
        margin: theme.spacing(1),
        width: '50vh',
    },
}));

function getSteps() {
    return ['Step 1', '2FA'];
}

class Login2FA extends React.Component {
    constructor() {
        super();
        this.state = {
            activeStep: 0,
            nikname: '',
            password: '',
            verify: '',
            errors: {},
            verificationErrors: {},
        };

        // Create an axios instance for use within this class
        this.client = axios.create({
            baseURL: API_URL.API_URL, // Assuming API_URL is an object with a baseUrl property
        });
    }

    componentDidMount() {
        // If logged in and user navigates to Login page, should redirect them to dashboard
        if (this.props.auth.isAuthenticated) {
            this.props.history.push('/home');
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.auth.isAuthenticated) {
            this.props.history.push('/home');
        }

        if (nextProps.errors) {
            this.setState({
                errors: nextProps.errors,
            });
        }
    }

    checkErrorsAndProceed() {
        // Fetch the administrator's phone number
        this.client
            .get(`/adminPhone/${this.state.nikname}`)
            .then((response) => {
                if (response.status === 200 && response.data.phone) {
                    // Send SMS if the phone number was successfully retrieved
                    return this.client.post('/send-sms', {
                        to: response.data.phone,
                    });
                } else {
                    // Handle case where phone number fetch is successful but no phone number is returned
                    throw new Error('Failed to fetch phone number.');
                }
            })
            .then((response) => {
                if (response.status === 200) {
                    // SMS sent successfully, proceed to the next step
                    this.setState((prevState) => ({
                        activeStep: Math.min(
                            prevState.activeStep + 1,
                            getSteps().length - 1
                        ),
                        errors: {}, // Clear any previous errors since operation was successful
                    }));
                } else {
                    // Handle unsuccessful SMS sending attempt
                    alert('SMS sending failed.');
                }
            })
            .catch((error) => {
                // General error handling for both fetching phone number and sending SMS
                alert(JSON.stringify(error.response.data));
                const errorMessage =
                    error.response && error.response.data
                        ? error.response.data
                        : error.message;
                this.setState({
                    errors: {
                        ...this.state.errors,
                        apiError: errorMessage,
                    },
                });
            });
    }

    componentDidUpdate(prevProps) {
        if (prevProps.errors !== this.props.errors) {
            this.setState(
                {
                    errors: this.props.errors || {},
                },
                () => {
                    // If there are no errors after update, proceed
                    if (Object.keys(this.state.errors).length === 0) {
                        this.checkErrorsAndProceed();
                    }
                }
            );
        }

        // Additional logic to handle authentication state changes
        if (
            prevProps.auth.isAuthenticated !==
                this.props.auth.isAuthenticated &&
            this.props.auth.isAuthenticated
        ) {
            // Redirect or perform actions after successful authentication
        }
    }

    handleNext = () => {
        const { nikname, password } = this.state;
        const userData = { nikname, password };
        this.props.verifyUser(userData); // Trigger the verification action
    };

    onChange = (e) => {
        this.setState({ [e.target.id]: e.target.value });
    };

    onSubmit = (e) => {
        e.preventDefault();

        const userData = {
            nikname: this.state.nikname,
            password: this.state.password,
        };

        this.client
            .get(`/adminPhone/${this.state.nikname}`)
            .then((response) => {
                if (response.status === 200 && response.data.phone) {
                    return this.client
                        .post('/verify-sms', {
                            to: response.data.phone,
                            code: this.state.verify,
                        })
                        .then((res) => {
                            if (res.data.status === 'approved') {
                                this.props.loginUser(userData);
                            } else {
                                alert(
                                    'Грешен код за верификация, моля опитайте отново!'
                                );
                            }
                        });
                } else {
                    // Handle case where phone number fetch is successful but no phone number is returned
                    throw new Error('Failed to fetch phone number.');
                }
            });
    };

    getStepContent(stepIndex) {
        const { errors, nikname, password, verify } = this.state;

        switch (stepIndex) {
            case 0:
                return (
                    <React.Fragment>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="nikname"
                            label="Потребителско име"
                            name="nikname"
                            autoComplete="nikname"
                            autoFocus
                            onChange={this.onChange}
                            value={nikname}
                            error={Boolean(
                                errors.nikname ||
                                    errors.niknamenotfound ||
                                    errors.inactiveprofile
                            )}
                            helperText={
                                errors.nikname ||
                                errors.niknamenotfound ||
                                errors.inactiveprofile
                            }
                        />
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="Парола"
                            type="password"
                            id="password"
                            autoComplete="current-password"
                            onChange={this.onChange}
                            value={password}
                            error={Boolean(
                                errors.password || errors.passwordincorrect
                            )}
                            helperText={
                                errors.password || errors.passwordincorrect
                            }
                        />
                        <Button
                            onClick={this.handleNext}
                            fullWidth
                            variant="contained"
                            color="primary"
                            style={{ marginTop: '16px' }}
                        >
                            Генерирай код
                        </Button>
                    </React.Fragment>
                );
            case 1:
                return (
                    <React.Fragment>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="verify"
                            label="Код за потвърждение"
                            name="verify"
                            autoComplete="one-time-code"
                            autoFocus
                            onChange={this.onChange}
                            value={verify}
                            error={Boolean(errors.verify)}
                            helperText={errors.verify}
                        />
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            style={{ marginTop: '16px' }}
                            onClick={this.onSubmit}
                        >
                            Потвърди
                        </Button>
                    </React.Fragment>
                );
            default:
                // This should never be reached if the number of cases matches the number of steps
                return 'This step is not recognized.';
        }
    }

    render() {
        const { paper, logo_icon, activeStep, errors } = this.props;
        const steps = getSteps();

        return (
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <Grid
                    item
                    md={12}
                    style={{ alignItems: 'center', paddingTop: '25vh' }}
                >
                    <Card className={paper} style={{ borderRadius: '5px' }}>
                        <CardContent>
                            <div className={logo_icon}>
                                <img
                                    className="logo-icon"
                                    src={logo}
                                    alt="logo"
                                    style={{ width: '200px', margin: '5% 22%' }}
                                />
                                {/*<Stepper*/}
                                {/*    activeStep={activeStep}*/}
                                {/*    alternativeLabel*/}
                                {/*>*/}
                                {/*    {steps.map((label) => (*/}
                                {/*        <Step key={label}>*/}
                                {/*            <StepLabel>{label}</StepLabel>*/}
                                {/*        </Step>*/}
                                {/*    ))}*/}
                                {/*</Stepper>*/}
                                {this.getStepContent(this.state.activeStep)}
                            </div>
                        </CardContent>
                    </Card>
                </Grid>
                <Box mt={3}>
                    <Copyright />
                </Box>
            </Container>
        );
    }
}

Login2FA.propTypes = {
    verifyUser: PropTypes.func.isRequired,
    loginUser: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    auth: state.auth,
    errors: state.errors,
});

export default connect(mapStateToProps, { loginUser, verifyUser })(Login2FA);
